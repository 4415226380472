// All styles for the experience card in the profile (job seekers).
@import 'styles/styles';

[role='dialog'].popup-content.profile-experience-details-modal-content > .profile-experience-card {
  width: auto;
}

.profile-experience-card {
  @extend %vbox;

  background-color: $white;
  border: 1px solid $silver-lake-blue;
  border-radius: 1.25rem;
  cursor: pointer;
  flex: 0 0 16.8rem;
  gap: 1rem;
  padding: 1.5rem;
  text-align: left;
  transition: all 0.2s ease;

  &:hover {
    @include md {
      box-shadow: $result-card-hover-shadow;
    }

    @include lg {
      box-shadow: $result-card-hover-shadow;
    }
  }

  &__actions {
    @extend %hbox;

    justify-content: flex-end;
    margin-bottom: 0;
  }

  &__edit-button {
    border-radius: 9999px;
    padding: 0.5rem 0.6rem;

    &:hover {
      box-shadow: $result-card-hover-shadow;
    }
  }

  &__column {
    @extend %vbox;
  }

  &__row {
    @extend %hbox;

    align-items: center;

    > .txt {
      font-weight: 500;
    }

    &--description {
      @extend %wrap;

      @include truncate-box-vertical(4);

      &.txt {
        font-weight: normal;
      }
    }

    &--image {
      align-self: center;
      margin-right: 0.625rem;
    }

    &--title {
      @extend %wrap;

      &.txt {
        font-weight: 600;
      }
    }

    &--text {
      @extend %wrap;
    }
  }
}
