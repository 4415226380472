// All styles for the about us supporters section.
@import 'styles/styles';

.about-us-supporters-section {
  @extend %vbox;

  background-color: $isabelline;
  gap: 1.5rem;
  padding: 5% 0;

  @include xs {
    padding: 4% 0;
  }

  @include sm {
    padding: 4% 0;
  }

  @include md {
    padding: 5% 0;
  }

  &__title {
    padding: 0 6%;

    @include xs {
      padding: 0 4%;
    }

    @include sm {
      padding: 0 4%;
    }

    @include md {
      padding: 0 5%;
    }
  }

  &__image-container {
    margin: auto;
    max-width: 28.56rem;
  }

  .carousel {
    & * {
      box-sizing: initial;
    }

    .slide {
      img {
        height: 100%;
        width: 100%;
      }
    }

    &.carousel-slider {
      height: 27rem;
    }

    .control-dots {
      margin: 0;

      .dot {
        background-color: $beau-blue;
        box-shadow: none;

        &.selected {
          background-color: $silver-lake-blue;
        }
      }
    }
  }
}
