// All variables.

// Fonts.
$commissioner: 'Commissioner';
$iwona: 'Iwona';

// Colors.
// NOTE: Generated by https://www.color-name.com.
$african-violet: rgba(170 145 201 / 100%); // #AA91C9
$anti-flash-white: rgba(237 244 247 / 100%); // EDF4F7
$alabaster: rgba(250 250 250 / 100%); // #FAFAFA
$beau-blue: rgba(184 212 224 / 100%); // B8D4E0
$black: rgba(0 0 0 / 100%); // #000000
$copper-red: rgba(210 112 86 / 100%); // #D27056
$carrara: rgba(243 243 238 / 100%); // #F3F3EE
$dove-gray: rgba(112 112 112 / 100%); // #707070
$gainsboro: rgba(218 218 218 / 100%); // #DADADA
$geyser: rgba(204 213 225 / 100%); // #CCD5E1
$gull-gray: rgba(149 164 183 / 100%); // #95A4B7
$isabelline: rgba(244 244 239 / 100%); // #F4F4EF
$jelly-bean-blue: rgba(61 118 143 / 100%); // #3D768F
$marigold: rgba(230 162 25 / 100%); // #E6A219
$pizza: rgba(207 145 22 / 100%); // #CF9116
$maximum-yellow-red: rgba(235 180 70 / 100%); // #EBB446b
$medium-carmine: rgba(172 74 48 / 100%); // #AC4A30
$middle-blue-purple: rgba(148 111 192 / 100%); // #946FC0
$middle-red: rgba(220 145 125 / 100%); // #DC917D
$mischka: rgba(228 228 231 / 100%); // #E4E4E7
$moonstone-blue: rgba(116 171 195 / 100%); // #74ABC3
$raisin-black: rgba(34 34 34 / 100%); // #222222
$rhythm: rgba(134 108 166 / 100%); // #866CA6;
$silver-lake-blue: rgba(80 150 180 / 100%); // #5096B4
$scarpa-flow: rgba(82 82 91 / 100%); // #52525B
$silver-sand: rgba(196 196 196 / 100%); // #C4C4C4
$spanish-gray: rgba(151 151 151 / 100%); // #979797
$tundora: rgba(64 64 64 / 100%); // #404040
$wageningen-green: rgba(29 168 60 / 100%); // #1DA83C
$white: rgba(255 255 255 / 100%); // #FFFFFF
$woodsmoke: rgba(24 24 27 / 100%); // #18181B

// Shadows.
$light-shadow: rgba(0 0 0 / 5%);
$dark-shadow: rgba(0 0 0 / 50%);
$button-box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
  0px 4px 5px 0px rgb(0 0 0 / 14%),
  0px 1px 10px 0px rgb(0 0 0 / 12%);
$result-card-hover-shadow: rgb(0 0 0 / 20%) 0 3px 5px -1px,
  rgb(0 0 0 / 14%) 0 6px 10px 0,
  rgb(0 0 0 / 12%) 0 1px 18px 0;

// Breakpoints.
$screen-xs-min: 0;
$screen-xs-max: 594px;
$screen-sm-min: 595px;
$screen-sm-max: 834px;
$screen-md-min: 835px;
$screen-md-max: 1440px;
$screen-lg-min: 1441px;
