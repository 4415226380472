// All styles for the saved job seekers section in the dashboard (companies).
@import 'styles/styles';

.dashboard-saved-job-seekers {
  @extend %vbox;

  @include xs {
    padding: 1rem 6.6%;
  }

  @include sm {
    padding: 1rem 14.39%;
  }

  @include md {
    padding: 2.5rem 13.8%;
  }

  @include lg {
    padding: 2.5rem 11.15%;
  }

  &__header {
    @extend %hbox;

    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 1rem;

    @include xs {
      @include vbox;

      align-items: initial;
      justify-content: center;
      text-align: center;
    }

    @include sm {
      @include vbox;

      align-items: initial;
      justify-content: center;
      text-align: center;
    }
  }

  &__header-title {
    @include xs {
      margin-bottom: 1rem;
    }

    @include sm {
      margin-bottom: 1rem;
    }
  }

  &__no-results {
    @extend %hbox;

    align-items: center;
    justify-content: center;
    padding: 1rem;
  }

  &__results {
    @extend %hbox;

    flex-wrap: wrap;
    gap: 0.7rem;

    @include xs {
      @include vbox;
    }

    @include sm {
      @include vbox;
    }
  }
}
