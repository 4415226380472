// All styles for the search sort component
@import 'styles/styles';

.search-sort {
  @extend %hbox;

  > .ody-popup-menu > .btn-custom {
    @extend %hbox;

    align-items: center;
    font-size: 1.375rem;
    gap: 0.75rem;
    padding: 1rem;

    @include xs {
      font-size: 1rem;
      padding: 0.5rem 1rem;
    }

    @include sm {
      font-size: 1rem;
      padding: 0.5rem 1rem;
    }
  }
}

[role='tooltip'].popup-content.sort-popupmenu-content {
  padding: 0;

  > .sort-key {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 1.375rem;
    padding-bottom: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;

    &:first-of-type {
      padding-top: 1rem;
    }

    &:hover {
      color: $silver-lake-blue;
    }

    @include xs {
      font-size: 1rem;
    }

    @include sm {
      font-size: 1rem;
    }
  }
}
