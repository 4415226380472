// All styles for the public testimonials section.
@import 'styles/styles';

.public-testimonials-section {
  @extend %vbox;

  align-items: center;
  background-color: $isabelline;
  gap: 5rem;
  justify-content: center;
  overflow: hidden;
  padding: 5% 0;

  @include xs {
    gap: 2rem;
    padding: 4% 0;
  }

  @include sm {
    gap: 2.5rem;
    padding: 4% 0;
  }

  @include md {
    gap: 3rem;
    padding: 5% 0;
  }

  .carousel {
    & * {
      box-sizing: initial;
    }

    &.carousel-slider {
      min-height: 72rem;

      @include xs {
        min-height: 85rem;
      }

      @include sm {
        min-height: 96rem;
      }

      @include md {
        min-height: 65rem;
      }
    }

    .slide {
      iframe {
        height: 100%;
        margin: 0;
        width: 100%;
      }
    }

    .control-dots {
      margin: 0;

      .dot {
        background-color: $beau-blue;
        box-shadow: none;

        &.selected {
          background-color: $silver-lake-blue;
        }
      }
    }
  }
}
