// All styles for the learn more profile features cards section.
@import 'styles/styles';

.learn-more-profile-features-card-section {
  @extend %hbox;

  align-items: center;
  flex-wrap: wrap;
  gap: 3.125rem;
  justify-content: space-between;

  @include xs {
    gap: 1.5rem;
  }

  @include sm {
    gap: 2.5rem;
  }
}
