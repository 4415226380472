// All styles for the resume in the profile (job seekers).
@import 'styles/styles';

.profile-resume {
  &__download-container {
    @include hbox;

    flex-wrap: wrap;
    gap: 1rem;

    @include xs {
      @include vbox;
    }

    @include sm {
      @include vbox;
    }

    @include md {
      @include vbox;
    }
  }

  &__download-button {
    @extend %hbox;

    gap: 0.8125rem;
    justify-content: center;
    white-space: nowrap;
    width: 100%;
  }
}
