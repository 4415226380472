// All styles for the about solution section.
@import 'styles/styles';

.about-us-solution-section {
  @extend %hbox;

  align-items: center;
  background-color: $silver-lake-blue;
  gap: 3rem;
  justify-content: space-between;
  padding: 0 6%;

  @include xs {
    @include vbox;

    flex-direction: column-reverse;
    gap: 1.5rem;
    padding: 4%;
  }

  @include sm {
    @include vbox;

    flex-direction: column-reverse;
    gap: 1.75rem;
    padding: 4%;
  }

  @include md {
    gap: 2rem;
    padding: 5%;
  }

  &__content {
    @extend %vbox;

    gap: 1.5rem;
  }

  &__description {
    white-space: break-spaces;

    &.txt-md {
      @include xs {
        font-size: 1rem;
        line-height: 1.5rem;
      }

      @include sm {
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }
  }
}
