// All styles for the public grow social impact section.
@import 'styles/styles';

.public-grow-social-impact-section {
  @extend %hbox;

  align-items: center;
  background-color: $middle-red;
  justify-content: space-between;
  padding: 5% 8%;

  @include xs {
    @include vbox;

    gap: 2rem;
    padding: 5%;
  }

  @include sm {
    @include vbox;

    gap: 2.5rem;
    padding: 5% 0;
  }

  @include md {
    gap: 0.625rem;
    padding: 5% 3%;
  }

  &__blue {
    background-color: $moonstone-blue;
  }

  &__info-container {
    @extend %vbox;

    max-width: 42.7rem;

    @include sm {
      max-width: 32.1rem;
    }

    @include md {
      max-width: 32.1rem;
    }
  }

  &__title {
    margin: 0 0 3.31rem;

    @include xs {
      margin: 0 0 2rem;
    }

    @include sm {
      margin: 0 0 2.5rem;
    }

    @include md {
      margin: 0 0 3rem;
    }
  }

  &__info {
    white-space: break-spaces;

    &--has-button {
      margin-bottom: 3.81rem;

      @include xs {
        margin-bottom: 2rem;
      }

      @include sm {
        margin-bottom: 2.5rem;
      }

      @include md {
        margin-bottom: 3rem;
      }
    }

    &.txt-lg {
      @include xs {
        font-size: 1rem;
      }

      @include sm {
        font-size: 1rem;
      }

      @include md {
        font-size: 1.5rem;
      }
    }
  }

  &__button {
    &.btn {
      @extend %hbox;

      align-items: center;
      justify-content: center;
      width: 60%;

      @include xs {
        width: 100%;
      }

      @include sm {
        width: 100%;
      }
    }

    &.btn-sm {
      padding: 1rem 0;

      @include xs {
        padding: 0.5rem 0;
      }
    }

    &.btn-white {
      color: $black;
      font-size: 1.5rem;

      @include xs {
        font-size: 1rem;
      }

      @include sm {
        font-size: 1rem;
      }
    }
  }

  &__animated-icon {
    @include xs {
      height: 15.31rem;
    }

    @include sm {
      height: 20.31rem;
    }

    @include md {
      height: 20.31rem;
    }

    @include lg {
      height: 30.31rem;
    }
  }
}
