// All styles for the privacy policy page.
@import 'styles/styles';

.ody-privacy-policy {
  padding: 3rem 22.5%;

  @include xs {
    padding: 1rem 5%;
  }

  > .ody-markdown > :first-child {
    margin-top: 0;
  }
}
