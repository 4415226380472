// All styles for the total users card.
@import 'styles/styles';

.total-users-card {
  @extend %vbox;

  align-items: center;
  border: 1px solid $moonstone-blue;
  border-radius: 1rem;
  flex: 0 1 26.5rem;
  gap: 2.125rem;
  justify-content: flex-start;
  padding: 1.5rem;

  @include xs {
    flex: 1 1;
    width: 100%;
  }

  @include sm {
    flex: 1 1;
    width: 100%;
  }

  &__title-and-number {
    @extend %vbox;

    align-items: center;
    gap: 1rem;
    justify-content: center;
  }

  &__link.btn-sm {
    @include xs {
      font-size: 1rem;
      padding: 0.5rem 1rem;
      text-align: center;
      width: 100%;
    }

    @include sm {
      font-size: 1rem;
      padding: 0.5rem 1rem;
      text-align: center;
      width: 100%;
    }
  }
}
