// All styles for the steps icon.
@import 'styles/styles';

.steps-icon {
  @include xs {
    height: 13.68rem;
  }

  @include sm {
    height: 13.68rem;
  }

  @include md {
    height: 12.68rem;
  }

  @include lg {
    height: 19.68rem;
  }
}
