// All styles for the learn more page what does endorser do section.
@import 'styles/styles';

.learn-more-what-endorsers-do-section {
  @extend %vbox;

  align-items: center;
  background-color: $isabelline;
  gap: 4.65rem;
  justify-content: center;
  padding: 8% 15%;

  @include xs {
    padding: 4%  4% 16%;
  }

  @include sm {
    padding: 4%  4% 16%;
  }

  @include md {
    padding: 5%;
  }

  &__title {
    text-align: left;
    width: 100%;
  }

  &__cards-container {
    @extend %hbox;

    align-items: center;
    flex-wrap: wrap;
    gap: 5rem;
    justify-content: center;
    width: 100%;

    @include xs {
      @include vbox;

      align-items: initial;
      gap: 4rem;
    }

    @include sm {
      @include vbox;

      align-items: initial;
      gap: 4rem;
    }
  }
}
