// All styles for the about us member.
@import 'styles/styles';

.about-us-member {
  @extend %vbox;

  align-items: center;
  gap: 1rem;
  justify-content: center;

  @include md {
    opacity: 0;
  }

  @include lg {
    opacity: 0;
  }

  &__visible {
    @include md {
      animation: fade-in-up 0.5s ease-in-out forwards;
    }

    @include lg {
      animation: fade-in-up 0.5s ease-in-out forwards;
    }
  }

  &__image-container {
    border-radius: 9999px;
    height: 15.62rem;
    width: 15.62rem;

    @include xs {
      height: 9.62rem;
      width: 9.62rem;
    }

    @include sm {
      height: 11.62rem;
      width: 11.62rem;
    }

    @include md {
      height: 13.62rem;
      width: 13.62rem;
    }
  }

  &__image {
    border-radius: 9999px;
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  &__info-container {
    @extend %vbox;

    align-items: center;
    justify-content: center;
  }
}
