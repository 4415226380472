// All styles for the arrows up group icon.
@import 'styles/styles';

.arrows-up-group-icon {
  @include xs {
    height: 1.75rem;
  }

  @include sm {
    height: 1.75rem;
  }

  @include md {
    height: 2rem;
  }

  @include lg {
    height: 2.68rem;
  }
}
