// All styles for the about us unemployment section.
@import 'styles/styles';

.about-us-unemployment-section {
  @extend %vbox;

  align-items: center;
  background-color: $isabelline;
  gap: 2rem;
  padding: 6% 10%;

  @include xs {
    gap: 1.5rem;
    padding: 4%;
  }

  @include sm {
    gap: 1.75rem;
    padding: 4%;
  }

  @include md {
    padding: 5% 8%;
  }

  &__content-container {
    @extend %vbox;

    gap: 1.5rem;

    @include xs {
      gap: 1rem;
    }

    @include sm {
      gap: 1.375rem;
    }
  }

  &__title {
    @include xs {
      text-align: center;
    }

    @include sm {
      text-align: center;
    }
  }

  &__cards-container {
    @extend %hbox;

    align-items: center;
    flex-wrap: wrap;
    gap: 1.5rem;

    @include xs {
      @include vbox;
    }

    @include sm {
      @include vbox;
    }
  }

  &__card {
    background-color: $moonstone-blue;
    border-radius: 3.75rem;
    box-shadow: $result-card-hover-shadow;
    line-height: 2rem;
    padding: 1.5rem 2.062rem;

    @include xs {
      width: 80%;
    }

    @include sm {
      width: 90%;
    }

    @include md {
      line-height: 1.75rem;
      max-width: 11rem;
      min-height: 9rem;
    }

    @include lg {
      max-width: 13rem;
      min-height: 11rem;
    }

    &.txt.txt-md {
      @include xs {
        font-size: 1rem;
        line-height: 1.5rem;
      }

      @include sm {
        font-size: 1rem;
        line-height: 1.5rem;
      }

      @include md {
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }
  }

  &__animated-icon {
    @include xs {
      height: 12rem;
    }

    @include sm {
      height: 17rem;
    }

    @include md {
      height: 23rem;
    }

    @include lg {
      height: 27.93rem;
    }
  }
}
