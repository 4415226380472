// All styles for the about us milestones section.
@import 'styles/styles';

.about-us-milestones-section {
  @extend %vbox;

  background-color: $moonstone-blue;
  padding: 5% 0;

  @include xs {
    gap: 1.5rem;
    padding: 4%;
  }

  @include sm {
    gap: 2rem;
    padding: 4%;
  }

  @include md {
    padding: 4% 0;
  }

  &__title {
    @include md {
      padding: 0 5%;
    }

    @include lg {
      padding: 0 8%;
    }
  }

  &__months-container {
    @extend %hbox;

    background-color: $silver-lake-blue;
    justify-content: space-between;

    @include xs {
      @include vbox;

      align-items: stretch;
      justify-content: center;
      max-width: fit-content;
    }

    @include sm {
      @include vbox;

      align-items: stretch;
      justify-content: center;
      max-width: fit-content;
    }

    @include md {
      margin: 14rem 0;
      padding: 0 5% 0 1%;
    }

    @include lg {
      margin: 12rem 0;
      padding: 0 8%;
    }
  }

  &__month {
    @extend %hbox;

    align-items: center;
    justify-content: center;
    padding: 0.625rem;
    position: relative;

    &--has-quarter {
      background-color: $white;
    }
  }

  &__displayed-month {
    color: $white;
    font-family: $commissioner;
    font-size: 1.87rem;
    font-weight: 600;
    line-height: 3.75rem;

    &--has-quarter {
      color: $black;
    }

    @include xs {
      font-size: 1.375rem;
      line-height: 2.5rem;
    }

    @include sm {
      font-size: 1.375rem;
      line-height: 2.5rem;
    }

    @include md {
      font-size: 1.375rem;
      line-height: 2.5rem;
    }
  }

  &__quarter {
    @extend %vbox;

    border-radius: 3px 3px 0 0;
    gap: 0.625rem;
    position: absolute;

    @include xs {
      border-top: 3px solid $isabelline;
      left: 4rem;
      min-height: 9rem;
      min-width: 13rem;
      padding-left: 1.375rem;
      padding-top: 1.375rem;
      top: 0;
    }

    @include sm {
      border-top: 3px solid $isabelline;
      left: 4rem;
      min-height: 9rem;
      min-width: 13rem;
      padding-left: 1.375rem;
      padding-top: 1.375rem;
      top: 0;
    }

    @include md {
      border-left: 3px solid $isabelline;
      left: 0;
      min-height: 10rem;
      min-width: 15rem;
      padding-left: 1.375rem;
    }

    @include lg {
      border-left: 3px solid $isabelline;
      left: 0;
      min-width: 19rem;
      padding-left: 1.75rem;
    }

    &--up {
      @include md {
        justify-content: flex-start;
        top: -10rem;
      }

      @include lg {
        justify-content: flex-start;
        padding-bottom: 2.81rem;
        top: -10rem;
      }
    }

    &--down {
      @include md {
        bottom: -10rem;
        justify-content: flex-end;
      }

      @include lg {
        bottom: -8rem;
        justify-content: flex-end;
        padding-top: 2.81rem;
      }

      &.about-us-milestones-section__quarter--greek {
        @include lg {
          bottom: -10rem;
        }
      }
    }
  }

  &__quarter-description {
    &.txt-md {
      @include xs {
        font-size: 1rem;
        line-height: 1.5rem;
      }

      @include sm {
        font-size: 1rem;
        line-height: 1.5rem;
      }

      @include md {
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }
  }
}
