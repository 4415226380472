// All styles for the scrollable area.
@import 'styles/styles';

.ody-scrollable {
  @extend %scrollable;
  @extend %vbox;

  flex-grow: 1;
  flex-shrink: 1;
}
