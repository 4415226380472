// All style resets.
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
}

html {
  font-size: 16px;

  body {
    margin: 0;
    min-width: 360px;

    a {
      @extend %no-select;

      text-decoration: none;

      &:focus-visible {
        outline: none;
      }

      &:hover {
        text-decoration: none;
      }
    }

    button {
      @extend %no-select;

      cursor: pointer;
      outline: none;
      padding: 0;
    }

    h1 {
      margin: 0;
    }

    h2 {
      margin: 0;
    }

    h3 {
      margin: 0;
    }

    p {
      margin: 0;
    }
  }
}
