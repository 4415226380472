// All styles for the dashboard contact
@import 'styles/styles';

.dashboard-contact {
  @extend %hbox;

  @include xs {
    @include vbox;
  }

  @include sm {
    @include vbox;
  }

  &__section {
    flex: 1;

    @include xs {
      padding: 1rem 6.6%;
    }

    @include sm {
      padding: 1rem 14.39%;
    }

    @include md {
      padding: 2.5rem 13.8%;
    }

    @include lg {
      padding: 7rem 11.15%;
    }

    &--left {
      @extend %vbox;

      background-color: $middle-red;

      @include md {
        padding-right: 5rem;
      }
    }

    &--left-purple {
      background-color: $african-violet;
    }

    &--right {
      @extend %hbox;

      align-items: center;
      background-color: $copper-red;
      justify-content: center;

      @include xs {
        padding-bottom: 2rem;
        padding-top: 2rem;
      }

      @include sm {
        padding-bottom: 2rem;
        padding-top: 2rem;
      }

      @include md {
        padding-left: 3rem;
      }
    }

    &--right-purple {
      background-color: $middle-blue-purple;
    }

    &--right-job-seekers {
      @include lg {
        background-image: url('/images/waves.png');
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }

  &__text {
    margin-bottom: 1rem;
  }

  &__buttons {
    @include hbox;

    flex-wrap: wrap;
    gap: 1.5rem;

    @include xs {
      @include vbox;

      gap: 1rem;
    }

    @include sm {
      @include vbox;

      gap: 1rem;
    }
  }

  &__button {
    border: none;
    color: $marigold;

    @include xs {
      width: 100%;
    }

    &--download-profile {
      @extend %hbox;

      align-items: flex-start;
      color: $middle-blue-purple;
      justify-content: center;
    }

    &--purple {
      color: $middle-blue-purple;
    }
  }
}
