// All styles for the public individual testimonial.
@import 'styles/styles';

.public-testimonial {
  @extend %vbox;

  gap: 2rem;
  margin: auto;
  width: 100vw;

  &__video-testimonials-container {
    @extend %hbox;

    flex-wrap: wrap;
    gap: 5rem;
    justify-content: space-evenly;

    @include xs {
      gap: 2rem;
      justify-content: center;
    }

    @include sm {
      gap: 2.5rem;
      justify-content: center;
    }

    @include md {
      gap: 3rem;
      justify-content: center;
    }
  }

  &__image-testimonials-container {
    @extend %vbox;
  }
}
