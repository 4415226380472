// All styles for file previews.
@import 'styles/styles';

.ody-file-preview {
  @extend %vbox;

  gap: 1rem;

  > .navigation-and-preview {
    @extend %hbox;

    gap: 1rem;
    justify-content: center;

    > .no-preview {
      @extend %vbox;

      align-items: center;
      height: 20rem;
      justify-content: center;
      width: 100%;
    }

    > button {
      padding: 1rem;

      > img {
        height: 1rem;
        width: auto;
      }
    }

    > .react-pdf__Document {
      @extend %vbox;

      height: 20rem !important;
      justify-content: center;
      width: auto !important;

      > .react-pdf__message {
        font-family: $commissioner;
        font-size: 1rem;
        font-weight: normal;
        line-height: 1.5rem;
      }

      > .react-pdf__Page {
        height: 20rem !important;
        width: auto !important;

        > .react-pdf__Page__canvas {
          height: 20rem !important;
          width: auto !important;
        }

        > .react-pdf__Page__textContent {
          height: 20rem !important;
          width: auto !important;
        }
      }
    }
  }

  > .page-number {
    text-align: center;
  }
}
