// All mixins.

// Media queries.
@mixin xs {
  @media screen and (min-width: #{$screen-xs-min}) and (max-width: #{$screen-xs-max}) {
    @content;
  }
}

@mixin sm {
  @media screen and (min-width: #{$screen-sm-min}) and (max-width: #{$screen-sm-max}) {
    @content;
  }
}

@mixin md {
  @media screen and (min-width: #{$screen-md-min}) and (max-width: #{$screen-md-max}) {
    @content;
  }
}

@mixin lg {
  @media screen and (min-width: #{$screen-lg-min}) {
    @content;
  }
}

// Something that displays its items horizontally.
@mixin hbox {
  display: flex;
  flex-flow: row nowrap;
  width: auto;
}

// Something that displays its items vertically.
@mixin vbox {
  display: flex;
  flex-flow: column nowrap;
  height: auto;
}

// Something that adjusts its height to its content, and has its overflow clipped.
@mixin fit {
  height: auto;
  overflow: hidden;
}

// truncates a text in one line
@mixin truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

// wraps a text to its width
@mixin wrap {
  hyphens: auto;
  overflow-wrap: break-word;
  word-break: break-word;
}

// truncates a text to a specific number of rows
@mixin truncate-box-vertical($numberOfRows) {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: $numberOfRows;
  overflow: hidden;
}
