// All styles for toasts.
@import 'styles/styles';

.redux-toastr {
  .toastr.ody-toast {
    border-radius: 0.5rem;
    color: $white;
    min-height: 3rem;
    opacity: 1;

    &.error {
      background-color: $copper-red;
    }

    &.info {
      background-color: $silver-lake-blue;
    }

    .rrt-middle-container {
      margin-left: 1rem;
      padding: 0.5rem 0.25rem;
      width: 85%;
    }

    .rrt-text {
      font-family: $commissioner;
      font-size: 1rem;
      font-weight: normal;
    }

    .close-toastr {
      @extend %hbox;

      align-items: flex-start;
      color: $white;
      font-family: $commissioner;
      font-size: 1rem;
      padding: 0.5rem 0.25rem;
      position: static;

      > span {
        left: auto;
        position: static;
        right: auto;
        top: auto;
        transform: none;
      }
    }
  }
}
