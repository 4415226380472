// All styles for the dashboard.
@import 'styles/styles';

.ody-dashboard {
  @extend %vbox;

  &__section {
    @include hbox;

    justify-content: space-between;

    @include xs {
      padding: 1rem 6.6%;
    }

    @include sm {
      padding: 1rem 14.39%;
    }

    @include md {
      padding: 2.5rem 13.8%;
    }

    @include lg {
      padding: 2.5rem 11.15%;
    }

    &--profile-overview {
      background-color: $isabelline;

      @include xs {
        @include vbox;

        gap: 1.5rem;
      }

      @include sm {
        @include vbox;

        gap: 1.5rem;
      }

      @include md {
        @include vbox;

        gap: 1.5rem;
      }
    }

    &--profile-overview-companies {
      @include xs {
        @include vbox;

        gap: 1.5rem;
      }

      @include sm {
        @include vbox;

        gap: 1.5rem;
      }

      @include md {
        @include vbox;

        gap: 1.5rem;
      }

      @include lg {
        min-height: 34.5rem;
        padding: 2.18rem 0 1.2rem 11.15%;
      }
    }

    &--next-steps-job-seekers {
      @extend %vbox;

      background-color: $silver-lake-blue;
    }

    &--next-steps-companies {
      @include xs {
        padding: 2rem 6.6%;
      }

      @include sm {
        padding: 2rem 14.39%;
      }

      @include md {
        padding: 3.125rem 13.8%;
      }

      @include lg {
        padding: 3.125rem 11.15%;
      }
    }
  }

  &__share-profile {
    @include hbox;

    @include xs {
      @include vbox;
    }

    @include sm {
      @include vbox;
    }
  }

  &__share-profile-section {
    flex: 1;

    @include xs {
      padding: 1rem 6.6%;
    }

    @include sm {
      padding: 1rem 14.39%;
    }

    @include md {
      padding: 2.5rem 13.8%;
    }

    @include lg {
      padding: 7rem 11.15%;
    }

    &--left {
      @extend %vbox;

      background-color: $african-violet;

      @include md {
        padding-right: 5rem;
      }
    }

    &--right {
      @extend %hbox;

      align-items: center;
      background-color: $middle-blue-purple;
      justify-content: center;

      @include xs {
        padding-bottom: 2rem;
        padding-top: 2rem;
      }

      @include sm {
        padding-bottom: 2rem;
        padding-top: 2rem;
      }

      @include md {
        padding-left: 3rem;
      }

      @include lg {
        background-image: url('/images/waves.png');
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }

  &__share-profile-title {
    margin-bottom: 1rem;
  }

  &__share-profile-buttons {
    @include hbox;

    flex-wrap: wrap;
    gap: 1.5rem;

    @include xs {
      @include vbox;

      gap: 1rem;
    }

    @include sm {
      @include vbox;

      gap: 1rem;
    }
  }

  &__share-profile-button {
    color: $middle-blue-purple;

    @include xs {
      width: 100%;
    }

    @include sm {
      width: 100%;
    }

    &--download-profile {
      @extend %hbox;

      align-items: flex-start;
      justify-content: center;
    }
  }

  &__share-profile-button-image {
    margin-right: 1rem;
  }

  &__share-profile-logo {
    @extend %hbox;
  }

  &__contact {
    @extend %vbox;

    background-color: $middle-red;

    @include xs {
      padding: 1rem 6.6%;
    }

    @include sm {
      padding: 1rem 14.39%;
    }

    @include md {
      padding: 2.5rem 13.8%;
    }

    @include lg {
      padding: 2.5rem 11.15%;
    }
  }

  &__contact-title {
    margin-bottom: 1rem;
  }

  &__contact-buttons {
    @include hbox;

    flex-wrap: wrap;
    gap: 1.5rem;

    @include xs {
      @include vbox;

      gap: 1rem;
    }

    @include sm {
      @include vbox;

      gap: 1rem;
    }
  }

  &__contact-button {
    background-color: $white;
    border: none;
    color: $marigold;

    @include xs {
      width: 100%;
    }
  }
}
