// All styles for the verify-email page.
@import 'styles/styles';

.ody-verify-email {
  @include hbox;

  height: 100%;

  @include xs {
    @include vbox;

    flex-direction: column-reverse;
  }

  @include sm {
    @include vbox;

    flex-direction: column-reverse;
  }

  > .wrapper {
    @extend %hbox;

    flex: 1;
    justify-content: center;
    max-width: 40rem;

    @include xs {
      padding: 1rem;
    }

    @include sm {
      padding: 1rem;
    }

    @include md {
      padding: 3rem;
    }

    @include lg {
      padding: 5rem;
    }

    > .error {
      color: $copper-red;
      font-family: $commissioner;
      font-size: 1rem;
      font-weight: normal;
      line-height: 1.25rem;
      margin-bottom: 1.5rem;

      @include xs {
        font-size: 0.8rem;
        line-height: 1rem;
      }

      @include sm {
        font-size: 0.8rem;
        line-height: 1rem;
      }
    }

    > .verified {
      white-space: pre-line;
    }
  }

  > .logo {
    @extend %hbox;

    align-items: center;
    flex: 1;
    justify-content: center;

    @include xs {
      padding-bottom: 1.5rem;
      padding-top: 1.5rem;
    }

    @include sm {
      padding-bottom: 1.5rem;
      padding-top: 1.5rem;
    }

    @include md {
      background-color: $silver-lake-blue;
      padding: 2rem;
    }

    @include lg {
      background-color: $silver-lake-blue;
      padding-bottom: 5rem;
      padding-top: 5rem;
    }
  }
}
