// All classes that can be applied to public pages.

// Some section in some public page.
.section.public {
  @include hbox;

  gap: 1rem;
  justify-content: space-between;

  @include xs {
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem;
    padding: 2rem 1rem 1.5rem;

    &.reverse {
      flex-wrap: wrap-reverse;
    }
  }

  @include sm {
    padding: 2.7rem 4.5%;
  }

  @include md {
    padding: 2.7rem 13.8%;
  }

  @include lg {
    padding: 3rem 10%;
  }

  > .content {
    align-self: center;

    @include xs {
      padding-top: 0;
      width: 100%;
    }

    > h1 {
      font-family: $iwona;

      @include xs {
        font-size: 1.375rem;
        font-weight: 700;
        line-height: 1.75rem;
        margin-bottom: 0.5rem;
      }

      @include sm {
        font-size: 2.25rem;
        font-weight: 900;
        line-height: 3.125rem;
        margin-bottom: 0.5rem;
        max-width: 25rem;
      }

      @include md {
        font-size: 3rem;
        font-weight: 900;
        line-height: 4rem;
        margin-bottom: 0.5rem;
        max-width: 28rem;
      }

      @include lg {
        font-size: 4rem;
        font-weight: 900;
        line-height: 5.5625rem;
        margin-bottom: 1rem;
        max-width: 40rem;
      }
    }

    > .description {
      font-family: $commissioner;
      white-space: break-spaces;

      @include xs {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5rem;
      }

      @include sm {
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.5rem;
        max-width: 25rem;
      }

      @include md {
        font-size: 1.125rem;
        font-weight: 500;
        line-height: 1.5rem;
        max-width: 28rem;
      }

      @include lg {
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 2.75rem;
        max-width: 40rem;
      }
    }

    > .buttons {
      margin: 2.63rem 0;

      @include xs {
        margin: 1.5rem 0 0;
      }

      @include sm {
        margin: 1rem 0;
      }

      @include md {
        margin: 2rem 0;
      }
    }
  }

  > .image {
    align-self: center;

    > img {
      width: 100%;
    }
  }

  &.image-only {
    padding: 0;

    > img {
      width: 100%;
    }
  }

  &.content-only {
    > .content {
      flex: 1;

      > .description {
        max-width: fit-content;
      }
    }
  }

  &.top {
    > .content {
      > h1 {
        font-family: $iwona;
        margin: 0 0 2rem;
        text-align: left;

        @include xs {
          font-size: 1.25rem;
          font-weight: 700;
          line-height: 1.875rem;
          text-align: center;
        }

        @include sm {
          font-size: 1.5rem;
          font-weight: 900;
          line-height: 2.25rem;
          max-width: 25rem;
        }

        @include md {
          font-size: 2.25rem;
          font-weight: 900;
          line-height: 3.375rem;
          max-width: fit-content;
        }

        @include lg {
          font-size: 3rem;
          font-weight: 900;
          line-height: 4.5rem;
          max-width: 40rem;
        }
      }
    }
  }
}
