// All styles for the resume editor in the profile (job seekers).
@import 'styles/styles';

[role='dialog'].popup-content.job-seeker-profile-editor-modal-content > .editor.resume {
  > .file {
    margin-bottom: 1rem;

    > .preview {
      @extend %hbox;

      gap: 1rem;
      justify-content: center;

      > .no-file,
      > .failed {
        @extend %vbox;

        align-items: center;
        border: 1px dashed $gainsboro;
        border-radius: 1rem;
        height: 20rem;
        justify-content: center;
        width: 100%;

        > img {
          margin-bottom: 1rem;
          width: 3rem;
        }
      }
    }

    > .buttons {
      @extend %hbox;

      justify-content: space-between;
      margin-top: 1rem;
    }

    > .error {
      text-align: right;
    }
  }
}
