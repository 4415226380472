// All styles for the search.
@import 'styles/styles';

.ody-search {
  @extend %vbox;

  @include xs {
    gap: 1rem;
  }

  @include sm {
    gap: 1rem;
  }

  @include md {
    gap: 1.5rem;
  }

  @include lg {
    gap: 1.5rem;
  }

  &__section {
    @include xs {
      padding: 0 6.6%;
    }

    @include sm {
      padding: 0 14.39%;
    }

    @include md {
      padding: 0 4.8%;
    }

    @include lg {
      padding: 0 15.46%;
    }
  }

  &__top-section {
    @extend %hbox;

    min-height: 10rem;
    position: relative;

    @include xs {
      padding: 1rem 6.6%;
    }

    @include sm {
      padding: 1rem 14.39%;
    }

    @include md {
      padding: 2.5rem 4.8%;
    }

    @include lg {
      padding: 2.5rem 15.46%;
    }

    &--endorsers {
      background-color: $african-violet;
    }

    &--image {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      z-index: 0;
    }
  }

  &__total-and-sort {
    @extend %hbox;

    align-items: center;
    justify-content: space-between;
  }

  &__filters-and-sort {
    @include xs {
      @include vbox;

      flex-wrap: wrap-reverse;
      gap: 1rem;
    }

    @include sm {
      @include hbox;

      justify-content: space-between;
    }
  }

  &__filters-results-and-pages {
    @extend %hbox;

    align-items: flex-start;
    gap: 1.5rem;
  }

  &__results-and-pages {
    @extend %vbox;

    flex: 1;
    gap: 1.5rem;
  }
}
