// All styles for controlled metrics card.
@import 'styles/styles';

.controlled-metrics-card {
  @extend %vbox;

  border: 1px solid $moonstone-blue;
  border-radius: 1rem;
  flex: 0 0 19.5rem;
  gap: 2.5rem;
  justify-content: space-between;
  padding: 1rem 1rem 1.375rem;

  @include xs {
    flex: 1 1;
    width: 100%;
  }

  @include sm {
    flex: 1 1;
    width: 100%;
  }

  &__error {
    border: 1px solid $copper-red;
  }

  &__input-container {
    @extend %vbox;

    gap: 0.5rem;
  }
}
