// All styles for the home page learn more card.
@import 'styles/styles';

.home-learn-more-card {
  @extend %vbox;

  align-items: center;
  background-color: $white;
  border-radius: 1.25rem;
  justify-content: center;

  @include xs {
    gap: 1.5rem;
    min-height: 12.76rem;
    padding: 1.85rem 2.07rem;
  }

  @include sm {
    gap: 1.5rem;
    min-height: 12.76rem;
    padding: 1.85rem 2.07rem;
  }

  @include md {
    gap: 1.875rem;
    min-height: 16.76rem;
    padding: 1.85rem 3.07rem;
  }

  @include lg {
    gap: 1.875rem;
    min-height: 16.76rem;
    padding: 1.85rem 4.07rem;
  }

  &__company-card-visible {
    @include sm {
      animation: slide-from-left-in 0.5s ease-in-out forwards;
      transform: translateX(-6.25rem);
    }

    @include md {
      animation: slide-from-right-in 0.5s ease-in-out forwards;
      transform: translateX(6.25rem);
    }

    @include lg {
      animation: slide-from-right-in 0.5s ease-in-out forwards;
      transform: translateX(6.25rem);
    }
  }

  &__job-seeker-card-visible {
    @include sm {
      animation: slide-from-left-in 0.5s ease-in-out forwards;
      transform: translateX(-6.25rem);
    }

    @include md {
      animation: slide-from-left-in 0.5s ease-in-out forwards;
      transform: translateX(-6.25rem);
    }

    @include lg {
      animation: slide-from-left-in 0.5s ease-in-out forwards;
      transform: translateX(-6.25rem);
    }
  }

  &__title {
    &.txt {
      color: $woodsmoke;
      font-size: 1.31rem;
      margin: 0;

      @include xs {
        font-size: 1rem;
      }

      @include sm {
        font-size: 1rem;
      }
    }
  }

  &__description {
    &.txt {
      color: $scarpa-flow;
    }
  }
}
