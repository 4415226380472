// All styles for the public statistics section.
@import 'styles/styles';

.public-statistics {
  @include hbox;

  align-self: stretch;
  justify-content: center;

  @include xs {
    @include vbox;

    justify-content: center;
    width: 100%;
  }

  @include sm {
    @include vbox;
  }

  &__statistic {
    @extend %vbox;

    align-items: stretch;
    background-color: $moonstone-blue;
    flex: 1;
    padding-bottom: 8.93rem;
    padding-top: 7.25rem;
    text-align: center;

    @include xs {
      padding-bottom: 4.93rem;
      padding-top: 3.25rem;
    }

    @include sm {
      padding-bottom: 5.93rem;
      padding-top: 4.25rem;
    }

    @include md {
      padding-bottom: 7.93rem;
      padding-top: 6.25rem;
    }

    &--dark {
      background-color: $silver-lake-blue;
    }
  }

  &__statistic-yellow {
    background-color: $maximum-yellow-red;

    &--dark {
      background-color: $marigold;
    }
  }

  &__statistic-label {
    &.txt-xl {
      @include xs {
        font-size: 1rem;
        line-height: 1.5rem;
      }

      @include sm {
        font-size: 1.375rem;
        line-height: 1.75rem;
      }

      @include md {
        font-size: 1.5rem;
        line-height: 2rem;
      }
    }
  }
}
