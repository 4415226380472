// All styles for multi-selects.
@import 'styles/styles';

.ody-multi-select {
  margin-top: 0.5rem;

  .ody-tags-selected-options > .ody-tags-selected-option {
    border: 1px solid $maximum-yellow-red;
  }
}
