// All styles for the header.
@import 'styles/styles';

.ody-header {
  @extend %hbox;

  align-items: center;
  box-shadow: 0px 4px 8px 0px $light-shadow;
  justify-content: space-between;

  @include xs {
    padding: 0.75rem 6.6%;
  }

  @include sm {
    padding: 0.75rem 14.39%;
  }

  @include md {
    padding: 0.75rem 13.8%;
  }

  @include lg {
    padding: 1.25rem 11.15%;
  }

  > .brand > a {
    color: $silver-lake-blue;
    font-family: $iwona;
    font-weight: 900;

    @include xs {
      font-size: 1.25rem;
      line-height: 1.25rem;
    }

    @include sm {
      font-size: 1.25rem;
      line-height: 1.25rem;
    }

    @include md {
      font-size: 2.5rem;
      line-height: 2.5rem;
    }

    @include lg {
      font-size: 2.5rem;
      line-height: 2.5rem;
    }
  }

  > .links {
    @extend %hbox;

    gap: 3rem;

    > .ody-popup-menu > button {
      color: $raisin-black;
      font-family: $commissioner;
      font-size: 1.375rem;
      font-weight: 500;
      line-height: 1.75rem;
    }

    > .link,
    > .btn-link {
      font-size: 1.375rem;
      font-weight: 500;
      line-height: 1.75rem;
    }
  }
}

[role='tooltip'].popup-content.header-popupmenu-content {
  > .group {
    @extend %vbox;
  }

  > .link,
  > .btn-link,
  > .group > .link,
  > .group > .btn-link {
    &:not(:last-child) {
      padding-bottom: 1.5rem;
    }
  }
}
