// All styles for modals.
@import 'styles/styles';

[role='dialog'].popup-content {
  @extend %vbox;

  background-color: $white;
  flex: 1;
  padding: 1rem;

  > .close-modal {
    align-items: center;
    align-self: flex-end;
    background-color: $silver-lake-blue;
    border-radius: 50%;
    color: $white;
    display: flex;
    justify-content: center;
    z-index: 128;

    @include xs {
      font-size: 1rem;
      height: 1.5rem;
      line-height: 1rem;
      margin-bottom: -1rem;
      margin-right: -0.5rem;
      min-height: 1.5rem;
      padding-bottom: 0.3rem;
      width: 1.5rem;
    }

    @include sm {
      font-size: 1rem;
      height: 1.5rem;
      line-height: 1rem;
      margin-bottom: -1rem;
      margin-right: -0.5rem;
      min-height: 1.5rem;
      padding-bottom: 0.3rem;
      width: 1.5rem;
    }

    @include md {
      font-size: 1.5rem;
      height: 2rem;
      line-height: 1.5rem;
      margin-bottom: -1rem;
      margin-right: -0.5rem;
      min-height: 2rem;
      padding-bottom: 0.3rem;
      width: 2rem;
    }

    @include lg {
      font-size: 1.5rem;
      height: 2rem;
      line-height: 1.5rem;
      margin-bottom: -1rem;
      margin-right: -0.5rem;
      min-height: 2rem;
      padding-bottom: 0.3rem;
      width: 2rem;
    }
  }
}

[data-popup='modal'].popup-overlay {
  background-color: $dark-shadow;

  > .popup-content {
    background-color: transparent;
  }
}
