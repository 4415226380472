// All classes that can be applied to forms and their elements.

label {
  @extend %vbox;

  color: $raisin-black;
  font-family: $commissioner;
  font-size: 1.375rem;
  font-weight: normal;
  line-height: 1.625rem;

  @include xs {
    font-size: 1rem;
    line-height: 1.25rem;
  }

  @include sm {
    font-size: 1rem;
    line-height: 1.25rem;
  }

  &.inline {
    @extend %hbox;

    align-items: center;
  }
}

input {
  background-color: $white;
  border: 1px solid $silver-sand;
  outline: 0;

  &[type='checkbox'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.3125rem;
    display: inline-block;
    height: 1.25rem;
    margin-right: 0.5rem;
    min-height: 1.25rem;
    min-width: 1.25rem;
    position: relative;
    width: 1.25rem;

    &:checked {
      background-color: $silver-lake-blue;

      &::after {
        color: $white;
        content: 'L';
        font-size: 0.625rem;
        left: 0.375rem;
        position: absolute;
        top: 0.1875rem;
        -webkit-transform: scaleX(-1) rotate(-35deg); /* Chrome, Safari, Opera */
        transform: scaleX(-1) rotate(-35deg);
      }
    }
  }

  &[type='password'],
  &[type='text'] {
    border-radius: 0.5rem;
    color: $raisin-black;
    font-family: $commissioner;
    font-size: 1.375rem;
    font-weight: normal;
    line-height: 1.625rem;
    margin-top: 0.5rem;
    padding: 1rem;

    @include xs {
      font-size: 1rem;
      line-height: 1.25rem;
    }

    @include sm {
      font-size: 1rem;
      line-height: 1.25rem;
    }
  }

  &:focus,
  &:focus-visible {
    border-color: $moonstone-blue;
  }

  &:disabled {
    background-color: $silver-sand;
    cursor: not-allowed;
  }

  &.error {
    border-color: $copper-red;
  }
}

textarea {
  background-color: $white;
  border: 1px solid $silver-sand;
  border-radius: 0.5rem;
  color: $raisin-black;
  font-family: $commissioner;
  font-size: 1.375rem;
  font-weight: normal;
  line-height: 1.625rem;
  margin-top: 0.5rem;
  outline: 0;
  padding: 1rem;

  @include xs {
    font-size: 1rem;
    line-height: 1.25rem;
  }

  @include sm {
    font-size: 1rem;
    line-height: 1.25rem;
  }

  &:focus,
  &:focus-visible {
    border-color: $moonstone-blue;
  }

  &.error {
    border-color: $copper-red;
  }
}

span.error-message {
  color: $copper-red;
  font-family: $commissioner;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.25rem;
  white-space: break-spaces;

  @include xs {
    font-size: 0.8rem;
    line-height: 1rem;
  }

  @include sm {
    font-size: 0.8rem;
    line-height: 1rem;
  }
}
