// All styles for the app.
@import 'styles/styles';

.ody-app {
  @extend %vbox;

  height: 100vh;
  max-height: 100vh;

  a,
  button {
    cursor: pointer;

    a:hover,
    button:hover {
      cursor: pointer;
    }
  }
}
