// All styles for the home our companies say section.
@import 'styles/styles';

.home-our-companies-say-section {
  @extend %vbox;

  background-color: $silver-lake-blue;
  justify-content: center;
  padding: 7.5rem 0;

  @include xs {
    padding: 3rem 0;
  }

  @include sm {
    padding: 5rem 0;
  }

  &__title {
    margin-bottom: 2.5rem;
    text-align: center;
  }

  .carousel {
    & * {
      box-sizing: initial;
    }

    .slide {
      img {
        height: 100%;
        width: 100%;
      }
    }

    &.carousel-slider {
      height: 53rem;

      @include xs {
        height: 73rem;
      }

      @include sm {
        height: 73rem;
      }
    }

    .control-dots {
      margin: 0;

      .dot {
        background-color: $beau-blue;
        box-shadow: none;

        &.selected {
          background-color: $white;
        }
      }
    }
  }
}
