// All styles for the learn more endorser action card.
@import 'styles/styles';

.learn-more-endorser-action-card {
  @extend %hbox;

  align-items: center;
  background-color: $rhythm;
  border-radius: 1.25rem;
  box-shadow: $result-card-hover-shadow;
  justify-content: center;
  max-width: 18.5rem;
  padding: 4.625rem 3rem 3.875rem;
  position: relative;
  text-align: center;

  @include xs {
    max-width: 100%;
    padding: 3rem 2rem 2.5rem;
  }

  @include sm {
    max-width: 100%;
    padding: 3rem 2rem 2.5rem;
  }

  @include md {
    justify-content: flex-start;
    max-width: 14.5rem;
    opacity: 0;
    padding: 4rem 2.5rem 3rem;
  }

  @include lg {
    opacity: 0;
  }

  &__visible {
    @include sm {
      animation: slide-from-left-in 0.5s ease-in-out forwards;
      transform: translateX(-6.25rem);
    }

    @include md {
      animation: fade-in-up 0.5s ease-in-out forwards;
      transform: translateY(6.25rem);
    }

    @include lg {
      animation: fade-in-up 0.5s ease-in-out forwards;
      transform: translateY(6.25rem);
    }
  }

  &__icon-container {
    @extend %hbox;

    align-items: center;
    background-color: $white;
    border-radius: 9999px;
    box-shadow: $result-card-hover-shadow;
    height: 3.125rem;
    justify-content: center;
    left: 50%;
    padding: 1.56rem;
    position: absolute;
    top: -50%;
    transform: translate(-50%, 50%);
    width: 3.125rem;

    @include xs {
      height: 1.5rem;
      transform: translate(-50%, 40%);
      width: 1.5rem;
    }

    @include sm {
      height: 2rem;
      transform: translate(-50%, 25%);
      width: 2rem;
    }

    @include md {
      height: 2.5rem;
      width: 2.5rem;
    }
  }
}
