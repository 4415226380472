// All styles for the endorsement information.
@import 'styles/styles';

[role='dialog'].popup-content.endorsement-information-modal-content {
  @extend %vbox;

  height: auto;
  max-height: 95vh;
  max-width: 33rem;
  overflow: hidden;
  width: calc(100% - 2rem);

  > .endorsement-information {
    @extend %discreet-scrollable;
    @extend %vbox;

    background-color: $white;
    border-radius: 1.25rem;
    flex: 1;
    gap: 1.5rem;
    height: 100%;
    padding: 1.5rem 3rem;

    > .endorsement-information__section {
      @extend %vbox;

      > .endorsement-information__company-information {
        color: $dove-gray;
      }
    }

    > .endorsement-information__section-main {
      gap: 0.5rem;

      > .endorsement-information__section-question {
        color: $dove-gray;
      }

      > .endorsement-information__section-answer-container {
        @extend %hbox;

        gap: 0.625rem;
      }

      > .endorsement-information__section-answer-personality-qualities {
        @extend %hbox;

        gap: 0.5rem;
      }
    }
  }
}
