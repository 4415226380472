// All styles for the auth social buttons.
@import 'styles/styles';

.auth-social-buttons {
  @extend %vbox;

  gap: 0.5rem;
  width: 100%;

  &__button {
    @extend %hbox;

    align-items: center;
    gap: 0.5rem;
    justify-content: center;

    @include xs {
      font-size: 1rem;
      padding: 1rem;
      width: 100%;
    }

    @include sm {
      font-size: 1rem;
      padding: 1rem 3.5rem;
    }

    @include md {
      font-size: 1.5rem;
      padding: 1rem 3.5rem;
    }

    @include lg {
      font-size: 1.5rem;
      padding: 1rem 3.5rem;
    }
  }
}
