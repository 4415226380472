// All helper classes.

// Something that is hidden.
.hidden {
  display: none !important;
}

// Something that is hidden in xs.
.hidden-xs {
  @include xs {
    display: none !important;
  }
}

// Something that is hidden in sm.
.hidden-sm {
  @include sm {
    display: none !important;
  }
}

// Something that is hidden in md.
.hidden-md {
  @include md {
    display: none !important;
  }
}

// Something that is hidden in lg.
.hidden-lg {
  @include lg {
    display: none !important;
  }
}

// Something with light colors.
.light {
  color: $white;
}

// Something with dark colors.
.dark {
  color: $raisin-black;
}

// Some link.
.link {
  color: $raisin-black;
  cursor: pointer;
  font-family: $commissioner;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.25rem;

  &:hover {
    text-decoration: underline 0.15rem $silver-lake-blue;
    text-underline-offset: 0.375rem;
    transition: text-decoration 0.3s ease;
  }

  &__black {
    &:hover {
      text-decoration: underline 0.15rem $black;
      text-underline-offset: 0.375rem;
      transition: text-decoration 0.3s ease;
    }
  }

  &.inline {
    color: $silver-lake-blue;
  }
}

// Some statistics.
.statistics {
  @extend %hbox;

  align-self: stretch;

  @include xs {
    @include vbox;

    justify-content: center;
    padding-top: 0;
    width: 100%;
  }

  > .statistic {
    @extend %vbox;

    align-items: stretch;
    flex: 1;
    padding-bottom: 6rem;
    padding-top: 8rem;
    text-align: center;

    @include xs {
      justify-content: center;
      min-height: 11rem;
      padding: 0;
    }

    @include sm {
      padding-bottom: 2rem;
      padding-top: 3rem;
    }

    @include md {
      padding-bottom: 4rem;
      padding-top: 5.5rem;
    }

    > .number {
      font-family: $commissioner;
      font-weight: 600;
      padding-bottom: 0.5rem;

      @include xs {
        font-size: 2.5rem;
        line-height: 3rem;
      }

      @include sm {
        font-size: 2.25rem;
        line-height: 2.75rem;
      }

      @include md {
        font-size: 3rem;
        line-height: 3.625rem;
      }

      @include lg {
        font-size: 4rem;
        line-height: 5rem;
      }
    }

    > .label {
      align-self: center;
      font-family: $commissioner;
      font-weight: normal;
      max-width: 20.125rem;

      @include xs {
        font-size: 1.375rem;
        line-height: 1.75rem;
        padding: 0 1rem;
      }

      @include sm {
        font-size: 1rem;
        line-height: 1.5rem;
        padding: 0 1rem;
      }

      @include md {
        font-size: 1.5rem;
        line-height: 1.875rem;
        padding: 0 2rem;
      }

      @include lg {
        font-size: 2.125rem;
        line-height: 2.625rem;
        padding: 0 2rem;
      }
    }
  }
}

// Some divider.
.divider {
  border-top: 1px solid $silver-sand;
  height: 0;
  margin: 1rem 0;
  overflow: hidden;
}

// Something that is hidden, but still affects the layout.
.no-visibility {
  visibility: hidden !important;
}

// margin right 1rem
.margin-right-1 {
  margin-right: 1rem;
}
