// All placeholders.

// Something that adjusts its height to its content, and has its overflow clipped.
%fit {
  @include fit;
}

// Something that displays its items horizontally.
%hbox {
  @include hbox;
}

// Something that displays its items vertically.
%vbox {
  @include vbox;
}

// truncates a text to a single line
%truncate {
  @include truncate;
}

// wrap a text to its width
%wrap {
  @include wrap;
}

// Something that scrolls.
%scrollable {
  overflow-y: auto;
  scrollbar-color: $silver-lake-blue $white;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    height: 0;
    width: 0.6rem;
  }

  &::-webkit-scrollbar-track {
    background-color: $white;
    -webkit-border-radius: auto;
    border-radius: auto;
    -webkit-box-shadow: auto;
    box-shadow: auto;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $silver-lake-blue;
    -webkit-border-radius: auto;
    border-radius: auto;
    -webkit-box-shadow: auto;
    box-shadow: auto;
  }
}

// Something that scrolls discreetly.
%discreet-scrollable {
  @extend %scrollable;

  overflow-y: auto;

  &::-webkit-scrollbar {
    height: 0;
    width: 0;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}

// Something that cannot be selected.
%no-select {
  user-select: none;
}
