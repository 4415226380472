// All styles for the video guide card.
@import 'styles/styles';

.video-guide-card {
  @extend %vbox;

  border: 1px solid $moonstone-blue;
  border-radius: 1.25rem;
  gap: 0.6875rem;
  max-width: 16.5rem;
  padding: 1rem 1.5rem;

  &__title {
    &.hdg.hdg-sm {
      font-weight: 700;
    }
  }

  &__description {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    flex: 1 1 auto;
    overflow: hidden;

    &--video {
      @include truncate-box-vertical(3);

      @include xs {
        @include truncate-box-vertical(4);
      }

      @include sm {
        @include truncate-box-vertical(4);
      }
    }

    &--guide {
      @include truncate-box-vertical(10);

      @include xs {
        @include truncate-box-vertical(4);
      }

      @include sm {
        @include truncate-box-vertical(4);
      }
    }
  }
}
