// All styles for tags.
@import 'styles/styles';

.ody-tags {
  .ody-tags-input-field {
    display: none;
  }

  .ody-tags-selected-options {
    @extend %hbox;

    flex-flow: wrap;
    gap: 0.5rem;

    > .ody-tags-selected-option {
      @extend %hbox;

      background-color: transparent;
      border: 1px solid $raisin-black;
      border-radius: 0.5rem;
      color: $raisin-black;
      font-family: $commissioner;
      font-size: 1rem;
      padding: 0.5rem;

      > .ody-tags-remove {
        background-color: transparent;
        border: none;
        color: $raisin-black;
        font-family: $commissioner;
        font-size: 1rem;
        margin-left: 1rem;
      }
    }
  }
}
