// Generic styles for svg icons.
.card-icon {
  width: 2.56rem;

  @include xs {
    width: 1.56rem;
  }

  @include sm {
    width: 1.56rem;
  }
}
