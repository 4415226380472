// All styles for the footer.
@import 'styles/styles';

.ody-footer {
  @extend %vbox;

  align-items: center;
  background-color: $white;
  justify-content: center;

  @include xs {
    padding: 1rem 0;
  }

  @include sm {
    padding: 2rem 0;
  }

  @include md {
    padding: 3rem 0;
  }

  @include lg {
    padding: 7.5rem 0;
  }

  &__blue {
    background-color: $moonstone-blue;
  }

  &__dark-blue {
    background-color: $silver-lake-blue;
  }

  &__yellow {
    background-color: $maximum-yellow-red;
  }

  &__red {
    background-color: $middle-red;
  }

  &__purple {
    background-color: $african-violet;
  }

  > .brand {
    color: $raisin-black;
    font-family: $iwona;
    font-size: 2.125rem;
    font-weight: 700;

    @include xs {
      margin-bottom: 2rem;
    }

    @include sm {
      margin-bottom: 2rem;
    }

    @include md {
      margin-bottom: 2rem;
    }

    @include lg {
      margin-bottom: 3rem;
    }
  }

  > .contact {
    @extend %hbox;

    align-items: center;
    color: $raisin-black;
    font-family: $commissioner;
    gap: 1rem;
    margin-bottom: 1.5rem;

    @include xs {
      @include vbox;

      margin-bottom: 1.25rem;
    }

    @include sm {
      margin-bottom: 2.25rem;
    }

    @include md {
      margin-bottom: 2.25rem;
    }

    @include lg {
      margin-bottom: 3.25rem;
    }

    > .link {
      &:not(:last-of-type) {
        @include sm {
          border-right: 1px solid $black;
          padding-right: 1rem;
        }

        @include md {
          border-right: 1px solid $black;
          padding-right: 1rem;
        }

        @include lg {
          border-right: 1px solid $black;
          padding-right: 1rem;
        }
      }
    }
  }

  > .social {
    @extend %hbox;

    align-items: center;
    gap: 1.87rem;

    @include xs {
      margin-bottom: 1.25rem;
    }

    @include sm {
      margin-bottom: 2.25rem;
    }

    @include md {
      margin-bottom: 2.25rem;
    }

    @include lg {
      margin-bottom: 3.25rem;
    }

    > a {
      > .icon {
        width: 1.43rem;
      }
    }
  }

  > .links {
    @include hbox;

    gap: 1.62rem;

    > .link {
      &:not(:last-of-type) {
        @include sm {
          border-right: 1px solid $black;
          padding-right: 1rem;
        }

        @include md {
          border-right: 1px solid $black;
          padding-right: 1rem;
        }

        @include lg {
          border-right: 1px solid $black;
          padding-right: 1rem;
        }
      }
    }

    @include xs {
      @include vbox;

      gap: 1rem;
    }

    > a {
      text-align: center;
    }
  }
}
