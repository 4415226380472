// All styles for the learn more profile features section.
@import 'styles/styles';

.learn-more-profile-features-section {
  @extend %vbox;

  background-color: $marigold;
  gap: 6.25rem;
  padding: 5% 6%;

  @include xs {
    gap: 2.25rem;
    padding: 5%;
  }

  @include sm {
    gap: 4.25rem;
    padding: 5%;
  }

  @include md {
    padding: 5% 4%;
  }
}
