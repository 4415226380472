// All styles for share-profile buttons.
@import 'styles/styles';

.share-profile-button {
  @extend %hbox;

  > .ody-modal {
    @extend %hbox;

    flex: 1;

    > .btn {
      align-items: center;
      display: flex;
      flex: 1;
      justify-content: center;

      img {
        margin-right: 1rem;
      }
    }
  }
}

[role='dialog'].popup-content.share-profile-modal-content {
  @extend %vbox;

  height: auto;
  max-width: 40rem;
  padding: 1rem;

  @include xs {
    max-width: 20rem;
  }

  @include sm {
    max-width: 34rem;
  }

  > .share-profile {
    @extend %vbox;

    background-color: $white;
    border-radius: 1.25rem;
    padding: 1.5rem;

    > .hdg-md {
      padding-bottom: 1.5rem;
    }

    > .profile-link-and-copy {
      @include hbox;

      gap: 1rem;

      @include xs {
        @include vbox;
      }

      > .profile-link {
        background-color: $silver-sand;
        border: none;
        border-radius: 0.5rem;
        flex: 1;
        font-family: $commissioner;
        font-size: 1rem;
        font-weight: normal;
        line-height: 1.5rem;
        outline: none;
        overflow: hidden;
        padding: 0.5rem 1rem;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      > button {
        white-space: nowrap;
      }
    }
  }
}
