// All styles for company agent contact buttons.
@import 'styles/styles';

.contact-button {
  @extend %hbox;

  > .ody-modal.contact-modal {
    @extend %hbox;

    flex: 1;

    > .btn {
      align-items: center;
      display: flex;
      flex: 1;
      justify-content: center;
    }

    > .button-banner {
      color: $moonstone-blue;
    }

    > .ody-profile__banner-button {
      color: $moonstone-blue;
    }
  }
}

[role='dialog'].popup-content.contact-modal-content {
  @extend %vbox;

  height: auto;
  max-width: 33rem;
  padding: 1rem;

  @include xs {
    max-width: 20rem;
  }

  > .contact-odyssea {
    @extend %vbox;

    background-color: $white;
    border-radius: 1.25rem;

    > .header {
      margin-bottom: 1.75rem;
      padding: 2.31rem 0 0 4.25rem;

      @include xs {
        padding: 1.25rem 0 0 1.25rem;
      }

      > .header-logo {
        max-height: 16.02rem;

        @include xs {
          max-height: 10.6rem;
        }
      }
    }

    > .contact-content {
      @extend %vbox;

      align-items: center;
      justify-content: center;
      padding-bottom: 4.125rem;

      > .links {
        @extend %vbox;

        align-items: center;
        justify-content: center;
        margin-top: 1rem;

        > .link {
          @extend %hbox;

          align-items: center;
          justify-content: center;

          > .logo {
            margin-right: 0.625rem;
          }

          &:not(:last-of-type) {
            margin-bottom: 0.625rem;
          }
        }
      }
    }
  }
}
