// All styles for confirm modals.
@import 'styles/styles';

[role='dialog'].popup-content.confirm-modal-content {
  @extend %vbox;

  height: auto;
  max-width: 40rem;
  padding: 1rem;

  @include xs {
    max-width: 20rem;
  }

  @include sm {
    max-width: 34rem;
  }

  > .confirm {
    @extend %vbox;

    background-color: $white;
    border-radius: 1.25rem;
    gap: 2rem;
    padding: 1.5rem;

    > .buttons {
      @extend %hbox;

      align-self: flex-end;
      gap: 1rem;

      > .btn-custom {
        font-size: 1.375rem;
        padding: 0.5rem 1.5rem;

        @include xs {
          font-size: 1rem;
        }

        @include sm {
          font-size: 1rem;
        }
      }
    }
  }
}
