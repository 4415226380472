// All styles for the terms of service page.
@import 'styles/styles';

.ody-terms-of-service {
  padding: 3rem 22.5%;

  @include xs {
    padding: 1rem 5%;
  }

  > .ody-markdown > :first-child {
    margin-top: 0;
  }
}
