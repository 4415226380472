// All styles for the social responsibility scr packages section.
@import 'styles/styles';

.social-responsibility-csr-packages-section {
  @extend %hbox;

  align-items: center;
  background-color: $isabelline;
  flex-wrap: wrap;
  gap: 2.5rem;
  justify-content: flex-start;
  overflow: hidden;
  padding: 5% 6%;

  @include xs {
    gap: 1.5rem;
    padding: 6% 0 4%;
  }

  @include sm {
    gap: 2rem;
    padding: 6% 0 4%;
  }

  @include md {
    gap: 2.5rem;
    padding: 5% 4%;
  }

  &__title {
    text-align: left;
    width: 100%;

    @include xs {
      padding-left: 4%;
    }

    @include sm {
      padding-left: 4%;
    }
  }

  .carousel {
    &.carousel-slider {
      height: 28rem;
    }

    .slider-wrapper.axis-horizontal {
      width: 100vw;
    }

    .slide {
      @extend %hbox;

      align-items: center;
      justify-content: center;
    }

    .control-dots {
      margin-top: 1.25rem;
      width: 100vw;

      .dot {
        background-color: $beau-blue;
        box-shadow: none;

        &.selected {
          background-color: $silver-lake-blue;
        }
      }
    }
  }
}
