// All styles for the endorsement form.
@import 'styles/styles';

.endorsement-form {
  @extend %vbox;

  gap: 3.875rem;

  &__hashtags-container {
    @extend %vbox;

    gap: 0.5rem;
  }

  &__hashtags-box {
    position: relative;
  }

  &__actions {
    @extend %hbox;

    gap: 1rem;
    justify-content: flex-end;
  }
}
