// All styles for the profile info row.
@import 'styles/styles';

.profile-info-row {
  @extend %hbox;

  padding-bottom: 1rem;

  &__actions {
    @extend %hbox;

    flex: 1;
    gap: 0.5rem;
    justify-content: flex-end;
  }

  &__action {
    @extend %hbox;

    align-items: center;
    border-radius: 9999px;
    height: 2.5rem;
    justify-content: center;
    padding: 0.5rem 0.6rem;
    width: 2.5rem;
  }
}

.profile-info-field {
  @extend %hbox;

  align-items: center;

  &:not(:last-child) {
    padding-bottom: 1rem;
  }

  &__icon {
    @extend %hbox;

    align-items: center;
    width: 2rem;
  }

  &__name {
    font-weight: 500;
  }

  &__value {
    flex: 1;
  }
}
