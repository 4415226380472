// All styles for the home page find talent section.
@import 'styles/styles';

.home-find-talent-section {
  @extend %hbox;

  align-items: center;
  background-color: $isabelline;
  justify-content: space-between;
  min-height: 47.93rem;

  @include xs {
    @include vbox;

    flex-direction: column-reverse;
    min-height: 27.93rem;
    padding: 5%;
  }

  @include sm {
    @include vbox;

    flex-direction: column-reverse;
    min-height: 37.93rem;
    padding: 5% 0;
  }

  &__info-container {
    max-width: 33.5rem;
    padding-left: 8%;

    @include xs {
      padding: 0;
    }

    @include sm {
      padding: 0;
    }

    @include md {
      max-width: 26.5rem;
      padding-left: 5%;
    }
  }

  &__title {
    margin: 0 0 1rem;
  }

  &__info {
    margin-bottom: 2.62rem;

    &.txt-lg {
      @include xs {
        font-size: 1rem;
      }

      @include sm {
        font-size: 1rem;
      }

      @include md {
        font-size: 1.5rem;
      }
    }
  }

  &__explore-button {
    &.btn {
      @extend %hbox;

      align-items: center;
      justify-content: center;
      width: 60%;

      @include xs {
        width: 90%;
      }

      @include sm {
        width: 90%;
      }
    }

    &.btn-sm {
      padding: 1rem;

      @include xs {
        padding: 0.5rem 1rem;
      }
    }

    &.btn-blue {
      color: $black;
      font-size: 1.5rem;

      @include xs {
        font-size: 1rem;
      }

      @include sm {
        font-size: 1rem;
      }
    }
  }

  &__animated-icon {
    @include xs {
      height: 11rem;
    }

    @include sm {
      height: 20.5rem;
    }

    @include md {
      height: 20.5rem;
    }

    @include lg {
      height: 27.5rem;
    }
  }
}
