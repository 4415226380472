// All classes that can be applied to buttons.

// Some button.
.btn {
  border: transparent;
  cursor: pointer;
  font-family: $commissioner;
  font-weight: normal;
  transition: all 0.3s ease;

  &:hover {
    box-shadow: $button-box-shadow;
  }

  &:active {
    box-shadow: none;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;

    &:hover {
      box-shadow: none;
    }

    &:active {
      box-shadow: none;
    }
  }
}

// Some small button.
.btn-sm {
  font-size: 1rem;
  padding: 0.75rem 1rem;
}

// Some large button.
.btn-lg {
  font-weight: 500;

  @include xs {
    font-size: 1rem;
    padding: 1rem;
    width: 100%;
  }

  @include sm {
    font-size: 1rem;
    padding: 1rem 3.5rem;
  }

  @include md {
    font-size: 2rem;
    padding: 1rem 3.5rem;
  }

  @include lg {
    font-size: 2rem;
    padding: 1rem 3.5rem;
  }
}

// Some button with small border radius.
.btn-rounded-sm {
  border-radius: 0.5rem;
}

// Some button with large border radius.
.btn-rounded-lg {
  @include xs {
    border-radius: 2.5rem;
  }

  @include sm {
    border-radius: 1rem;
  }

  @include md {
    border-radius: 1rem;
  }

  @include lg {
    border-radius: 1rem;
  }
}

// Some blue button.
.btn-blue {
  background-color: $silver-lake-blue;
  color: $white;

  &:disabled {
    background-color: $silver-sand;
    cursor: not-allowed;
  }
}

// Some yellow button.
.btn-yellow {
  background-color: $maximum-yellow-red;
  color: $white;

  &:disabled {
    background-color: $silver-sand;
    cursor: not-allowed;
  }
}

// Some yellow darker button
.btn-yellow-dark {
  background-color: $marigold;
  color: $white;

  &:disabled {
    background-color: $silver-sand;
    cursor: not-allowed;
  }
}

// Some white button.
.btn-white {
  background-color: $white;
  border: 1px solid $silver-sand;
  color: $raisin-black;

  &:disabled {
    border-color: $silver-sand;
    color: $silver-sand;
    cursor: not-allowed;
  }
}

// Some red button.
.btn-red {
  background-color: $copper-red;
  color: $white;

  &:disabled {
    background-color: $silver-sand;
    cursor: not-allowed;
  }
}

// Some purple button.
.btn-purple {
  background-color: $african-violet;
  color: $white;

  &:disabled {
    background-color: $silver-sand;
    cursor: not-allowed;
  }
}

// Some transparent button.
.btn-trans {
  background-color: transparent;
  color: $raisin-black;
}

// Some button that is really a link.
.btn-link {
  background-color: transparent;
  border: transparent;
  color: $raisin-black;
  font-family: $commissioner;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.25rem;

  &:hover {
    text-decoration: underline 0.15rem $silver-lake-blue;
    text-underline-offset: 0.375rem;
    transition: text-decoration-color 300ms;
  }
}
