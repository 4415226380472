@keyframes fade-in-up {
  from {
    opacity: 0;
    transform: translateY(6.25rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-from-left-in {
  from {
    opacity: 0;
    transform: translateX(-6.25rem);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-from-right-in {
  from {
    opacity: 0;
    transform: translateX(6.25rem);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes light-bounce {
  0% {
    transform: scaleY(1);
  }

  50% {
    transform: scaleY(0.93);
  }

  100% {
    transform: scaleY(1);
  }
}
