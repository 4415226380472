// All styles for the learn more become endorser partner section.
@import 'styles/styles';

.learn-more-become-endorser-partner-section {
  @extend %hbox;

  align-items: center;
  background-color: $rhythm;
  justify-content: space-evenly;
  padding: 5% 8%;
  position: relative;

  @include xs {
    @include vbox;

    flex-direction: column-reverse;
    gap: 1.5rem;
    padding: 8% 4% 4%;
  }

  @include sm {
    @include vbox;

    flex-direction: column-reverse;
    gap: 2rem;
    padding: 8% 4% 4%;
  }

  @include md {
    justify-content: space-between;
    padding: 5%;
  }

  &__content {
    @extend %vbox;

    gap: 1.62rem;
    max-width: 36.1rem;

    @include xs {
      gap: 1.375rem;
      max-width: 100%;
    }

    @include sm {
      gap: 1.5rem;
      max-width: 100%;
    }
  }

  &__description {
    white-space: break-spaces;

    &.txt-md {
      @include xs {
        font-size: 1rem;
        line-height: 1.5rem;
      }

      @include sm {
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }
  }

  &__join-us-button {
    &.btn {
      max-width: fit-content;
      min-width: 30%;

      @include xs {
        max-width: 100%;
        min-width: auto;
      }

      @include sm {
        max-width: 100%;
        min-width: auto;
      }
    }

    &.btn-sm {
      font-size: 2rem;
      line-height: 2.44rem;
      padding: 1rem;
      text-align: center;

      @include xs {
        font-size: 1.375rem;
        line-height: 1.5rem;
        padding: 0.5rem 1rem;
      }

      @include sm {
        font-size: 1.5rem;
        line-height: 1.75rem;
      }

      @include md {
        font-size: 1.75rem;
        line-height: 2rem;
      }
    }

    &.btn-white {
      background-color: $isabelline;
      color: $tundora;
      font-weight: 600;
      text-align: center;
    }
  }

  &__example-endorsement-button {
    left: 50%;
    min-width: 31.31rem;
    position: absolute;
    top: -2.18rem;
    transform: translateX(-50%);

    @include xs {
      min-width: 20.62rem;
    }

    &.btn {
      border: none;
    }

    &.btn-rounded-sm {
      border-radius: 2.5rem;
    }

    &.btn-sm {
      font-size: 2rem;
      line-height: 2.44rem;
      padding: 1rem;

      @include xs {
        font-size: 1.375rem;
        line-height: 1.5rem;
        padding: 0.5rem 1rem;
      }

      @include sm {
        font-size: 1.5rem;
        line-height: 1.75rem;
      }

      @include md {
        font-size: 1.75rem;
        line-height: 2rem;
      }
    }

    &.btn-white {
      background-color: $isabelline;
      color: $tundora;
      font-weight: 600;
      text-align: center;
    }
  }

  &__animation-container {
    max-width: 50%;

    @include xs {
      max-width: 100%;
    }

    @include sm {
      max-width: 100%;
    }
  }
}

[role='dialog'].popup-content.see-example-endorsement-modal-content {
  background-color: transparent;
  flex: 0 0;

  > .see-example-endorsement {
    border-radius: 1.25rem;
    height: 100%;
    justify-content: center;
    margin: 0 auto;
    overflow: hidden;
    width: fit-content;

    > .image {
      @extend %discreet-scrollable;

      height: 100%;

      > img {
        display: block;
        margin: 0 auto;
      }
    }
  }
}
