// All styles for the social responsibility sdg section.
@import 'styles/styles';

.social-responsibility-sdg-section {
  @extend %vbox;

  align-items: center;
  background-color: $middle-red;
  gap: 2.5rem;
  justify-content: center;
  padding: 5% 10%;

  @include xs {
    padding: 3%;
  }

  @include sm {
    padding: 4%;
  }

  @include md {
    padding: 5%;
  }

  &__title {
    text-align: left;
    width: 100%;
  }

  &__sdgs-container {
    @extend %hbox;

    flex-wrap: wrap;
    gap: 1.875rem;
    transition: all 0.3s ease;

    @include xs {
      align-items: center;
      gap: 1.375rem;
      justify-content: center;
    }

    @include sm {
      align-items: center;
      gap: 1.375rem;
      justify-content: center;
    }

    @include md {
      gap: 1.5rem;
    }
  }

  &__sdg {
    @extend %hbox;

    align-items: center;
    background-color: $white;
    border-radius: 1.25rem;
    box-shadow: $result-card-hover-shadow;
    height: 12.5rem;
    justify-content: center;
    width: 12.5rem;

    @include xs {
      height: 8rem;
      width: 8rem;
    }

    @include sm {
      height: 9rem;
      width: 9rem;
    }

    @include md {
      height: 10rem;
      width: 10rem;
    }
  }

  &__image {
    width: 100%;
  }

  &__webpage-link {
    @extend %hbox;

    align-items: center;
    border: 5px dashed $white;
    border-radius: 1.25rem;
    color: $isabelline;
    font-family: $commissioner;
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    height: 12rem;
    justify-content: center;
    line-height: 2.43rem;
    text-align: center;
    transition: all 0.3s ease;
    width: 12rem;

    &:hover {
      box-shadow: $result-card-hover-shadow;
    }

    @include xs {
      font-size: 1rem;
      height: 7.5rem;
      line-height: 1.5rem;
      width: 7.5rem;
    }

    @include sm {
      font-size: 1rem;
      height: 8rem;
      line-height: 1.5rem;
      width: 8rem;
    }

    @include md {
      font-size: 1.5rem;
      height: 9.5rem;
      line-height: 2rem;
      width: 9.5rem;
    }
  }
}
