// All styles for the about us welcome section.
@import 'styles/styles';

.about-us-welcome-section {
  @extend %vbox;

  background-color: $moonstone-blue;
  padding: 6% 10% 0;

  @include xs {
    padding: 4%;
  }

  @include sm {
    padding: 4%;
  }

  @include md {
    margin-bottom: -7rem;
    padding: 5% 8% 0;
  }

  @include lg {
    margin-bottom: -6.25rem;
  }

  &__content-section {
    @extend %hbox;

    align-items: center;
    gap: 2rem;
    justify-content: space-between;
    margin-bottom: 7.43rem;

    @include xs {
      @include vbox;

      gap: 1.5rem;
      justify-content: center;
      margin-bottom: 2rem;
    }

    @include sm {
      @include vbox;

      gap: 1.5rem;
      justify-content: center;
      margin-bottom: 3rem;
    }

    @include md {
      gap: 1.5rem;
      margin-bottom: 4rem;
    }
  }

  &__logo-link-container {
    @extend %vbox;

    gap: 2.25rem;

    @include xs {
      gap: 1.5rem;
    }

    @include sm {
      gap: 1.5rem;
    }

    @include md {
      gap: 2rem;
      min-width: 22.125rem;
    }

    @include lg {
      min-width: 27.125rem;
    }
  }

  &__link {
    &.btn {
      @extend %hbox;

      align-items: center;
      justify-content: center;
    }

    &.btn-sm {
      padding: 1rem;

      @include xs {
        padding: 0.5rem 1rem;
      }
    }

    &.btn-white {
      color: $tundora;
      font-size: 1.5rem;
      font-weight: 600;

      @include xs {
        font-size: 1rem;
      }

      @include sm {
        font-size: 1rem;
      }
    }
  }

  &__info-container {
    @extend %vbox;

    gap: 1.25rem;
    max-width: 33.062rem;

    @include xs {
      gap: 1rem;
      max-width: 100%;
    }

    @include sm {
      gap: 1rem;
      max-width: 100%;
    }

    @include md {
      animation: fade-in-up 0.5s ease-in-out forwards;
      opacity: 0;
    }

    @include lg {
      animation: fade-in-up 0.5s ease-in-out forwards;
      opacity: 0;
    }
  }

  &__title {
    margin: 0;
  }

  &__description {
    &.txt-lg {
      @include xs {
        font-size: 1rem;
        line-height: 1.5rem;
      }

      @include sm {
        font-size: 1rem;
        line-height: 1.5rem;
      }

      @include md {
        font-size: 1.375rem;
        line-height: 1.75rem;
      }
    }
  }
}
