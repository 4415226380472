// All styles for range filters.
@import 'styles/styles';

.ody-filter.range {
  @extend %vbox;

  gap: 1rem;

  > .title-and-expand-collapse {
    @extend %hbox;

    align-items: center;
    justify-content: space-between;

    > .title {
      color: $raisin-black;
      font-family: $commissioner;
      font-size: 1.375rem;
      font-weight: 700;

      @include xs {
        font-size: 1rem;
      }

      @include sm {
        font-size: 1rem;
      }
    }
  }

  > .range > .ody-slider {
    margin-top: 1.5rem;
    padding-bottom: 2rem;
  }

  &.collapsed > .range {
    max-height: 0;
    overflow: hidden;
    transition: max-height 100ms ease-out;
  }

  &.expanded > .range {
    max-height: 500rem;
    transition: max-height 100ms ease-in;
  }
}
