// All styles for carousels.
@import 'styles/styles';

.ody-carousel {
  > .carousel {
    img {
      width: auto;
    }

    .slide {
      text-align: left;

      img {
        width: auto;
      }
    }
  }
}
