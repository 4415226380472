// All styles for the learn white section.
@import 'styles/styles';

.learn-more-white-section {
  @extend %hbox;

  align-items: center;
  background-color: $isabelline;
  justify-content: space-between;
  padding: 5% 6%;

  @include xs {
    @include vbox;

    flex-direction: column-reverse;
    gap: 1.5rem;
    padding: 4% 6%;
  }

  @include sm {
    @include vbox;

    flex-direction: column-reverse;
    gap: 2rem;
    padding: 4% 6%;
  }

  @include md {
    padding: 5% 4%;
  }

  &__content-container {
    @extend %vbox;

    max-width: 42rem;

    @include xs {
      max-width: 100%;
    }

    @include sm {
      max-width: 100%;
    }

    @include md {
      max-width: 31rem;
    }
  }

  &__title {
    margin-bottom: 3.18rem;

    @include xs {
      margin-bottom: 1.5rem;
    }

    @include sm {
      margin-bottom: 2rem;
    }

    @include md {
      margin-bottom: 2.5rem;
    }
  }

  &__description {
    &.txt {
      @include xs {
        font-size: 1rem;
      }

      @include sm {
        font-size: 1rem;
      }

      @include md {
        font-size: 1rem;
      }
    }

    &--has-button {
      margin-bottom: 5.93rem;

      @include xs {
        margin-bottom: 1.5rem;
      }

      @include sm {
        margin-bottom: 2.5rem;
      }

      @include md {
        margin-bottom: 4.5rem;
      }
    }
  }

  &__button {
    &.btn {
      width: 70%;

      @include xs {
        width: 100%;
      }

      @include sm {
        width: 100%;
      }

      @include md {
        width: 80%;
      }
    }

    &.btn-sm {
      padding: 1rem 0;

      @include xs {
        padding: 0.5rem 0;
      }
    }

    &.btn-red {
      border: none;
      color: $white;
      font-size: 1.5rem;
      font-weight: 700;
      text-align: center;

      @include xs {
        font-size: 1rem;
      }

      @include sm {
        font-size: 1rem;
      }
    }

    &.btn-white {
      border: none;
      color: $tundora;
      font-size: 1.5rem;
      font-weight: 700;
      text-align: center;

      @include xs {
        font-size: 1rem;
      }

      @include sm {
        font-size: 1rem;
      }
    }
  }

  &__animation-container {
    max-width: 50%;

    @include xs {
      max-width: 100%;
    }

    @include sm {
      max-width: 100%;
    }
  }
}
