// All styles for markdown.
@import 'styles/styles';

.ody-markdown {
  > h1 {
    font-family: $iwona;
    margin-bottom: 1rem;
    margin-top: 2rem;
  }

  > h2 {
    font-family: $iwona;
    margin-bottom: 1rem;
  }

  > h3 {
    font-family: $iwona;
    margin-bottom: 1rem;
  }

  > p {
    font-family: $commissioner;
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.5rem;
    padding-bottom: 1rem;
  }

  > ul {
    font-family: $commissioner;
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.5rem;
    padding-bottom: 1rem;
  }

  a {
    color: $silver-lake-blue;
    cursor: pointer;
    font-family: $commissioner;
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.25rem;
  }

  > table {
    border: 1px solid $raisin-black;
    border-collapse: collapse;
    font-family: $commissioner;
    font-size: 1rem;
    margin-bottom: 1rem;

    > thead > tr > th,
    > tbody > tr > td {
      border: 1px solid $raisin-black;
      border-collapse: collapse;
      padding: 0.25rem 1rem;
    }
  }
}
