// All styles for the learn more our endorsers section.
@import 'styles/styles';

.learn-more-our-endorsers-section {
  @extend %vbox;

  background-color: $isabelline;
  justify-content: flex-start;
  overflow: hidden;
  padding: 5% 8%;

  @include xs {
    padding: 4% 0;
  }

  @include sm {
    padding: 4% 0;
  }

  @include md {
    padding: 5%;
  }

  &__title {
    margin-bottom: 3rem;
    text-align: left;
    width: 100%;

    @include xs {
      margin-bottom: 1.5rem;
      text-align: center;
    }

    @include sm {
      margin-bottom: 2rem;
      text-align: center;
    }

    @include md {
      text-align: center;
    }
  }

  &__cards-container {
    @extend %hbox;

    flex-wrap: wrap;
    gap: 1.5rem;
    margin-bottom: 4.375rem;

    @include md {
      align-items: center;
      gap: 4rem;
      justify-content: center;
    }
  }

  &__button-container {
    align-self: flex-start;

    @include xs {
      align-self: center;
      width: 92%;
    }

    @include sm {
      align-self: center;
      width: 92%;
    }

    @include md {
      align-self: center;
      width: 30%;
    }
  }

  &__button {
    display: block;
    text-align: center;

    &.btn-sm {
      font-size: 2rem;
      line-height: 2.44rem;
      padding: 1rem;

      @include xs {
        font-size: 1.375rem;
        line-height: 1.5rem;
        padding: 0.5rem 1rem;
      }

      @include sm {
        font-size: 1.5rem;
        line-height: 1.75rem;
      }

      @include md {
        font-size: 1.75rem;
        line-height: 2rem;
      }
    }

    &.btn-purple {
      background-color: $rhythm;
      font-size: 1.5rem;
      font-weight: 600;

      @include xs {
        font-size: 1rem;
      }

      @include sm {
        font-size: 1rem;
      }
    }
  }

  .carousel {
    & * {
      box-sizing: initial;
    }

    &.carousel-slider {
      height: 21rem;
      margin-bottom: 1rem;
    }

    .slider-wrapper.axis-horizontal {
      height: 100%;
      width: 100vw;

      > .slider {
        height: 100%;
      }
    }

    .slide {
      @extend %hbox;

      align-items: center;
      justify-content: center;
    }

    .control-dots {
      margin-top: 1.25rem;
      width: 100vw;

      .dot {
        background-color: $beau-blue;
        box-shadow: none;

        &.selected {
          background-color: $silver-lake-blue;
        }
      }
    }
  }
}
