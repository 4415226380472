// All styles for the profile endorsement card.
@import 'styles/styles';

.profile-endorsement-card {
  @extend %vbox;

  border: 1px solid $moonstone-blue;
  border-radius: 1.25rem;
  flex: 0 1 19.5rem;
  gap: 1rem;
  justify-content: space-between;
  min-height: 21.87rem;
  padding: 1.5rem;

  @include xs {
    flex: 0 1 auto;
    min-height: auto;
  }

  @include sm {
    flex: 0 1 auto;
    min-height: auto;
  }

  &__top-section {
    @extend %vbox;

    gap: 1rem;
  }

  &__bottom-section {
    @extend %vbox;

    gap: 1rem;
  }

  &__header {
    @extend %hbox;

    gap: 1.5rem;
  }

  &__header-picture-container {
    border-radius: 9999px;
    flex: 0 0 4.35rem;
    height: 4.35rem;
    width: 4.35rem;

    &.no-picture {
      background-color: $silver-sand;
      border-radius: 9999px;
    }
  }

  &__name-container {
    @extend %vbox;
    @extend %wrap;
  }

  &__location-container {
    @extend %hbox;

    align-items: center;
    gap: 0.7rem;
  }

  &__overview-container {
    @extend %vbox;

    gap: 0.5rem;
  }

  &__overview-list {
    margin: 0;
    padding-left: 1.7rem;
  }
}
