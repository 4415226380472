// All styles for the learn more dark red section.
@import 'styles/styles';

.learn-more-dark-red-section {
  @extend %hbox;

  align-items: center;
  background-color: $copper-red;
  justify-content: space-between;
  padding: 5% 6%;

  @include xs {
    @include vbox;

    gap: 1.5rem;
    padding: 4% 6%;
  }

  @include sm {
    @include vbox;

    gap: 2rem;
    padding: 4% 6%;
  }

  @include md {
    padding: 5% 4%;
  }

  &__content-container {
    @extend %vbox;

    max-width: 40.5rem;

    @include xs {
      max-width: 100%;
    }

    @include sm {
      max-width: 100%;
    }

    @include md {
      max-width: 30.5rem;
    }
  }

  &__title {
    margin-bottom: 2.93rem;

    @include xs {
      margin-bottom: 1.5rem;
    }

    @include sm {
      margin-bottom: 2rem;
    }

    @include md {
      margin-bottom: 2.5rem;
    }
  }

  &__description {
    margin-bottom: 4.18rem;

    @include xs {
      margin-bottom: 2rem;
    }

    @include sm {
      margin-bottom: 3rem;
    }

    @include md {
      margin-bottom: 3.5rem;
    }

    &.txt {
      @include xs {
        font-size: 1rem;
      }

      @include sm {
        font-size: 1rem;
      }

      @include md {
        font-size: 1rem;
      }
    }
  }

  &__coming-soon-button {
    @extend %hbox;

    align-items: center;
    background-color: rgba($copper-red, 100%);
    border: 4px dashed $carrara;
    border-radius: 0.93rem;
    color: $carrara;
    font-family: $commissioner;
    font-size: 2rem;
    font-weight: 600;
    justify-content: center;
    line-height: 2.43rem;
    padding: 1rem;
    width: 60%;

    &:disabled {
      cursor: not-allowed;
    }

    @include xs {
      font-size: 0.8rem;
      line-height: 1rem;
      padding: 0.5rem;
      width: 100%;
    }

    @include sm {
      font-size: 1rem;
      line-height: 1.3rem;
      padding: 0.5rem;
      width: 100%;
    }

    @include md {
      font-size: 1.5rem;
      line-height: 1.875rem;
      padding: 0.8rem;
    }
  }

  &__button {
    &.btn {
      width: 70%;

      @include xs {
        width: 100%;
      }

      @include sm {
        width: 100%;
      }

      @include md {
        width: 80%;
      }
    }

    &.btn-sm {
      padding: 1rem 0;

      @include xs {
        padding: 0.5rem 0;
      }
    }

    &.btn-white {
      border: none;
      color: $tundora;
      font-size: 1.5rem;
      font-weight: 700;
      text-align: center;

      @include xs {
        font-size: 1rem;
      }

      @include sm {
        font-size: 1rem;
      }
    }
  }

  &__animation-container {
    max-width: 50%;

    @include xs {
      max-width: 100%;
    }

    @include sm {
      max-width: 100%;
    }
  }
}
