// All styles for the learn more why section.
@import 'styles/styles';

.learn-more-why-section {
  @extend %hbox;

  align-items: center;
  background-color: $african-violet;
  justify-content: space-evenly;
  padding: 5% 8%;

  @include xs {
    @include vbox;

    flex-direction: column-reverse;
    padding: 4%;
  }

  @include sm {
    @include vbox;

    flex-direction: column-reverse;
    padding: 4%;
  }

  @include md {
    padding: 5%;
  }

  &__content {
    @extend %vbox;

    gap: 1rem;
    max-width: 40.5rem;

    @include xs {
      max-width: 100%;
    }

    @include sm {
      max-width: 100%;
    }

    @include md {
      max-width: 30rem;
    }
  }

  &__description {
    white-space: break-spaces;

    &.txt-lg {
      @include xs {
        font-size: 1rem;
        line-height: 1.5rem;
      }

      @include sm {
        font-size: 1rem;
        line-height: 1.5rem;
      }

      @include md {
        font-size: 1.375rem;
        line-height: 1.75rem;
      }
    }
  }

  &__animation-container {
    max-width: 50%;

    @include xs {
      max-width: 100%;
    }

    @include sm {
      max-width: 100%;
    }
  }
}
