// All styles for the search results card.
@import 'styles/styles';

.search-result-card {
  @extend %vbox;

  align-items: center;
  background-color: $white;
  border: 1px solid $moonstone-blue;
  border-radius: 1.25rem;
  cursor: pointer;
  flex: 0 0 20.81rem;
  gap: 1.5rem;
  max-width: 20.81rem;
  padding: 1.5rem;
  position: relative;
  transition: all 0.2s ease;

  @include xs {
    padding: 1rem;
  }

  @include sm {
    padding: 1rem;
  }

  &:hover {
    @include md {
      box-shadow: $result-card-hover-shadow;
    }

    @include lg {
      box-shadow: $result-card-hover-shadow;
    }
  }

  &__top-section {
    @extend %vbox;

    gap: 0.5rem;
  }

  &__profile-picture-and-name {
    @extend %vbox;

    align-items: center;
    gap: 0.5rem;
    justify-content: center;
  }

  &__profile-picture {
    @include xs {
      height: 3.75rem;
      width: 3.75rem;
    }

    @include sm {
      height: 3.75rem;
      width: 3.75rem;
    }

    @include md {
      height: 6.25rem;
      width: 6.25rem;
    }

    @include lg {
      height: 6.25rem;
      width: 6.25rem;
    }
  }

  &__no-picture {
    background-color: $silver-sand;
    border-radius: 9999px;
  }

  &__full-name {
    @extend %wrap;
  }

  &__short-bio {
    @include truncate-box-vertical(4);

    text-align: left;
  }

  &__bottom-section {
    @extend %vbox;
    @extend %wrap;

    align-items: flex-start;
    gap: 0.5rem;
    width: 100%;
  }
}
