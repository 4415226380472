// All styles for the dashboard (administrators).
@import 'styles/styles';

.ody-dashboard__administrators {
  @extend %vbox;

  &--section {
    @include xs {
      padding: 1rem 6.6%;
    }

    @include sm {
      padding: 1rem 14.39%;
    }

    @include md {
      padding: 2.5rem 13.8%;
    }

    @include lg {
      padding: 2.5rem 11.15%;
    }
  }

  &--top-banner {
    background-color: $silver-lake-blue;
  }

  &--total-users {
    @include xs {
      @include vbox;

      align-items: center;
    }

    @include sm {
      @include vbox;

      align-items: center;
    }
  }

  &--total-users-title {
    margin-bottom: 1rem;
  }

  &--total-users-cards {
    @extend %hbox;

    flex-wrap: wrap;
    gap: 1.5rem;
    width: 100%;

    @include xs {
      @include vbox;

      align-items: center;
    }

    @include sm {
      @include vbox;

      align-items: center;
    }
  }

  &--controlled-metrics-form {
    @extend %vbox;

    gap: 1rem;
  }

  &--controlled-metrics-form-title-container {
    @extend %hbox;

    align-items: center;
    gap: 2.81rem;
    justify-content: flex-start;

    @include xs {
      @include vbox;

      gap: 1rem;
    }

    @include sm {
      @include vbox;

      gap: 1rem;
    }
  }

  &--controlled-metrics-update-button.btn-sm {
    @include xs {
      font-size: 1rem;
      padding: 0.5rem 1rem;
      width: 100%;
    }

    @include sm {
      font-size: 1rem;
      padding: 0.5rem 1rem;
      width: 100%;
    }
  }

  &--pending-approvals {
    @include xs {
      @include vbox;

      align-items: center;
    }

    @include sm {
      @include vbox;

      align-items: center;
    }
  }

  &--pending-approvals-title {
    margin-bottom: 0.5rem;
  }
}
