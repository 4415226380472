// All classes that can be applied to headings.

// Some heading.
.hdg {
  font-family: $iwona;
}

// Some xxl heading.
.hdg-xxl {
  @include xs {
    font-size: 1.375rem;
    font-weight: 700;
    line-height: 1.75rem;
  }

  @include sm {
    font-size: 2.25rem;
    font-weight: 900;
    line-height: 3.125rem;
  }

  @include md {
    font-size: 3rem;
    font-weight: 900;
    line-height: 4rem;
  }

  @include lg {
    font-size: 4rem;
    font-weight: 900;
    line-height: 5.5625rem;
  }
}

// Some xl heading.
.hdg-xl {
  @include xs {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.875rem;
  }

  @include sm {
    font-size: 1.5rem;
    font-weight: 900;
    line-height: 2.25rem;
  }

  @include md {
    font-size: 2.25rem;
    font-weight: 900;
    line-height: 3.375rem;
  }

  @include lg {
    font-size: 3rem;
    font-weight: 900;
    line-height: 4.5rem;
  }
}

// Some lg heading.
.hdg-lg {
  @include xs {
    font-size: 1.25rem;
    font-weight: 900;
    line-height: 1.75rem;
  }

  @include sm {
    font-size: 1.75rem;
    font-weight: 700;
    line-height: 2.5rem;
  }

  @include md {
    font-size: 2rem;
    font-weight: 700;
    line-height: 3rem;
  }

  @include lg {
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 3.5rem;
  }
}

// Some md heading.
.hdg-md {
  font-weight: 700;

  @include xs {
    font-size: 1.375rem;
    line-height: 1.75rem;
  }

  @include sm {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  @include md {
    font-size: 1.875rem;
    line-height: 2.5rem;
  }

  @include lg {
    font-size: 2.125rem;
    line-height: 3rem;
  }
}

// Some sm heading.
.hdg-sm {
  font-size: 1.375rem;

  @include xs {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.75rem;
  }

  @include sm {
    font-weight: 500;
    line-height: 2rem;
  }

  @include md {
    font-weight: 500;
    line-height: 2rem;
  }

  @include lg {
    font-weight: 500;
    line-height: 2rem;
  }
}
