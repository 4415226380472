// All styles for the about us team section.
@import 'styles/styles';

.about-us-team-section {
  @extend %vbox;

  background-color: $moonstone-blue;
  gap: 3.75rem;
  padding: 5% 6%;

  @include xs {
    gap: 2rem;
    padding: 4%;
  }

  @include sm {
    gap: 2.5rem;
    padding: 4%;
  }

  @include md {
    gap: 3rem;
    padding: 5%;
  }

  &__members-container {
    @extend %hbox;

    flex-wrap: wrap;
    gap: 5rem;
  }

  .carousel {
    & * {
      box-sizing: initial;
    }

    .slide {
      img {
        height: 100%;
        width: 100%;
      }
    }

    &.carousel-slider {
      height: 22rem;

      @include xs {
        height: 17rem;
      }

      @include sm {
        height: 19rem;
      }
    }

    .control-dots {
      margin: 0;

      .dot {
        background-color: $beau-blue;
        box-shadow: none;

        &.selected {
          background-color: $silver-lake-blue;
        }
      }
    }
  }
}
