// All styles for the take assessment card.
@import 'styles/styles';

.take-assessment-card {
  @extend %vbox;

  background-color: $white;
  border-radius: 2.5rem;
  box-shadow: $result-card-hover-shadow;
  padding: 4rem;
  position: relative;

  @include xs {
    padding: 2.5rem;
  }

  @include sm {
    opacity: 0;
    padding: 2.5rem;
  }

  @include md {
    opacity: 0;
    padding: 2.5rem;
  }

  @include lg {
    opacity: 0;
  }

  &__visible {
    &:nth-of-type(1) {
      @include sm {
        animation: slide-from-left-in 0.5s ease-in-out forwards;
        transform: translateX(-6.25rem);
      }

      @include md {
        animation: fade-in-up 0.5s ease-in-out forwards;
        transform: translateY(6.25rem);
      }

      @include lg {
        animation: fade-in-up 0.5s ease-in-out forwards;
        transform: translateY(6.25rem);
      }
    }

    &:nth-of-type(2) {
      @include sm {
        animation: slide-from-left-in 0.5s ease-in-out forwards;
        transform: translateX(-6.25rem);
      }

      @include md {
        animation: slide-from-right-in 0.5s ease-in-out forwards;
        transform: translateX(6.25rem);
      }

      @include lg {
        animation: slide-from-right-in 0.5s ease-in-out forwards;
        transform: translateX(6.25rem);
      }
    }

    &:nth-of-type(3) {
      @include sm {
        animation: slide-from-left-in 0.5s ease-in-out forwards;
        transform: translateX(-6.25rem);
      }

      @include md {
        animation: slide-from-left-in 0.5s ease-in-out forwards;
        transform: translateX(-6.25rem);
      }

      @include lg {
        animation: slide-from-left-in 0.5s ease-in-out forwards;
        transform: translateX(-6.25rem);
      }
    }

    &:nth-of-type(4) {
      @include sm {
        animation: slide-from-left-in 0.5s ease-in-out forwards;
        transform: translateX(-6.25rem);
      }

      @include md {
        animation: slide-from-right-in 0.5s ease-in-out forwards;
        transform: translateX(6.25rem);
      }

      @include lg {
        animation: slide-from-right-in 0.5s ease-in-out forwards;
        transform: translateX(6.25rem);
      }
    }

    &:nth-of-type(5) {
      @include sm {
        animation: slide-from-left-in 0.5s ease-in-out forwards;
        transform: translateX(-6.25rem);
      }

      @include md {
        animation: slide-from-left-in 0.5s ease-in-out forwards;
        transform: translateX(-6.25rem);
      }

      @include lg {
        animation: slide-from-left-in 0.5s ease-in-out forwards;
        transform: translateX(-6.25rem);
      }
    }

    &:nth-of-type(6) {
      @include sm {
        animation: slide-from-left-in 0.5s ease-in-out forwards;
        transform: translateX(-6.25rem);
      }

      @include md {
        animation: fade-in-up 0.5s ease-in-out forwards;
        transform: translateY(6.25rem);
      }

      @include lg {
        animation: fade-in-up 0.5s ease-in-out forwards;
        transform: translateY(6.25rem);
      }
    }
  }

  &:nth-of-type(1) {
    max-width: 22rem;

    @include xs {
      max-width: 100%;
    }

    @include sm {
      max-width: 100%;
    }
  }

  &:nth-of-type(2) {
    max-width: 33.5rem;

    @include xs {
      max-width: 100%;
    }

    @include sm {
      max-width: 100%;
    }
  }

  &:nth-of-type(3) {
    max-width: 30.5rem;

    @include xs {
      max-width: 100%;
    }

    @include sm {
      max-width: 100%;
    }
  }

  &:nth-of-type(4) {
    max-width: 21.125rem;

    @include xs {
      max-width: 100%;
    }

    @include sm {
      max-width: 100%;
    }
  }

  &:nth-of-type(5) {
    max-width: 28.5rem;

    @include xs {
      max-width: 100%;
    }

    @include sm {
      max-width: 100%;
    }
  }

  &__content {
    hyphens: auto;
    word-break: break-word;
  }

  &__last-card {
    background-color: $maximum-yellow-red;
    max-width: 24.2rem;

    @include xs {
      max-width: 100%;
    }

    @include sm {
      max-width: 100%;
    }
  }

  &__icon-container {
    @extend %vbox;

    align-items: center;
    background-color: $maximum-yellow-red;
    border-radius: 9999px;
    justify-content: center;
    left: 2rem;
    padding: 1.25rem;
    position: absolute;
    top: -2.5rem;
  }

  &__description {
    &--last-card {
      text-align: center;

      &.txt-sm {
        font-size: 2rem;
        font-weight: bold;
        line-height: 2.5rem;

        @include xs {
          font-size: 1rem;
          font-weight: bold;
          line-height: 1.5rem;
        }

        @include sm {
          font-size: 1rem;
          font-weight: bold;
          line-height: 1.5rem;
        }
      }
    }
  }
}
