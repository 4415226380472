// All styles for the videos and guides.
@import 'styles/styles';

.videos-and-guides {
  @extend %vbox;

  &__title {
    margin-bottom: 1rem;
  }

  &__content {
    @extend %hbox;

    flex-wrap: wrap;
    gap: 1rem;

    @include xs {
      justify-content: center;
    }

    @include sm {
      justify-content: center;
    }
  }
}
