// All styles for the background image editor
@import 'styles/styles';

[role='dialog'].popup-content.profile-background-image-modal-content {
  @extend %vbox;

  height: auto;
  max-height: 95vh;
  max-width: 47.5rem;
  overflow: hidden;
  width: calc(100% - 2rem);

  > .profile-background-image-editor {
    @extend %discreet-scrollable;

    background-color: $white;
    border-radius: 1.25rem;
    flex: 1;
    height: 100%;
    padding: 1.5rem;

    > .hdg-md {
      padding-bottom: 1.5rem;
    }

    > .background-images {
      @extend %hbox;

      flex-wrap: wrap;
      gap: 1rem;
      justify-content: center;

      > button {
        border-top-left-radius: 1.25rem;
        border-top-right-radius: 1.25rem;
        width: 45%;

        img {
          height: 100%;
          opacity: 0.6;
          width: 100%;
        }

        &.selected > img {
          opacity: 1;
        }
      }
    }

    > form {
      > .fields {
        @extend %vbox;

        gap: 1rem;
      }

      > .buttons {
        @extend %hbox;

        justify-content: space-between;
        padding-top: 2rem;

        > .left-buttons {
          @extend %hbox;

          flex: 1;
          gap: 1rem;
          justify-content: flex-start;
        }

        > .right-buttons {
          @extend %hbox;

          flex: 1;
          gap: 1rem;
          justify-content: flex-end;
        }

        .btn-custom {
          @include xs {
            font-size: 1rem;
            padding: 0.5rem 1rem;
          }

          @include sm {
            font-size: 1rem;
            padding: 0.5rem 1rem;
          }

          @include md {
            font-size: 1.375rem;
            padding: 1rem;
          }

          @include lg {
            font-size: 1.375rem;
            padding: 1rem;
          }
        }
      }
    }
  }
}
