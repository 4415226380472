// All styles for the about us how does it work step.
@import 'styles/styles';

.about-us-how-does-it-work-step {
  @extend %vbox;

  background-color: $white;
  border-radius: 2.5rem;
  box-shadow: $result-card-hover-shadow;
  gap: 1.5rem;
  padding: 2.5rem 3rem;

  @include xs {
    padding: 1.5rem 2rem;
    width: 80%;
  }

  @include sm {
    padding: 1.5rem 2rem;
    width: 90%;
  }

  @include md {
    max-width: 16.5rem;
    opacity: 0;
    padding: 2rem 2.5rem;
  }

  @include lg {
    max-width: 16.5rem;
    opacity: 0;
  }

  &:nth-of-type(2) {
    @include md {
      margin-top: -2.37rem;
    }

    @include lg {
      margin-top: -4.37rem;
    }
  }

  &:nth-of-type(3) {
    @include md {
      margin-top: -4.25rem;
    }

    @include lg {
      margin-top: -6.25rem;
    }
  }

  &__visible {
    @include sm {
      animation: slide-from-left-in 0.5s ease-in-out forwards;
    }

    @include md {
      animation: fade-in-up 0.5s ease-in-out forwards;
    }

    @include lg {
      animation: fade-in-up 0.5s ease-in-out forwards;
    }

    &:nth-of-type(2) {
      @include md {
        animation: fade-in-up 0.5s ease-in-out forwards;
        animation-delay: 0.5s;
      }

      @include lg {
        animation: fade-in-up 0.5s ease-in-out forwards;
        animation-delay: 0.5s;
      }
    }

    &:nth-of-type(3) {
      @include md {
        animation: fade-in-up 0.5s ease-in-out forwards;
        animation-delay: 1s;
      }

      @include lg {
        animation: fade-in-up 0.5s ease-in-out forwards;
        animation-delay: 1s;
      }
    }
  }

  &__title {
    &.txt-lg {
      @include xs {
        font-size: 1rem;
        line-height: 1.5rem;
      }

      @include sm {
        font-size: 1rem;
        line-height: 1.5rem;
      }

      @include md {
        font-size: 1.375rem;
        line-height: 1.75rem;
      }
    }
  }

  &__description {
    &.txt-md {
      @include xs {
        font-size: 1rem;
        line-height: 1.5rem;
      }

      @include sm {
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }
  }
}
