// All styles for profile image editor
@import 'styles/styles';

[role='dialog'].popup-content.profile-image-editor-modal-content {
  @extend %vbox;

  height: auto;
  max-height: 95vh;
  max-width: 47.5rem;
  overflow: hidden;
  width: calc(100% - 2rem);

  > .profile-image-editor {
    background-color: $white;
    border: 1px solid $moonstone-blue;
    border-radius: 1.25rem;
    flex: 1;
    height: 100%;
    overflow-y: auto;
    padding: 1.5rem;

    &::-webkit-scrollbar {
      height: 0;
      width: 0;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
      -webkit-border-radius: auto;
      border-radius: auto;
      -webkit-box-shadow: auto;
      box-shadow: auto;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
      -webkit-border-radius: auto;
      border-radius: auto;
      -webkit-box-shadow: auto;
      box-shadow: auto;
    }

    > .hdg-md {
      padding-bottom: 1.5rem;
    }

    > .image {
      @extend %vbox;

      margin-bottom: 1rem;

      > .preview {
        @extend %hbox;

        justify-content: center;

        > .no-picture {
          @extend %vbox;

          align-items: center;
          border: 1px dashed $gainsboro;
          border-radius: 1rem;
          height: 20rem;
          justify-content: center;
          width: 100%;

          > img {
            margin-bottom: 1rem;
            width: 3rem;
          }
        }

        > .wrapper {
          @extend %hbox;

          align-items: center;
          align-self: center;
          border-radius: 9999px;
          justify-content: center;
          overflow: hidden;

          @include xs {
            height: 11.5rem;
            width: 11.5rem;
          }

          @include sm {
            height: 11.5rem;
            width: 11.5rem;
          }

          @include md {
            height: 26.25rem;
            width: 26.25rem;
          }

          @include lg {
            height: 26.25rem;
            width: 26.25rem;
          }
        }
      }

      > .buttons {
        @extend %hbox;

        justify-content: space-between;
        margin-top: 1rem;
      }

      > .error {
        text-align: right;
      }
    }

    > form {
      > .fields {
        @extend %vbox;

        gap: 1rem;

        > .field {
          label {
            font-size: 1rem;
            font-weight: normal;
            line-height: 1.25rem;
          }

          input {
            &[type='password'],
            &[type='text'] {
              font-size: 1rem;
              font-weight: normal;
              line-height: 1.25rem;
              padding: 0.5rem;
            }
          }

          textarea {
            font-size: 1rem;
            font-weight: normal;
            line-height: 1.25rem;
            padding: 0.5rem;
          }
        }
      }

      > .buttons {
        @extend %hbox;

        justify-content: space-between;
        padding-top: 2rem;

        > .left-buttons {
          @extend %hbox;

          flex: 1;
          gap: 1rem;
          justify-content: flex-start;
        }

        > .right-buttons {
          @extend %hbox;

          flex: 1;
          gap: 1rem;
          justify-content: flex-end;
        }

        .btn-custom {
          @include xs {
            font-size: 1rem;
            padding: 0.5rem 1rem;
          }

          @include sm {
            font-size: 1rem;
            padding: 0.5rem 1rem;
          }

          @include md {
            font-size: 1.375rem;
            padding: 1rem;
          }

          @include lg {
            font-size: 1.375rem;
            padding: 1rem;
          }
        }
      }
    }

    .error {
      color: $copper-red;
      font-family: $commissioner;
      font-size: 1rem;
      font-weight: normal;
      line-height: 1.25rem;
      margin-top: 1.5rem;

      @include xs {
        font-size: 0.8rem;
        line-height: 1rem;
      }

      @include sm {
        font-size: 0.8rem;
        line-height: 1rem;
      }
    }
  }
}
