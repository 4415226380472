// All styles for the arrow up icon.
@import 'styles/styles';

.arrow-up-icon {
  @include xs {
    height: 0.5rem;
  }

  @include sm {
    height: 0.5rem;
  }

  @include md {
    height: 0.75rem;
  }

  @include lg {
    height: 0.93rem;
  }
}
