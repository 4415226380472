// All styles for the learn request endorsement section.
@import 'styles/styles';

.learn-more-request-endorsement-section {
  @extend %hbox;

  align-items: center;
  background-color: $isabelline;
  justify-content: space-between;
  padding: 5% 6%;

  @include xs {
    @include vbox;

    flex-direction: column-reverse;
    padding: 4% 6%;
  }

  @include sm {
    @include vbox;

    flex-direction: column-reverse;
    padding: 4% 6%;
  }

  @include md {
    padding: 5% 4%;
  }

  &__content {
    @extend %vbox;

    max-width: 43.4rem;
  }

  &__title {
    margin-bottom: 1rem;
  }

  &__description {
    margin-bottom: 2.64rem;
    max-width: 90%;

    @include xs {
      margin-bottom: 1.5rem;
    }

    @include sm {
      margin-bottom: 2rem;
    }

    &.txt {
      @include xs {
        font-size: 1rem;
      }

      @include sm {
        font-size: 1rem;
      }

      @include md {
        font-size: 1rem;
      }
    }
  }

  &__button {
    &.btn {
      width: 70%;

      @include xs {
        width: 100%;
      }

      @include sm {
        width: 100%;
      }

      @include md {
        width: 50%;
      }
    }

    &.btn-sm {
      padding: 1rem 0;

      @include xs {
        padding: 0.5rem 0;
      }
    }

    &.btn-yellow {
      color: $tundora;
      font-size: 1.5rem;
      font-weight: 700;
      text-align: center;

      @include xs {
        font-size: 1rem;
      }

      @include sm {
        font-size: 1rem;
      }
    }
  }

  &__animation-container {
    max-width: 51rem;
  }
}
