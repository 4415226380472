// All styles for average bubbles.
@import 'styles/styles';

.average-bubbles {
  @extend %hbox;

  gap: 1.5rem;

  &__bubble {
    border: 1px solid $silver-lake-blue;
    border-radius: 9999px;
    height: 2.5rem;
    width: 2.5rem;

    &--small {
      height: 1.8rem;
      width: 1.8rem;

      @include sm {
        height: 1.5rem;
        width: 1.5rem;
      }

      @include xs {
        height: 1.3rem;
        width: 1.3rem;
      }
    }

    @include sm {
      height: 1.8rem;
      width: 1.8rem;
    }

    @include xs {
      height: 1.3rem;
      width: 1.3rem;
    }

    &--whole {
      background-color: $silver-lake-blue;
    }
  }
}
