// All styles for the about us how does it work section.
@import 'styles/styles';

.about-us-how-does-it-work-section {
  @extend %vbox;

  background-color: $isabelline;
  padding: 5% 6%;

  @include xs {
    gap: 1.5rem;
    padding: 4%;
  }

  @include sm {
    gap: 1.5rem;
    padding: 4%;
  }

  @include md {
    padding: 5%;
  }

  &__title {
    @include xs {
      text-align: center;
    }

    @include sm {
      text-align: center;
    }
  }

  &__icon-container {
    align-self: center;
  }

  &__steps-container {
    @extend %hbox;

    gap: 1.5rem;
    justify-content: space-evenly;

    @include xs {
      @include vbox;

      align-items: center;
      justify-content: center;
    }

    @include sm {
      @include vbox;

      align-items: center;
      justify-content: center;
    }

    @include md {
      justify-content: center;
    }
  }
}
