// All styles for the selected filters in the search page (job seekers).
@import 'styles/styles';

.search-selected-filters {
  .ody-tags-selected-options {
    > .ody-tags-selected-option {
      border: none;
      border-radius: 0.5rem;
      color: $white;
      font-size: 1.375rem;
      padding: 1rem;

      > .ody-tags-remove {
        color: $white;
        font-size: 1.375rem;
      }

      @include xs {
        font-size: 1rem;
        padding: 0.5rem;

        > .ody-tags-remove {
          font-size: 1rem;
        }
      }

      @include sm {
        font-size: 1rem;
        padding: 0.5rem;

        > .ody-tags-remove {
          font-size: 1rem;
        }
      }
    }
  }

  &__job-seekers {
    .ody-tags-selected-options {
      > .ody-tags-selected-option {
        background-color: $middle-red;
      }
    }
  }

  &__companies {
    .ody-tags-selected-options {
      > .ody-tags-selected-option {
        background-color: $maximum-yellow-red;
      }
    }
  }

  &__endorsers {
    .ody-tags-selected-options {
      > .ody-tags-selected-option {
        background-color: $african-violet;
      }
    }
  }
}
