// All styles for the profile about
@import 'styles/styles';

.profile-about {
  &__main-section {
    @extend %vbox;
  }

  &__video-section {
    @extend %vbox;

    margin-bottom: 1rem;
  }

  &__video {
    background-color: $raisin-black;
    height: auto;
    max-height: 30rem;
    width: 100%;
  }

  &__description {
    white-space: break-spaces;
  }
}
