// All styles for the results in the search page.
@import 'styles/styles';

.results {
  @extend %vbox;

  &__endorsers {
    @extend %hbox;

    flex-wrap: wrap;
    gap: 1.5rem;
  }

  @include xs {
    gap: 1rem;
  }

  @include sm {
    gap: 1rem;
  }

  @include md {
    gap: 1.5rem;
  }

  @include lg {
    gap: 1.5rem;
  }
}
