// All styles for the individual result.
@import 'styles/styles';

.search-result {
  @extend %hbox;

  border: 1px solid $moonstone-blue;
  border-radius: 1.25rem;
  gap: 1rem;
  padding: 2rem;
  position: relative;
  transition: all 0.2s ease;

  @include xs {
    @include vbox;

    padding: 1rem;
  }

  @include sm {
    @include vbox;

    padding: 1rem;
  }

  &:hover {
    @include md {
      box-shadow: $result-card-hover-shadow;
    }

    @include lg {
      box-shadow: $result-card-hover-shadow;
    }
  }

  > .save-job-seeker-button {
    @extend %vbox;

    align-items: center;
    background-color: transparent;
    border: none;
    border-radius: 9999px;
    justify-content: center;
    padding: 1rem;
    position: absolute;
    right: 0;
    top: 0;

    > img {
      height: 1.36rem;
      width: 1.42rem;
    }
  }

  > .left {
    @extend %vbox;

    gap: 1rem;

    > .profile-picture-and-name {
      @extend %vbox;

      gap: 1rem;

      @include xs {
        @include hbox;
      }

      @include sm {
        @include hbox;
      }

      > .profile-picture {
        @include xs {
          height: 3.75rem;
          width: 3.75rem;
        }

        @include sm {
          height: 3.75rem;
          width: 3.75rem;
        }

        @include md {
          height: 13.375rem;
          width: 13.375rem;
        }

        @include lg {
          height: 13.375rem;
          width: 13.375rem;
        }

        &.no-picture {
          background-color: $silver-sand;
          border-radius: 9999px;
        }
      }
    }

    > .location {
      @extend %hbox;

      margin-top: 1rem;

      > img {
        align-self: center;

        @include xs {
          margin-right: 0.5rem;
        }

        @include sm {
          margin-right: 0.5rem;
        }

        @include md {
          margin-right: 0.75rem;
        }

        @include lg {
          margin-right: 0.75rem;
        }
      }
    }
  }

  > .right {
    @extend %vbox;

    gap: 1rem;

    > .short-bio > .value {
      @include truncate-box-vertical(4);
    }

    .title {
      margin-bottom: 0.5rem;
    }

    > .professions {
      > .values {
        @extend %hbox;

        flex-wrap: wrap;
        gap: 1rem;

        > .value {
          border-radius: 0.5rem;
          padding: 0.5rem 1rem;

          &.red {
            border: 1px solid $middle-red;
          }

          &.purple {
            border: 1px solid $african-violet;
          }

          &.yellow {
            border: 1px solid $maximum-yellow-red;
          }
        }
      }
    }
  }
}
