// All styles for selects.
@import 'styles/styles';

.ody-select {
  cursor: pointer;
  font-family: $commissioner;
  margin-top: 0.5rem;

  > .ody-select__control {
    background-color: $white;
    border: 1px solid $silver-sand;
    border-radius: 0.5rem;

    &--is-focused {
      border-color: $moonstone-blue;
      box-shadow: none;
    }

    > img {
      padding-left: 1rem;
    }

    > .ody-select__value-container {
      padding: 0.5rem;

      > .ody-select__input-container {
        margin: 0;
        padding: 0;
      }
    }

    > .ody-select__indicators {
      > .ody-select__indicator-separator {
        display: none;
      }
    }
  }

  &.error {
    > .ody-select__control {
      border-color: $copper-red;
    }
  }
}

body > .ody-select__menu-portal {
  z-index: 1024;

  > .ody-select__menu {
    border-radius: 0;
    box-shadow: none;
    font-family: $commissioner;
    margin: 0;

    > .ody-select__menu-list {
      border: 1px solid $silver-sand;
      padding: 0;

      > .ody-select__option {
        color: $raisin-black;
        font-size: 1rem;
        line-height: 1.25rem;
        padding: 0.5rem;

        &--is-focused {
          background-color: $moonstone-blue;
          color: $white;
        }

        &--is-selected {
          background-color: transparent;
          color: $moonstone-blue;
        }
      }
    }
  }
}
