// All styles for the home page welcome section.
@import 'styles/styles';

.welcome-section {
  @extend %hbox;

  align-items: center;
  background-color: $isabelline;
  justify-content: flex-end;
  min-height: 54.65rem;
  padding-right: 4.58%;
  position: relative;

  @include xs {
    @include vbox;

    min-height: 42.65rem;
    padding-right: 0;
  }

  @include sm {
    @include vbox;

    min-height: 50.65rem;
    padding-right: 0;
  }

  @include md {
    min-height: 44.65rem;
    padding-right: 2.5rem;
  }

  &__animation-container {
    left: 3rem;
    position: absolute;
    top: 45%;
    transform: translate(0, -50%);

    @include xs {
      left: 50%;
      top: 0;
      transform: translate(-50%, 0);
    }

    @include sm {
      left: 50%;
      top: 0;
      transform: translate(-50%, 0);
    }
  }

  &__animated-icon {
    @include xs {
      height: 21.9rem;
    }

    @include sm {
      height: 26.4rem;
    }

    @include md {
      height: 45rem;
    }

    @include lg {
      height: 52.35rem;
    }
  }

  &__welcome-card {
    @extend %vbox;

    background-color: $moonstone-blue;
    border-radius: 7.75rem;
    box-shadow: $result-card-hover-shadow;
    gap: 2.5rem;
    padding: 5rem;

    @include xs {
      background-color: transparent;
      border-radius: 0;
      box-shadow: none;
      gap: 1rem;
      padding: 1rem;
    }

    @include sm {
      background-color: transparent;
      border-radius: 0;
      box-shadow: none;
      gap: 1.5rem;
      padding: 2rem;
    }

    @include md {
      animation: fade-in-up 0.5s ease-in-out forwards;
      gap: 2rem;
      max-width: 30.38rem;
      opacity: 0;
      padding: 3.5rem;
      transform: translateY(6.25rem);
    }

    @include lg {
      animation: fade-in-up 0.5s ease-in-out forwards;
      max-width: 37.38rem;
      opacity: 0;
      transform: translateY(6.25rem);
    }
  }

  &__card-text {
    text-align: center;

    &.dark {
      color: $raisin-black;
    }
  }

  &__buttons-container {
    @extend %hbox;

    align-items: center;
    justify-content: space-evenly;

    @include xs {
      @include vbox;

      gap: 1rem;
    }

    @include sm {
      @include vbox;

      gap: 1rem;
    }
  }

  &__button-link {
    padding: 1.5rem;

    @include xs {
      padding: 0.5rem;
      text-align: center;
      width: 80%;
    }

    @include sm {
      padding: 0.5rem;
      text-align: center;
      width: 80%;
    }

    @include md {
      padding: 1rem;
    }

    &--greek {
      &:first-of-type {
        margin-right: 1rem;
      }

      &:last-of-type {
        margin-left: 1rem;
      }
    }
  }

  &__button-text {
    &.txt {
      @include xs {
        font-size: 1rem;
      }

      @include sm {
        font-size: 1rem;
      }

      @include md {
        font-size: 1rem;
      }
    }
  }
}
