// All styles for the profile professions.
@import 'styles/styles';

.profile-professions {
  &__fields {
    @extend %hbox;

    flex-wrap: wrap;
    gap: 1rem;
  }

  &__field {
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;

    &--red {
      border: 1px solid $middle-red;
    }

    &--purple {
      border: 1px solid $african-violet;
    }

    &--yellow {
      border: 1px solid $maximum-yellow-red;
    }
  }
}
