// All styles for the work availability in the dashboard (job seekers).
@import 'styles/styles';

.work-availability {
  @extend %vbox;

  align-items: stretch;
  border: none;
  text-align: left;
  transition: all 0.2s ease;

  @include xs {
    width: 100%;
  }

  @include sm {
    width: 100%;
  }

  @include md {
    min-height: 6.1875rem;
    width: 23.875rem;
  }

  @include lg {
    min-height: 6.1875rem;
    width: 23.875rem;
  }

  > .current-and-select {
    @extend %hbox;

    border-radius: 0.625rem;
    color: $white;
    padding: 1.875rem 2.25rem;
    transition: border-radius 0.2s ease;
    will-change: border-radius;

    &.expanded {
      border-radius: 0.625rem 0.625rem 0 0;
    }

    &.none {
      background-color: $silver-sand;
    }

    &.available-in-less-than-a-month {
      background-color: $moonstone-blue;
    }

    &.available-after-a-month {
      background-color: $maximum-yellow-red;
    }

    &.available-now {
      background-color: $wageningen-green;
    }

    &.not-available {
      background-color: $middle-red;
    }

    > .current {
      flex: 1;
      font-family: $commissioner;
      font-weight: 500;
      padding-right: 1rem;

      @include xs {
        font-size: 1.375rem;
        line-height: 1.875rem;
      }

      @include sm {
        font-size: 1.375rem;
        line-height: 1.875rem;
      }

      @include md {
        font-size: 2rem;
        line-height: 2.5rem;
      }

      @include lg {
        font-size: 2rem;
        line-height: 2.5rem;
      }
    }

    > .select {
      align-self: center;
      transition: transform 0.2s ease;

      &.expanded {
        transform: rotate(180deg);
      }
    }
  }

  > .options {
    @extend %vbox;

    overflow: hidden;
    transition: all 0.2s ease;
    will-change: height;

    > .option {
      @extend %hbox;

      padding: 0.75rem 1.5rem;

      &:nth-child(even) {
        background-color: $anti-flash-white;
      }

      &:nth-child(odd) {
        background-color: $white;
      }

      &:last-child {
        border-bottom-left-radius: 0.625rem;
        border-bottom-right-radius: 0.625rem;
      }

      > label {
        cursor: pointer;
        flex: 1;
        font-family: $commissioner;

        @include xs {
          font-size: 1rem;
        }

        @include sm {
          font-size: 1rem;
        }

        @include md {
          font-size: 1.375rem;
        }

        @include lg {
          font-size: 1.375rem;
        }
      }

      > input[type='radio'] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-color: transparent;
        background-image: url('/images/incomplete.png');
        background-position: right top;
        background-repeat: no-repeat;
        border: none;
        cursor: pointer;
        height: 1.25rem;
        width: 1.25rem;

        &:checked {
          background-image: url('/images/checked.png');
          background-position: right top;
          background-repeat: no-repeat;
        }
      }
    }
  }

  &.collapsed > .options {
    height: 0;
  }

  &.expanded > .options {
    height: 15.75rem;
  }
}
