// All styles for the header (guests).
@import 'styles/styles';
@import '../header';

.ody-header.guests {
  > .buttons-and-language {
    @extend %hbox;

    align-items: center;
    gap: 1rem;

    > .buttons {
      @extend %hbox;

      gap: 1rem;
    }

    > .language {
      @extend %hbox;

      > .ody-popup-menu > .selected-language {
        @extend %hbox;

        align-items: center;

        > .flag {
          height: 1.25rem;
          margin-right: 0.5rem;
        }

        > .name {
          color: $raisin-black;
          font-family: $commissioner;
          font-size: 1rem;
          font-weight: normal;
          line-height: 1.25rem;
        }
      }

      &:focus {
        border: none;
      }

      :last-child {
        padding-left: 0.75rem;
      }
    }
  }

  > .buttons-links-and-language {
    @extend %hbox;

    align-items: center;
    gap: 1rem;

    > .buttons {
      @extend %hbox;

      gap: 0.5rem;
    }

    > .guests-header-mobile-popupmenu {
      > .btn-trans {
        border-radius: 9999px;
        padding: 0.5rem 0.6rem;
      }
    }
  }

  .btn-custom {
    font-size: 1.375rem;
    padding: 0.5rem 1rem;

    @include xs {
      font-size: 1rem;
    }

    @include sm {
      font-size: 1rem;
    }
  }
}

[role='tooltip'].popup-content.guests-header-languages-popupmenu-content {
  > .available-language {
    @extend %hbox;

    background-color: transparent;
    border: none;
    cursor: pointer;
    flex: 1;
    gap: 1.5rem;
    justify-content: space-between;
    padding: 0;

    &:not(:last-child) {
      padding-bottom: 1rem;
    }

    &:hover {
      color: $silver-lake-blue;
    }

    @include xs {
      justify-content: flex-start;
    }

    @include sm {
      justify-content: flex-start;
    }

    > img {
      align-self: flex-end;
    }
  }
}

[role='tooltip'].popup-content.guests-header-mobile-popupmenu-content {
  > .links {
    margin-bottom: 0.5rem;

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    li:not(:last-child) {
      margin-bottom: 1.5rem;
    }

    > ul {
      > li {
        > ul {
          padding-block-start: 1rem;
          padding-inline-start: 1.5rem;

          > li:not(:last-child) {
            margin-bottom: 1rem;
          }
        }
      }
    }
  }

  > .languages {
    @extend %vbox;

    justify-content: flex-end;
    margin-top: 0.5rem;

    > .available-language {
      @extend %hbox;

      background-color: transparent;
      border: none;
      cursor: pointer;
      flex: 1;
      gap: 1.5rem;
      padding: 0;

      &:not(:last-child) {
        padding-bottom: 1rem;
      }

      &:hover {
        color: $silver-lake-blue;
      }

      @include xs {
        justify-content: flex-start;
      }

      @include sm {
        justify-content: flex-start;
      }

      > img {
        align-self: flex-end;
      }
    }
  }
}
