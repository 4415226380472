// All styles for popups.
@import 'styles/styles';

.ody-popup-menu {
  @extend %no-select;
}

[role='tooltip'].popup-content {
  @extend %vbox;

  background-color: $white;
  border: 1px solid $silver-sand;
  border-radius: 0.5rem;
  flex: 1;
  font-family: $commissioner;
  font-size: 1rem;
  font-weight: 400;
  padding: 1rem 2rem;

  > * {
    @extend %no-select;
  }

  .divider {
    margin: 1rem -2rem;
  }
}
