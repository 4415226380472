// All styles for the profile details section.
@import 'styles/styles';

.profile-details-section {
  padding-top: 7.5rem;

  @include xs {
    padding-top: 5rem;
  }

  @include sm {
    padding-top: 5rem;
  }

  &__displayed-name {
    @extend %wrap;

    padding-bottom: 1rem;
    text-align: center;
  }

  &__fields {
    @extend %vbox;
  }

  &__field {
    @extend %hbox;

    &:not(:last-child) {
      padding-bottom: 1rem;
    }
  }

  &__field-icon {
    @extend %hbox;

    align-items: center;
    width: 2rem;
  }

  &__field-value {
    flex: 1;
  }
}
