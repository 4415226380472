// All styles for the sign-up page.
@import 'styles/styles';

$logo-right-attribute: 0.5625rem;

.ody-sign-up {
  @include hbox;

  height: 100%;
  position: relative;

  &__created {
    white-space: break-spaces;

    @include xs {
      padding: 0 1rem;
    }

    @include sm {
      padding: 0 1rem;
    }

    @include md {
      padding: 0 3rem;
    }

    @include lg {
      padding: 0 5rem;
    }
  }

  &__form-wrapper {
    @extend %vbox;

    align-items: center;
    flex: 1;
    gap: 1rem;
    max-width: 100%;
    z-index: 1;

    @include xs {
      padding: 1rem;
    }

    @include sm {
      padding: 1rem;
    }

    @include md {
      padding-bottom: 3rem;
      padding-right: calc(45% - #{$logo-right-attribute});
      padding-top: 3rem;
    }

    @include lg {
      padding-bottom: 5rem;
      padding-right: calc(50% - #{$logo-right-attribute});
      padding-top: 5rem;
    }
  }

  &__title {
    margin-bottom: 1rem;
  }

  &__form {
    @extend %vbox;

    gap: 1rem;

    @include xs {
      width: 100%;
    }

    @include sm {
      width: 100%;
    }

    @include md {
      width: 95%;
    }

    @include lg {
      width: 80%;
    }
  }

  &__form-control {
    @extend %vbox;

    gap: 0.3rem;
    width: 100%;

    &--link {
      @extend %hbox;

      align-items: center;
    }

    &--link-label {
      font-size: 1rem;
      line-height: 1.25rem;
    }
  }

  &__sign-up-button {
    @extend %hbox;

    align-items: center;
    gap: 0.5rem;
    justify-content: center;

    @include xs {
      font-size: 1rem;
      padding: 1rem;
      width: 100%;
    }

    @include sm {
      font-size: 1rem;
      padding: 1rem 3.5rem;
    }

    @include md {
      font-size: 1.5rem;
      padding: 1rem 3.5rem;
    }

    @include lg {
      font-size: 1.5rem;
      padding: 1rem 3.5rem;
    }
  }

  &__social-buttons-container {
    @include xs {
      width: 100%;
    }

    @include sm {
      width: 100%;
    }

    @include md {
      width: 95%;
    }

    @include lg {
      width: 80%;
    }
  }

  &__reset-authentication-provider-button {
    @extend %hbox;

    align-items: center;
    gap: 0.5rem;
    justify-content: center;
    width: 80%;

    @include xs {
      font-size: 1rem;
      padding: 1rem;
      width: 100%;
    }

    @include sm {
      font-size: 1rem;
      padding: 1rem 3.5rem;
    }

    @include md {
      font-size: 1.5rem;
      padding: 1rem 3.5rem;
    }

    @include lg {
      font-size: 1.5rem;
      padding: 1rem 3.5rem;
    }
  }

  &__separator {
    color: $raisin-black;
    font-family: $commissioner;
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.25rem;
    text-align: center;
  }

  &__logo {
    @extend %hbox;

    align-items: center;
    flex: 1;
    height: 100vh;
    justify-content: center;
    position: fixed;
    right: $logo-right-attribute;

    @include xs {
      display: none;
    }

    @include sm {
      display: none;
    }

    @include md {
      background-color: $silver-lake-blue;
      width: calc(45% - #{$logo-right-attribute});
    }

    @include lg {
      background-color: $silver-lake-blue;
      width: calc(50% - #{$logo-right-attribute});
    }
  }
}
