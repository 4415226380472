// All styles for the pending section (administrators).
@import 'styles/styles';

.pending-section {
  @extend %vbox;

  &:not(:last-of-type) {
    margin-bottom: 2.31rem;
  }

  &__title-section {
    @extend %hbox;

    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.125rem;

    @include xs {
      @include vbox;

      gap: 1.5rem;
      justify-content: center;
    }

    > .pending-section__view-all-button {
      @include xs {
        font-size: 1rem;
        padding: 0.5rem 1rem;
        width: 100%;
      }
    }
  }

  &__no-pending-results {
    @extend %hbox;

    align-items: center;
    justify-content: center;
    padding: 1rem;
  }

  &__cards {
    @extend %hbox;

    flex-wrap: wrap;
    gap: 1.5rem;

    @include xs {
      @include vbox;

      justify-content: center;
    }

    @include sm {
      @include vbox;

      justify-content: center;
    }
  }
}
