// All styles for see-example-profile buttons.
@import 'styles/styles';

.see-example-profile-button {
  > .ody-modal {
    > .ody-profile__banner-button {
      color: $moonstone-blue;
    }
  }
}

[role='dialog'].popup-content.see-example-profile-modal-content {
  background-color: transparent;
  height: 95vh;

  @include xs {
    max-width: 20rem;
  }

  @include sm {
    max-width: 35rem;
  }

  @include md {
    max-width: 50rem;
  }

  @include lg {
    max-width: 80rem;
  }

  > .see-example-profile {
    border-radius: 1.25rem;
    height: 100%;
    justify-content: center;
    margin: 0 auto;
    overflow: hidden;
    width: fit-content;

    > .image {
      @extend %discreet-scrollable;

      height: 100%;

      > img {
        display: block;
        margin: 0 auto;
        width: 80rem;

        @include xs {
          width: 20rem;
        }

        @include sm {
          width: 35rem;
        }

        @include md {
          width: 50rem;
        }

        @include lg {
          width: 80rem;
        }
      }
    }
  }
}
