// All styles for the about us target group section.
@import 'styles/styles';

.about-us-target-group-section {
  @extend %vbox;

  background-color: $moonstone-blue;
  gap: 2.93rem;
  padding: 5% 6%;

  @include xs {
    gap: 1.5rem;
    padding: 4%;
  }

  @include sm {
    gap: 2rem;
    padding: 4%;
  }

  @include md {
    gap: 2.5rem;
    padding: 5%;
  }

  &__title {
    @include xs {
      text-align: center;
    }

    @include sm {
      text-align: center;
    }
  }

  &__cards-container {
    @extend %hbox;

    align-items: center;
    flex-wrap: wrap;
    gap: 1.25rem;
    justify-content: space-between;

    @include xs {
      @include vbox;

      justify-content: center;
    }

    @include sm {
      @include vbox;

      justify-content: center;
    }

    @include md {
      gap: 3rem;
      justify-content: flex-start;
    }
  }

  &__card {
    @extend %hbox;

    align-items: center;
    background-color: $silver-lake-blue;
    border-radius: 2.5rem;
    box-shadow: $result-card-hover-shadow;
    color: $white;
    font-family: $commissioner;
    font-size: 1.75rem;
    font-weight: 500;
    justify-content: center;
    line-height: 2rem;
    text-align: center;
    white-space: break-spaces;

    @include xs {
      font-size: 1rem;
      line-height: 1.375rem;
      padding: 2rem 1rem;
      width: 90%;
    }

    @include sm {
      font-size: 1.375rem;
      line-height: 1.5rem;
      padding: 2.5rem 1.5rem;
      width: 90%;
    }

    @include md {
      font-size: 1.5rem;
      height: 15.45rem;
      line-height: 1.75rem;
      opacity: 0;
      padding: 3rem 2rem;
      width: 17.438rem;
    }

    @include lg {
      height: 18.45rem;
      opacity: 0;
      padding: 3.37rem 2.5rem;
      width: 21.438rem;
    }

    &--visible {
      @include md {
        animation: fade-in-up 0.5s ease-in-out forwards;
      }

      @include lg {
        animation: fade-in-up 0.5s ease-in-out forwards;
      }
    }
  }
}
