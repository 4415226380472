// All styles for the pending profile card (administrators).
@import 'styles/styles';

.pending-profile-card {
  @extend %vbox;

  border: 1px solid $moonstone-blue;
  border-radius: 1.25rem;
  flex: 0 1 14.125rem;
  gap: 0.875rem;
  justify-content: space-between;
  max-width: 14.125rem;
  padding: 1.25rem 1rem;

  @include xs {
    gap: 0;
    justify-content: space-between;
    max-width: none;
  }

  @include sm {
    gap: 0;
    justify-content: space-between;
    max-width: none;
  }

  &__container {
    @extend %vbox;

    gap: 0.875rem;
  }

  &__title {
    &.hdg.hdg-sm {
      @extend %wrap;

      font-weight: 700;
    }
  }

  &__info-container {
    @extend %hbox;

    align-items: center;
    justify-content: flex-start;

    &.link {
      color: $silver-lake-blue;
    }
  }

  &__info {
    @extend %wrap;
  }

  &__info-icon {
    margin-right: 0.625rem;
  }

  &__approval-button {
    width: 100%;
  }

  > .ody-confirm {
    > .ody-modal.confirm-modal {
      > .pending-profile-card__approval-button {
        @include xs {
          font-size: 1rem;
          padding: 0.5rem 1rem;
          width: 100%;
        }
      }
    }
  }
}
