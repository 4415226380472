// All styles for the learn more info section.
@import 'styles/styles';

.learn-more-info-section {
  @extend %vbox;

  gap: 10.75rem;
  justify-content: space-between;

  @include xs {
    gap: 3.75rem;
  }

  @include sm {
    gap: 5.75rem;
  }

  @include md {
    gap: 7.75rem;
  }
}
