// All styles for the image testimonial.
@import 'styles/styles';

.image-testimonial {
  @extend %hbox;

  align-items: center;
  flex-wrap: wrap;
  gap: 3.5rem;
  justify-content: space-evenly;

  @include xs {
    @include vbox;

    flex-direction: column-reverse;
    gap: 1.5rem;
    justify-content: center;
  }

  @include sm {
    @include vbox;

    flex-direction: column-reverse;
    gap: 2rem;
    justify-content: center;
  }

  @include md {
    gap: 3rem;
    justify-content: center;
  }

  &__info-content {
    @extend %vbox;

    gap: 2.5rem;
    max-width: 42.062rem;

    @include xs {
      gap: 1.5rem;
      max-width: 39rem;
      width: 95%;
    }

    @include sm {
      gap: 2rem;
      max-width: 39rem;
      width: 95%;
    }

    @include md {
      gap: 2rem;
      max-width: 30rem;
    }
  }

  &__quote-mark {
    color: $geyser;
    font-family: $commissioner;
    font-size: 8rem;
    font-weight: 700;
    height: 3rem;

    @include xs {
      font-size: 3rem;
      height: 1.75rem;
    }

    @include sm {
      font-size: 4rem;
      height: 2rem;
    }

    @include md {
      font-size: 6rem;
      height: 2.5rem;
    }
  }

  &__title {
    margin: 0;

    &.txt-lg {
      @include xs {
        font-size: 1rem;
        line-height: 1.5rem;
      }

      @include sm {
        font-size: 1rem;
        line-height: 1.5rem;
      }

      @include md {
        font-size: 1.375rem;
        line-height: 1.75rem;
      }
    }
  }

  &__description {
    font-weight: 300;

    &.txt-md {
      @include xs {
        font-size: 1rem;
        line-height: 1.5rem;
      }

      @include sm {
        font-size: 1rem;
        line-height: 1.5rem;
      }

      @include md {
        font-size: 1.375rem;
        line-height: 1.75rem;
      }
    }
  }

  &__image {
    @extend %vbox;

    border-radius: 1.875rem;
    box-shadow: $result-card-hover-shadow;
    justify-content: flex-end;
    padding: 1.875rem 2.5rem;

    @include xs {
      max-width: 39rem;
      min-height: 15rem;
      padding: 1rem 1.5rem;
      width: 82%;
    }

    @include sm {
      max-width: 39rem;
      min-height: 17rem;
      padding: 1.5rem 2rem;
      width: 82%;
    }

    @include md {
      min-height: 20rem;
      min-width: 25rem;
      padding: 1.75rem 2rem;
    }

    @include lg {
      min-height: 30rem;
      min-width: 35rem;
    }
  }

  &__name {
    &.txt-md {
      @include xs {
        font-size: 1rem;
        line-height: 1.5rem;
      }

      @include sm {
        font-size: 1rem;
        line-height: 1.5rem;
      }

      @include md {
        font-size: 1.375rem;
        line-height: 1.75rem;
      }
    }
  }

  &__age-location {
    &.txt {
      color: $gull-gray;
    }

    &.txt-md {
      @include xs {
        font-size: 1rem;
        line-height: 1.5rem;
      }

      @include sm {
        font-size: 1rem;
        line-height: 1.5rem;
      }

      @include md {
        font-size: 1.375rem;
        line-height: 1.75rem;
      }
    }
  }
}
