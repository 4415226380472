// All styles for the profile completion component.
@import 'styles/styles';

.profile-completion {
  @extend %vbox;

  width: 100%;

  > .next-steps-section {
    @extend %hbox;

    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;

    &.complete {
      justify-content: flex-end;
    }

    > a.btn-white {
      color: $moonstone-blue;
    }
  }

  > .hdg-md {
    padding-bottom: 1rem;
  }

  > .progress {
    @extend %vbox;

    border-radius: 1rem;

    @include xs {
      gap: 1rem;
      padding: 0;
    }

    @include sm {
      gap: 1rem;
      padding: 0;
    }

    @include md {
      align-items: center;
      background-color: $white;
      padding: 1.5625rem 0;
    }

    @include lg {
      align-items: center;
      background-color: $white;
      padding: 1.5625rem 0;
    }

    &.company-dashboard {
      @include md {
        border: 1px solid $silver-lake-blue;
      }

      @include lg {
        border: 1px solid $silver-lake-blue;
      }
    }

    > .status {
      @include xs {
        background-color: $white;
        border-radius: 1rem;
        padding: 0.625rem;
      }

      @include sm {
        background-color: $white;
        border-radius: 1rem;
        padding: 0.625rem;
      }

      @include md {
        width: 28.75rem;
      }

      @include lg {
        width: 28.75rem;
      }

      > .hdg {
        padding-bottom: 1rem;
        text-align: center;
      }

      > .progress-bar {
        background-color: $beau-blue;
        border-radius: 0 0.25rem 0.25rem 0;
        height: 2rem;

        > .progress {
          background-color: $middle-blue-purple;
          border-radius: 0.25rem 0 0 0.25rem;
          height: 2rem;
        }
      }
    }

    > .incomplete-steps {
      @include hbox;

      flex: 1;
      gap: 1rem;
      justify-content: space-between;
      max-width: 62rem;

      @include xs {
        @include vbox;

        width: 100%;
      }

      @include sm {
        @include vbox;

        width: 100%;
      }

      @include md {
        flex-wrap: wrap;
        margin-top: 2rem;
        padding: 0 1rem;
        width: 80%;
      }

      @include lg {
        flex-wrap: wrap;
        margin-top: 2rem;
        padding: 0 1rem;
        width: 80%;
      }

      > .incomplete-step {
        @extend %hbox;

        align-items: center;
        background-color: $white;
        border: 1px solid $silver-sand;
        border-radius: 0.5rem;
        padding: 0.5rem 1rem;

        @include md {
          max-width: 40%;
          min-width: 40%;
        }

        @include lg {
          max-width: 28.5rem;
          min-width: 45%;
        }

        > span {
          flex: 1;
        }

        > .image {
          @extend %hbox;

          align-items: center;
          align-self: center;
          margin-left: 1rem;

          > img {
            width: 100%;
          }
        }
      }
    }
  }

  > .roadmap {
    @extend %hbox;

    border-top: 4px solid $moonstone-blue;
    gap: 1rem;
    margin-top: 3.75rem;
    padding-top: 1rem;

    > .milestone {
      @extend %vbox;

      flex: 1;
      gap: 0.5rem;

      > .number {
        @extend %vbox;

        align-items: center;
        align-self: flex-start;
        border: 4px solid $moonstone-blue;
        border-radius: 50%;
        font-family: $commissioner;
        font-size: 1rem;
        height: 3rem;
        justify-content: center;
        width: 3rem;
      }

      &.complete > .number {
        background-color: $middle-blue-purple;
        border-color: $middle-blue-purple;
      }
    }
  }
}
