// All styles for sidebar profile completion
@import 'styles/styles';

.sidebar-profile-completion {
  @extend %vbox;

  &__title-container {
    @extend %hbox;

    align-items: center;
    justify-content: space-between;
    padding-bottom: 1rem;
  }

  &__progress-bar {
    background-color: $beau-blue;
    border-radius: 0 0.25rem 0.25rem 0;
    height: 2rem;
  }

  &__progress {
    background-color: $moonstone-blue;
    border-radius: 0.25rem 0 0 0.25rem;
    height: 2rem;
  }

  &__incomplete-step {
    @extend %hbox;

    align-items: center;
    gap: 1rem;
    justify-content: space-between;
    margin-top: 1rem;
  }
}
