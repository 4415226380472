// All styles for the saved job seeker individual card in the dashboard (companies).
@import 'styles/styles';

.saved-job-seeker-card {
  @extend %vbox;

  border: 1px solid $moonstone-blue;
  border-radius: 1.25rem;
  flex: 0 1 19.5rem;
  gap: 1rem;
  min-height: 21.87rem;
  padding: 1.5rem;
  position: relative;
  transition: all 0.2s ease;

  @include xs {
    flex: 0 1 auto;
    min-height: auto;
  }

  @include sm {
    flex: 0 1 auto;
    min-height: auto;
  }

  &:hover {
    box-shadow: $result-card-hover-shadow;
    cursor: pointer;

    @include sm {
      box-shadow: none;
    }

    @include xs {
      box-shadow: none;
    }
  }

  &__save-button {
    background-color: transparent;
    border: none;
    outline: none;
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
  }

  &__header {
    @extend %hbox;

    gap: 1.5rem;
  }

  &__header-logo-container {
    border-radius: 9999px;
    height: 4.35rem;
    width: 4.35rem;

    &.no-picture {
      background-color: $silver-sand;
      border-radius: 9999px;
    }
  }

  &__name-container {
    @extend %vbox;
  }

  &__about-section {
    @extend %vbox;

    gap: 0.31rem;
  }

  &__about-section-value {
    @include truncate-box-vertical(4);
  }

  &__jobs-interested-section {
    @extend %vbox;

    gap: 0.31rem;
  }

  &__jobs-interested-in {
    @extend %hbox;

    flex-wrap: wrap;
    gap: 0.625rem;
  }

  &__job {
    border-radius: 0.625rem;
    padding: 0.31rem;

    &--red {
      border: 1px solid $middle-red;
    }

    &--purple {
      border: 1px solid $african-violet;
    }

    &--yellow {
      border: 1px solid $maximum-yellow-red;
    }
  }
}
