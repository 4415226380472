// All fonts.

// The Iwona Regular font.
@font-face {
  font-family: Iwona;
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/Iwona-Regular.ttf') format('truetype');
}

// The Iwona Bold font.
@font-face {
  font-family: Iwona;
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/Iwona-Bold.ttf') format('truetype');
}

// The Iwona Heavy font.
@font-face {
  font-family: Iwona;
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/IwonaHeavy-Regular.ttf') format('truetype');
}

// The Commissioner Regular font.
@font-face {
  font-family: Commissioner;
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/Commissioner-Regular.ttf') format('truetype');
}

// The Commissioner Medium font.
@font-face {
  font-family: Commissioner;
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/Commissioner-Medium.ttf') format('truetype');
}

// The Commissioner Semi Bold font.
@font-face {
  font-family: Commissioner;
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/Commissioner-SemiBold.ttf') format('truetype');
}
