// All styles for the about us mission vision section.
@import 'styles/styles';

.about-us-mission-vision-section {
  @extend %vbox;

  background-color: $silver-lake-blue;
  gap: 2rem;
  padding: 5% 6%;

  @include xs {
    gap: 1.5rem;
    padding: 4%;
  }

  @include sm {
    gap: 1.5rem;
    padding: 4%;
  }

  @include md {
    gap: 1.5rem;
    padding: 5%;
  }

  &__title {
    @include xs {
      text-align: center;
    }

    @include sm {
      text-align: center;
    }
  }

  &__info-container {
    @extend %hbox;

    justify-content: space-between;

    @include xs {
      @include vbox;

      gap: 1.5rem;
      justify-content: center;
    }

    @include sm {
      @include vbox;

      gap: 2rem;
      justify-content: center;
    }
  }

  &__content {
    @extend %vbox;

    gap: 0.625rem;
    max-width: 45%;

    @include xs {
      max-width: 100%;
    }

    @include sm {
      max-width: 100%;
    }
  }

  &__mission-vision-title {
    @include xs {
      text-align: center;
    }

    @include sm {
      text-align: center;
    }
  }

  &__description {
    @include xs {
      text-align: center;
    }

    @include sm {
      text-align: center;
    }

    &.txt-md {
      @include xs {
        font-size: 1rem;
        line-height: 1.5rem;
      }

      @include sm {
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }
  }
}
