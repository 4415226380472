// All styles for sliders.
@import 'styles/styles';

.ody-slider {
  > .rc-slider-handle {
    background-color: $silver-lake-blue;
    border-color: $silver-lake-blue;
    opacity: 1;

    &:active {
      border-color: transparent;
      box-shadow: none;
    }
  }

  .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    border-color: transparent;
    box-shadow: none;
  }

  > .rc-slider-mark > .rc-slider-mark-text {
    color: $raisin-black;
    font-family: $commissioner;
    font-size: 1.375rem;
    font-weight: normal;

    @include xs {
      font-size: 1rem;
    }

    @include sm {
      font-size: 1rem;
    }
  }

  > .rc-slider-rail {
    background-color: $silver-sand;
    height: 0.25rem;
  }

  > .rc-slider-track {
    background-color: $silver-lake-blue;
    height: 0.25rem;
  }
}

.rc-tooltip.ody-slider-tooltip {
  padding: 0;

  > .rc-tooltip-content {
    > .rc-tooltip-arrow {
      display: none;
    }

    > .rc-tooltip-inner {
      background-color: transparent;
      box-shadow: none;
      color: $raisin-black;
      font-family: $commissioner;
      font-size: 1rem;
      font-weight: normal;
      min-height: auto;
      padding: 0;
    }
  }
}
