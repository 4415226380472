// All styles for the about us welcome key metrics section.
@import 'styles/styles';

.about-us-key-metrics-section {
  @extend %vbox;

  bottom: -10%;
  gap: 1.81rem;
  z-index: 1;

  @include xs {
    gap: 1rem;
  }

  @include sm {
    gap: 1.375rem;
  }

  @include md {
    gap: 1.5rem;
  }

  &__title {
    margin: 0;
  }

  &__metrics-container {
    @extend %hbox;

    flex-wrap: wrap;
    gap: 1.5rem;
    justify-content: space-between;

    @include xs {
      @include vbox;
    }

    @include sm {
      @include vbox;
    }

    @include md {
      animation: fade-in-up 0.5s ease-in-out forwards;
      gap: 1.375rem;
      justify-content: flex-start;
      opacity: 0;
    }

    @include lg {
      animation: fade-in-up 0.5s ease-in-out forwards;
      opacity: 0;
    }
  }
}
