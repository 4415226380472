// All styles for the profile
@import 'styles/styles';

.ody-profile {
  @extend %vbox;

  &__profile-section {
    @include hbox;

    @include xs {
      @include vbox;

      padding: 1rem;
    }

    @include sm {
      @include vbox;

      padding: 1rem 14.39%;
    }

    @include md {
      padding: 2.5rem 13.8%;
    }

    @include lg {
      padding: 2.5rem 11.15%;
    }

    &--left {
      @extend %vbox;

      @include xs {
        margin-bottom: 1.5rem;
        width: 100%;
      }

      @include sm {
        margin-bottom: 1.5rem;
        width: 100%;
      }

      @include md {
        width: 20rem;
      }

      @include lg {
        width: 26rem;
      }
    }

    &--right {
      @extend %vbox;

      flex: 1;
      gap: 4.6875rem;

      @include xs {
        padding: 0 0.3rem;
      }

      @include sm {
        padding: 0 0.3rem;
      }

      @include md {
        margin-left: 4rem;
      }

      @include lg {
        margin-left: 8rem;
      }
    }
  }

  &__banner {
    @extend %hbox;
  }

  &__banner-content {
    @extend %vbox;

    background-color: $moonstone-blue;
    flex: 1;
    font-family: $iwona;
    font-size: 2rem;
    font-weight: 700;
    line-height: 3rem;
    padding: 5rem 10.417% 5rem 15.625%;

    @include xs {
      font-size: 1rem;
      line-height: 1.375rem;
      padding: 2rem;
    }

    @include sm {
      padding: 2.5rem 14.39%;
    }

    @include md {
      padding: 5rem 3.8%;
    }

    @include lg {
      padding: 5rem 11.15%;
    }
  }

  &__banner-text {
    padding-bottom: 2rem;
    white-space: break-spaces;
  }

  &__banner-buttons-section {
    @extend %hbox;

    align-items: flex-end;

    > .ody-profile__banner-button {
      color: $moonstone-blue;

      &:first-of-type {
        margin-right: 1.5rem;
      }
    }
  }

  &__banner-logo {
    @extend %hbox;

    background-color: $silver-lake-blue;

    > img {
      align-self: center;
      padding: 0 6rem;
      width: 100%;
    }
  }

  &__images-section {
    @extend %vbox;
  }

  &__information-section {
    @extend %vbox;

    border: 1px solid $moonstone-blue;
    border-bottom-left-radius: 1.25rem;
    border-bottom-right-radius: 1.25rem;
    border-top: none;
    gap: 2.5rem;
    margin-top: -7.5rem;
    padding: 0 2rem 1.875rem;
    position: relative;

    @include xs {
      margin-top: -5rem;
      padding: 0 1rem 0.875rem;
    }

    @include sm {
      margin-top: -5rem;
      padding: 0 1rem 0.875rem;
    }
  }

  &__buttons-section {
    @extend %hbox;

    flex-wrap: wrap;
    gap: 1rem;
    padding: 2.5rem 1.7rem;

    @include xs {
      @include vbox;

      gap: 1rem;
      padding: 1.5rem 1rem;
    }

    @include sm {
      @include vbox;

      gap: 1rem;
      padding: 1.5rem 1rem;
    }

    @include md {
      @include vbox;

      gap: 1rem;
    }

    > .profile-confirm {
      > .confirm-modal {
        > .profile-button {
          > .profile-button-icon {
            height: 0.85rem;
            margin-right: 1rem;
            width: 0.89rem;
          }
        }
      }
    }

    > .profile-button {
      > .profile-button-icon {
        height: 0.85rem;
        margin-right: 1rem;
        width: 0.89rem;
      }
    }
  }

  &__profile-button {
    @extend %hbox;

    align-items: center;
    justify-content: center;
  }

  &__profile-button-icon {
    height: 0.85rem;
    margin-right: 1rem;
    width: 0.89rem;
  }

  &--not-found {
    align-items: center;
    height: 100%;
    justify-content: center;
  }
}

[role='dialog'].popup-content.profile-editor-modal-content {
  @extend %vbox;

  height: auto;
  max-height: 95vh;
  max-width: 47.5rem;
  overflow: hidden;
  width: calc(100% - 2rem);

  > .profile-editor {
    @extend %discreet-scrollable;

    background-color: $white;
    border-radius: 1.25rem;
    flex: 1;
    height: 100%;
    padding: 1.5rem;

    > .hdg-md {
      padding-bottom: 1.5rem;
    }

    > .profile-editor__form-fields {
      @extend %vbox;

      gap: 1rem;

      > .profile-editor__form-field {
        label {
          font-size: 1rem;
          font-weight: normal;
          line-height: 1.25rem;
        }

        input {
          &[type='password'],
          &[type='text'] {
            font-size: 1rem;
            font-weight: normal;
            line-height: 1.25rem;
            padding: 0.5rem;
          }
        }

        textarea {
          font-size: 1rem;
          font-weight: normal;
          line-height: 1.25rem;
          padding: 0.5rem;
        }

        > .profile-editor__form-field--born-on {
          @include hbox;

          @include xs {
            @include vbox;
          }

          @include sm {
            gap: 1rem;
          }

          @include md {
            gap: 1rem;
          }

          @include lg {
            gap: 1rem;
          }

          > label {
            flex: 1;
          }
        }
      }

      > .profile-editor__actions {
        @extend %hbox;

        flex: 1;
        gap: 1rem;
        justify-content: space-between;
        padding-top: 2rem;

        &--left {
          @extend %hbox;

          flex: 1;
          gap: 1rem;
          justify-content: flex-start;
        }

        &--right {
          @extend %hbox;

          flex: 1;
          gap: 1rem;
          justify-content: flex-end;
        }

        > .profile-editor__button {
          @extend %hbox;

          &--custom {
            @include xs {
              font-size: 1rem;
              padding: 0.5rem 1rem;
            }

            @include sm {
              font-size: 1rem;
              padding: 0.5rem 1rem;
            }

            @include md {
              font-size: 1.375rem;
              padding: 1rem;
            }

            @include lg {
              font-size: 1.375rem;
              padding: 1rem;
            }
          }
        }
      }
    }

    > .profile-editor__video-container {
      margin-bottom: 1rem;

      > .profile-editor__video-container--no-video {
        @extend %vbox;

        align-items: center;
        border: 1px dashed $gainsboro;
        border-radius: 1rem;
        height: 20rem;
        justify-content: center;
        width: 100%;
      }

      > .profile-editor__video-container--video {
        background-color: $raisin-black;
        height: auto;
        max-height: 30rem;
        width: 100%;
      }

      > .profile-editor__video-container--buttons {
        @extend %hbox;

        align-items: center;
        justify-content: space-between;
        margin-top: 1rem;
      }
    }

    > .profile-editor__resume-container {
      margin-bottom: 1rem;

      > .profile-editor__resume-container--no-resume {
        @extend %vbox;

        align-items: center;
        border: 1px dashed $gainsboro;
        border-radius: 1rem;
        height: 20rem;
        justify-content: center;
        width: 100%;
      }

      > .profile-editor__resume-container--buttons {
        @extend %hbox;

        align-items: center;
        justify-content: space-between;
        margin-top: 1rem;
      }
    }

    .error {
      color: $copper-red;
      font-family: $commissioner;
      font-size: 1rem;
      font-weight: normal;
      line-height: 1.25rem;
      margin-top: 1.5rem;

      @include xs {
        font-size: 0.8rem;
        line-height: 1rem;
      }

      @include sm {
        font-size: 0.8rem;
        line-height: 1rem;
      }
    }
  }
}
