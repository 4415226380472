// All styles for the learn more info item.
@import 'styles/styles';

.learn-more-info-item {
  @extend %hbox;

  gap: 1.87rem;
  position: relative;

  @include xs {
    gap: 0.8rem;
  }

  @include sm {
    gap: 0.8rem;
  }

  @include md {
    gap: 1rem;
    max-width: 30.4rem;
  }

  @include lg {
    max-width: 30.4rem;
  }

  &__visible {
    @include sm {
      animation: slide-from-right-in 0.5s ease-in-out forwards;
      transform: translateX(6.25rem);
    }

    @include md {
      animation: slide-from-right-in 0.5s ease-in-out forwards;
      transform: translateX(6.25rem);
    }

    @include lg {
      animation: slide-from-right-in 0.5s ease-in-out forwards;
      transform: translateX(6.25rem);
    }
  }

  &__icon-container {
    @extend %hbox;

    align-items: center;
    align-self: flex-start;
    background-color: $isabelline;
    border-radius: 1.25rem;
    box-shadow: $result-card-hover-shadow;
    justify-content: center;
    padding: 1.25rem;

    @include xs {
      padding: 0.8rem;
    }

    @include sm {
      padding: 0.8rem;
    }

    @include md {
      padding: 1rem;
    }
  }

  &__info-container {
    @extend %vbox;

    gap: 1.62rem;

    @include xs {
      gap: 1rem;
    }

    @include sm {
      gap: 1rem;
    }

    @include md {
      gap: 1.2rem;
    }
  }

  &__number {
    color: rgba($isabelline, 50%);
    font-family: $iwona;
    font-size: 8rem;
    font-weight: 700;
    line-height: 11.11rem;
    position: absolute;
    right: -1.875rem;
    top: -4.375rem;

    @include xs {
      font-size: 3rem;
      right: -0.5rem;
      top: -4.6rem;
    }

    @include sm {
      font-size: 5rem;
      right: -0.5rem;
      top: -4.6rem;
    }

    @include md {
      right: -2rem;
    }
  }
}
