// All styles for the profile overview in the dashboard.
@import 'styles/styles';

.profile-overview {
  &__left-section {
    @extend %vbox;

    flex: 1;
    gap: 2rem;

    @include xs {
      gap: 1.5rem;
    }

    @include sm {
      gap: 1.5rem;
    }

    @include md {
      gap: 2rem;
    }

    @include lg {
      gap: 2rem;
    }
  }

  &__image-and-name {
    @extend %hbox;

    align-items: center;
  }

  &__image {
    @extend %hbox;

    align-items: center;
    border-radius: 9999px;
    justify-content: center;
    overflow: hidden;

    @include xs {
      flex: 0 0 12.5rem;
      height: 6.67rem;
      margin-right: 1rem;
      width: 6.67rem;
    }

    @include sm {
      flex: 0 0 6.67rem;
      height: 6.67rem;
      margin-right: 1rem;
      width: 6.67rem;
    }

    @include md {
      flex: 0 0 12.5rem;
      height: 12.5rem;
      margin-right: 2.5rem;
      width: 12.5rem;
    }

    @include lg {
      flex: 0 0 12.5rem;
      height: 12.5rem;
      margin-right: 2.5rem;
      width: 12.5rem;
    }
  }

  &__no-picture {
    background-color: $silver-sand;
    border-radius: 9999px;
  }

  &__name {
    @extend %wrap;
  }

  &__description {
    white-space: break-spaces;
  }

  &__location-with-industry {
    @extend %hbox;

    @include xs {
      @include vbox;
    }

    @include sm {
      justify-content: space-between;
    }
  }

  &__location {
    @extend %hbox;

    margin-right: 1.56rem;

    @include xs {
      margin-bottom: 1rem;
    }
  }

  &__icon {
    align-self: center;

    @include xs {
      margin-right: 0.5rem;
    }

    @include sm {
      margin-right: 0.5rem;
    }

    @include md {
      margin-right: 0.75rem;
    }

    @include lg {
      margin-right: 0.75rem;
    }
  }

  &__company-industry {
    @extend %hbox;
  }

  &__button-link {
    @include xs {
      width: 100%;
    }

    @include sm {
      width: 100%;
    }
  }

  &__right-section {
    @include hbox;

    align-items: flex-start;
    flex: 1;
    justify-content: flex-end;

    @include xs {
      @include vbox;
    }

    @include sm {
      @include vbox;
    }

    @include lg {
      background-repeat: no-repeat;
    }

    &--companies {
      @include lg {
        background-image: url('/images/monocular.png');
        background-position: left top;
        background-size: cover;
      }
    }

    &--job-seekers {
      @include lg {
        background-image: url('/images/ship.png');
        background-position: center right;
      }
    }

    &--endorsers {
      @include lg {
        background-image: url('/images/endorser-illustration.png');
      }
    }
  }
}
