// All styles for paginators.
@import 'styles/styles';

.ody-paginator {
  @extend %hbox;

  .btn {
    border: 1px solid $moonstone-blue;
    font-family: $commissioner;
    font-size: 1.375rem;
    height: 3.5rem;
    width: 3.5rem;

    @include xs {
      font-size: 1rem;
      height: 2.25rem;
      width: 2.25rem;
    }

    @include sm {
      font-size: 1rem;
      height: 2.75rem;
      width: 2.75rem;
    }

    &.page {
      border-left: none;
      border-right: none;

      &.current {
        background-color: $silver-lake-blue;
        color: $white;
        margin: 0 0.5rem;
      }

      &.immediately-before-current {
        border-right: 1px solid $moonstone-blue;
      }

      &.immediately-after-current {
        border-left: 1px solid $moonstone-blue;
      }
    }
  }

  > div {
    @extend %vbox;

    align-items: center;
    border: 1px solid $moonstone-blue;
    color: $raisin-black;
    font-family: $commissioner;
    font-size: 1.375rem;
    height: calc(3.5rem - 2px);
    justify-content: center;
    width: 3.5rem;

    @include xs {
      font-size: 1rem;
      height: calc(2.25rem - 2px);
      width: 2.25rem;
    }

    @include sm {
      font-size: 1rem;
      height: calc(2.75rem - 2px);
      width: 2.75rem;
    }

    &.navigate {
      &:first-child {
        border-bottom-left-radius: 0.25rem;
        border-right: none;
        border-top-left-radius: 0.25rem;

        &.immediately-before-current {
          border-right: 1px solid $moonstone-blue;
        }
      }

      &:last-child {
        border-bottom-right-radius: 0.25rem;
        border-left: none;
        border-top-right-radius: 0.25rem;

        &.immediately-after-current {
          border-left: 1px solid $moonstone-blue;
        }
      }

      > .btn {
        border: none;
      }
    }

    &.more-pages {
      border-left: none;
      border-right: none;

      &.immediately-before-current {
        border-right: 1px solid $moonstone-blue;
      }

      &.immediately-after-current {
        border-left: 1px solid $moonstone-blue;
      }
    }
  }
}
