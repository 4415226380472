// All styles for the lamp icon.
@import 'styles/styles';

.lamp-icon {
  @include xs {
    height: 13rem;
  }

  @include sm {
    animation: lights-on-off-animation 2s ease-in-out infinite;
    height: 20rem;
  }

  @include md {
    animation: lights-on-off-animation 2s ease-in-out infinite;
    height: 17rem;
  }

  @include lg {
    animation: lights-on-off-animation 2s ease-in-out infinite;
    height: 30.62rem;
  }
}

@keyframes lights-on-off-animation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.3;
  }

  100% {
    opacity: 1;
  }
}
