// All styles for the search page (job seekers).
@import 'styles/styles';

.search-form {
  @extend %vbox;

  min-height: 13.93rem;
  position: relative;

  @include xs {
    padding: 1rem 6.6%;
  }

  @include sm {
    padding: 1rem 14.39%;
  }

  @include md {
    padding: 2.5rem 4.8%;
  }

  @include lg {
    padding: 2.5rem 15.46%;
  }

  &.company-dashboard {
    min-height: 10.25rem;

    @include xs {
      padding: 1rem 6.6%;
    }

    @include sm {
      padding: 1rem 14.39%;
    }

    @include md {
      padding: 2.5rem 13.8%;
    }

    @include lg {
      padding: 2.5rem 11.15%;
    }
  }

  &.job-seekers {
    background-color: $middle-red;
  }

  &.companies {
    background-color: $maximum-yellow-red;
  }

  > .hdg {
    margin-bottom: 1rem;
  }

  > .form {
    @extend %vbox;

    z-index: 1;

    @include xs {
      gap: 0.5rem;
    }

    @include sm {
      gap: 0.5rem;
    }

    @include md {
      gap: 2rem;
    }

    @include lg {
      gap: 2rem;
    }

    > form {
      @include vbox;

      gap: 0.5rem;

      > .fields-and-button {
        @include hbox;

        gap: 0.5rem;

        @include xs {
          @include vbox;
        }

        @include sm {
          @include vbox;
        }

        > .ody-select {
          flex: 1;
          font-size: 1.375rem;
          margin-top: 0;

          > .ody-select__control > .ody-select__value-container {
            padding: 1rem;
          }

          > .ody-select__menu > .ody-select__menu-list > .ody-select__option {
            font-size: 1.375rem;
            line-height: 1.75rem;
          }

          @include xs {
            font-size: 1rem;

            > .ody-select__control > .ody-select__value-container {
              padding: 0.5rem;
            }

            > .ody-select__menu > .ody-select__menu-list > .ody-select__option {
              font-size: 1rem;
              line-height: 1.25rem;
            }
          }

          @include sm {
            font-size: 1rem;

            > .ody-select__control > .ody-select__value-container {
              padding: 0.5rem;
            }

            > .ody-select__menu > .ody-select__menu-list > .ody-select__option {
              font-size: 1rem;
              line-height: 1.25rem;
            }
          }
        }

        > .btn-custom {
          font-size: 1.375rem;
          padding: 0.5rem 1rem;

          @include xs {
            font-size: 1rem;
          }

          @include sm {
            font-size: 1rem;
          }
        }
      }

      > span.error-message {
        color: $raisin-black;
        font-size: 1.375rem;
        line-height: 1.75rem;

        @include xs {
          font-size: 1rem;
          line-height: 1.25rem;
        }

        @include sm {
          font-size: 1rem;
          line-height: 1.25rem;
        }
      }
    }
  }

  .tags {
    .ody-tags-selected-options {
      > .ody-tags-selected-option {
        background-color: $white;
        border: none;
        border-radius: 0.5rem;
        font-size: 1.375rem;
        padding: 1rem;

        > .ody-tags-remove {
          font-size: 1.375rem;
        }

        @include xs {
          font-size: 1rem;
          padding: 0.5rem;

          > .ody-tags-remove {
            font-size: 1rem;
          }
        }

        @include sm {
          font-size: 1rem;
          padding: 0.5rem;

          > .ody-tags-remove {
            font-size: 1rem;
          }
        }
      }
    }
  }

  > .waves-image {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
  }
}
