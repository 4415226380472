// All styles for the endorsee profile information.
@import 'styles/styles';

.endorsee-profile-information {
  @extend %hbox;

  background-color: $rhythm;
  gap: 5.0625rem;
  padding: 1.6% 21.5% 1%;

  @include md {
    padding: 1.6% 13.8% 1%;
  }

  @include sm {
    @include vbox;

    gap: 1rem;
    padding: 1.6% 14.39% 1%;
  }

  @include xs {
    @include vbox;

    gap: 0.7rem;
    padding: 1.6% 6.6% 1%;
  }

  &__profile-image-name-container {
    @extend %hbox;

    gap: 1.5rem;
  }

  &__profile-image-container {
    border-radius: 9999px;
    height: 5.875rem;
    width: 5.875rem;

    @include xs {
      height: 4.35rem;
      width: 4.35rem;
    }

    &.no-picture {
      background-color: $silver-sand;
      border-radius: 9999px;
    }
  }

  &__profile-name-container {
    @extend %vbox;
  }

  &__profile-information {
    @extend %vbox;

    align-items: flex-start;
    gap: 1rem;
    justify-content: flex-start;
  }

  &__short-bio {
    @include truncate-box-vertical(4);
  }

  &__location-container {
    @extend %hbox;

    align-items: center;
    gap: 0.7rem;
  }

  &__view-profile-button {
    background-color: $white;
    color: $rhythm;
  }
}
