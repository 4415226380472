// All styles for the question box of the endorsement form.
@import 'styles/styles';

.question-box {
  @extend %vbox;

  border: 1px solid $moonstone-blue;
  border-radius: 0.93rem;
  gap: 2rem;
  padding: 1.5rem;

  &__error {
    border: 1px solid $copper-red;
  }

  &__container {
    @extend %vbox;

    gap: 0.7rem;
  }

  &__questions-section {
    @extend %vbox;

    gap: 1rem;

    &--secondary {
      color: $dove-gray;
    }
  }

  &__answers-section {
    @extend %vbox;

    gap: 2rem;
  }

  &__answer {
    @extend %hbox;

    gap: 0.6875rem;
  }

  &__radio {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    background-image: url('/images/incomplete.png');
    background-position: right top;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    height: 1.25rem;
    transition: all 0.2s ease;
    width: 1.25rem;
    will-change: background-image;

    &:hover {
      cursor: pointer;
    }

    &:checked {
      background-image: url('/images/checked.png');
      background-position: right top;
      background-repeat: no-repeat;
    }
  }

  &__checkbox {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.3125rem;
    display: inline-block;
    height: 1.25rem;
    margin-right: 0.5rem;
    min-height: 1.25rem;
    min-width: 1.25rem;
    position: relative;
    width: 1.25rem;

    &:checked {
      background-color: $silver-lake-blue;

      &::after {
        color: $white;
        content: 'L';
        font-size: 0.625rem;
        left: 0.375rem;
        position: absolute;
        top: 0.1875rem;
        -webkit-transform: scaleX(-1) rotate(-35deg); /* Chrome, Safari, Opera */
        transform: scaleX(-1) rotate(-35deg);
      }
    }
  }

  &__label {
    cursor: pointer;
  }

  &__textarea {
    border: 1px solid $silver-sand;
    border-radius: 0.5rem;
    max-height: 12.5rem;
    resize: none;
    width: 100%;
  }
}
