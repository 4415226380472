// All styles for the about us matching problem section.
@import 'styles/styles';

.about-us-matching-problem-section {
  @extend %vbox;

  background-color: $silver-lake-blue;
  padding: 8% 10%;

  @include xs {
    padding: 4%;
  }

  @include sm {
    padding: 4%;
  }

  @include md {
    padding: 13% 8% 5%;
  }

  &__problems-container {
    @extend %hbox;

    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 2.93rem;

    @include xs {
      @include vbox;

      gap: 2rem;
      justify-content: center;
    }

    @include sm {
      @include vbox;

      gap: 3rem;
      justify-content: center;
    }

    @include md {
      gap: 3rem;
      justify-content: center;
    }
  }

  &__title {
    margin-bottom: 2rem;

    @include xs {
      margin-bottom: 1.5rem;
      text-align: center;
    }

    @include sm {
      margin-bottom: 1.75rem;
      text-align: center;
    }

    @include md {
      text-align: center;
    }
  }

  &__problem {
    @extend %vbox;

    align-items: center;
    gap: 1.5rem;
    justify-content: center;

    @include xs {
      gap: 1rem;
      width: 100%;
    }

    @include sm {
      gap: 1.375rem;
      width: 100%;
    }
  }

  &__problem-info {
    @extend %vbox;

    align-items: center;
    justify-content: center;
  }

  &__problem-title {
    margin: 0;
    text-align: center;
  }

  &__problem-description {
    text-align: center;

    &.txt-md {
      @include xs {
        font-size: 1rem;
        line-height: 1.5rem;
      }

      @include sm {
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }
  }

  &__matching-problem-title {
    text-align: center;
  }
}
