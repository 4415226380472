// All styles for the public FAQ section.
@import 'styles/styles';

.public-faq-section {
  @extend %vbox;

  align-items: center;
  background-color: $maximum-yellow-red;
  gap: 2.5rem;
  justify-content: center;
  padding: 5% 20.57%;

  &--red {
    background-color: $middle-red;
  }

  @include xs {
    @include vbox;

    padding: 4% 6%;
  }

  @include sm {
    @include vbox;

    padding: 4% 6%;
  }

  @include md {
    padding: 5% 4%;
  }

  &__title {
    text-align: left;
    width: 100%;
  }
}
