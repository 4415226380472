// All styles for the background image in the profile
@import 'styles/styles';

.ody-profile-background-image {
  @extend %hbox;

  height: 7.9375rem;
  position: relative;

  > .edit-button {
    align-items: center;
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    z-index: 4;

    @include xs {
      padding: 0.4rem 0.525rem;

      > img {
        width: 0.75rem;
      }
    }

    @include sm {
      padding: 0.4rem 0.525rem;

      > img {
        width: 0.75rem;
      }
    }

    @include md {
      padding: 0.5rem 0.6rem;

      > img {
        width: 1rem;
      }
    }

    @include lg {
      padding: 0.5rem 0.6rem;

      > img {
        width: 1rem;
      }
    }
  }

  > img {
    height: 100%;
    width: 100%;
    z-index: 2;
  }

  > .ody-modal > button {
    display: none;
  }
}
