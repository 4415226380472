// All styles for the forgot-password page.
@import 'styles/styles';

.ody-forgot-password {
  @include hbox;

  height: 100%;
  position: relative;

  &__form-wrapper {
    @extend %vbox;

    align-items: center;
    flex: 1;
    gap: 1rem;
    max-width: 100%;
    z-index: 1;

    @include xs {
      padding: 1rem;
    }

    @include sm {
      padding: 1rem;
    }

    @include md {
      padding-bottom: 3rem;
      padding-right: 45%;
      padding-top: 3rem;
    }

    @include lg {
      padding-bottom: 5rem;
      padding-right: 50%;
      padding-top: 5rem;
    }
  }

  &__form-title {
    margin-bottom: 1rem;
  }

  &__sent {
    white-space: break-spaces;

    @include xs {
      padding: 0 1rem;
    }

    @include sm {
      padding: 0 1rem;
    }

    @include md {
      padding: 0 3rem;
    }

    @include lg {
      padding: 0 5rem;
    }
  }

  &__form {
    @extend %vbox;

    align-items: center;
    gap: 1rem;

    @include xs {
      width: 100%;
    }

    @include sm {
      width: 100%;
    }

    @include md {
      width: 95%;
    }

    @include lg {
      width: 80%;
    }
  }

  &__form-control {
    @extend %vbox;

    gap: 0.3rem;
    width: 100%;
  }

  &__submit-button {
    width: 100%;

    @include xs {
      font-size: 1rem;
      padding: 1rem;
      width: 100%;
    }

    @include sm {
      font-size: 1rem;
      padding: 1rem 3.5rem;
    }

    @include md {
      font-size: 1.5rem;
      padding: 1rem 3.5rem;
    }

    @include lg {
      font-size: 1.5rem;
      padding: 1rem 3.5rem;
    }
  }

  &__logo {
    @extend %hbox;

    align-items: center;
    flex: 1;
    height: 100vh;
    justify-content: center;
    position: fixed;
    right: 0;

    @include xs {
      display: none;
    }

    @include sm {
      display: none;
    }

    @include md {
      background-color: $silver-lake-blue;
      width: 45%;
    }

    @include lg {
      background-color: $silver-lake-blue;
      width: 50%;
    }
  }
}
