// All styles for the learn more hr package.
@import 'styles/styles';

.learn-more-hr-package {
  @extend %vbox;

  background-color: $white;
  border-radius: 0.93rem;
  box-shadow: $result-card-hover-shadow;
  justify-content: space-between;
  max-width: 26.56rem;
  min-height: 30rem;
  padding: 2.5rem;

  @include xs {
    box-shadow: none;
    max-width: 20rem;
    min-height: 25rem;
    padding: 1.5rem;
  }

  @include sm {
    box-shadow: none;
    max-width: 26.56rem;
    min-height: 25rem;
    padding: 1.5rem;
  }

  @include md {
    max-width: 23rem;
    min-height: 26rem;
    opacity: 0;
    padding: 2rem;
  }

  @include lg {
    opacity: 0;
  }

  &__visible {
    @include md {
      animation: fade-in-up 0.5s ease-in-out forwards;
      transform: translateY(6.25rem);
    }

    @include lg {
      animation: fade-in-up 0.5s ease-in-out forwards;
      transform: translateY(6.25rem);
    }
  }

  &__title-section {
    @extend %vbox;
  }

  &__title-container {
    @extend %hbox;
  }

  &__title {
    margin: 0;

    &.txt {
      @include xs {
        font-size: 1.2rem;
        line-height: 1.5rem;
      }

      @include sm {
        font-size: 1.2rem;
        line-height: 1.5rem;
      }

      @include md {
        font-size: 1.375rem;
        line-height: 1.75rem;
      }
    }
  }

  &__title-description {
    color: $dove-gray;

    &.txt {
      @include xs {
        font-size: 1rem;
        line-height: 1.5rem;
      }

      @include sm {
        font-size: 1rem;
        line-height: 1.5rem;
      }

      @include md {
        font-size: 1.2rem;
        line-height: 1.5rem;
      }
    }
  }

  &__pricing-description {
    color: $dove-gray;

    &.txt {
      @include xs {
        font-size: 1rem;
        line-height: 1.5rem;
      }

      @include sm {
        font-size: 1rem;
        line-height: 1.5rem;
      }

      @include md {
        font-size: 1.2rem;
        line-height: 1.5rem;
      }
    }
  }

  &__description-container {
    @extend %vbox;

    gap: 1rem;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__description-item {
    @extend %hbox;

    align-items: center;
    gap: 1.66rem;
  }

  &__description-text {
    &.txt {
      @include xs {
        font-size: 1rem;
        line-height: 1.5rem;
      }

      @include sm {
        font-size: 1rem;
        line-height: 1.5rem;
      }

      @include md {
        font-size: 1.2rem;
        line-height: 1.5rem;
      }
    }
  }

  &__button {
    @extend %hbox;

    align-items: center;
    gap: 1rem;
    justify-content: center;

    &.btn-sm {
      padding: 1rem 0;

      @include xs {
        padding: 0.5rem 0;
      }
    }

    &.btn-blue {
      border: none;
      color: $white;
      font-size: 1.5rem;
      font-weight: 700;
      text-align: center;

      @include xs {
        font-size: 1rem;
      }

      @include sm {
        font-size: 1rem;
      }
    }
  }
}
