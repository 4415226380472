// All styles for the home page learn more section.
@import 'styles/styles';

.learn-more-section {
  @extend %vbox;

  background-color: $silver-lake-blue;
  justify-content: center;
  padding: 7.5rem 0;

  @include xs {
    padding: 3rem 0;
  }

  @include sm {
    padding: 5rem 0;
  }

  &__titles-section {
    margin-bottom: 4.37rem;

    @include xs {
      margin-bottom: 1.37rem;
    }

    @include sm {
      margin-bottom: 2.37rem;
    }

    @include md {
      margin-bottom: 3.37rem;
    }
  }

  &__title {
    margin-bottom: 1.68rem;
    text-align: center;
  }

  &__sub-title {
    color: $scarpa-flow;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.75rem;
    text-align: center;

    @include xs {
      font-size: 0.8rem;
    }

    @include sm {
      font-size: 1rem;
    }
  }

  &__cards-section {
    @extend %hbox;

    align-items: center;
    justify-content: center;

    @include xs {
      @include vbox;

      gap: 1.5rem;
    }

    @include sm {
      @include vbox;

      gap: 2rem;
    }

    @include md {
      gap: 3.125rem;
    }

    @include lg {
      gap: 6.75rem;
    }
  }
}
