// All styles for the social responsibility first section.
@import 'styles/styles';

.social-responsibility-first-section {
  @extend %hbox;

  align-items: center;
  background-color: $isabelline;
  justify-content: space-between;
  padding: 5% 0 5% 10%;

  @include xs {
    @include vbox;

    flex-direction: column-reverse;
    gap: 1rem;
    justify-content: center;
    padding: 4%;
  }

  @include sm {
    @include vbox;

    flex-direction: column-reverse;
    gap: 1rem;
    justify-content: center;
    padding: 4%;
  }

  @include md {
    min-height: 50.87rem;
    padding: 5% 0 5% 5%;
  }

  &__content {
    @extend %vbox;

    gap: 4.125rem;
    max-width: 40.5rem;

    @include xs {
      gap: 1.5rem;
      max-width: 100%;
    }

    @include sm {
      gap: 2.125rem;
      max-width: 100%;
    }

    @include md {
      animation: fade-in-up 0.5s ease-in-out forwards;
      gap: 3.125rem;
      max-width: 30.5rem;
      opacity: 0;
      transform: translateY(6.25rem);
    }

    @include lg {
      animation: fade-in-up 0.5s ease-in-out forwards;
      opacity: 0;
      transform: translateY(6.25rem);
    }
  }

  &__description {
    white-space: break-spaces;

    &.txt {
      @include xs {
        font-size: 1rem;
      }

      @include sm {
        font-size: 1.375rem;
      }

      @include md {
        font-size: 1.5rem;
      }
    }
  }

  &__animated-icon {
    @include xs {
      height: 13rem;
    }

    @include sm {
      height: 18rem;
    }

    @include md {
      height: 18rem;
    }

    @include lg {
      height: 37rem;
    }
  }
}
