// All styles for the qualifications in the profile (job seekers).
@import 'styles/styles';

.profile-qualifications {
  @extend %vbox;

  &__cards-container {
    @extend %hbox;

    flex-wrap: wrap;

    @include xs {
      gap: 1rem;
    }

    @include sm {
      gap: 1rem;
    }

    @include md {
      gap: 2.5rem;
    }

    @include lg {
      gap: 2.5rem;
    }
  }
}

[role='dialog'].popup-content.profile-qualifications-details-modal-content {
  max-width: 33rem;

  > .profile-qualification-card {
    flex: 1;
  }
}
