// All styles for the home page learn more cards section.
@import 'styles/styles';

.home-learn-more-cards-section {
  @extend %vbox;

  align-items: center;
  gap: 3.75rem;
  justify-content: center;

  @include xs {
    gap: 1.5rem;
    max-width: 80%;
  }

  @include sm {
    gap: 2rem;
    max-width: 80%;
  }

  @include md {
    max-width: 40%;
  }

  @include lg {
    max-width: 30%;
  }

  &__job-seekers {
    @include xs {
      border-bottom: 1px solid $mischka;
      padding-bottom: 1.5rem;
    }

    @include sm {
      border-bottom: 1px solid $mischka;
      padding-bottom: 2rem;
    }

    @include md {
      border-right: 1px solid $mischka;
      padding-right: 3.125rem;
    }

    @include lg {
      border-right: 1px solid $mischka;
      padding-right: 6.75rem;
    }
  }

  &__learn-more-button {
    text-align: center;
    width: 50%;

    &.btn-blue {
      background-color: $moonstone-blue;
    }

    &.btn-sm {
      padding: 1rem;

      @include xs {
        padding: 0.5rem 1rem;
      }
    }
  }

  &__learn-more-button-text {
    font-size: 1.5rem;

    @include xs {
      font-size: 1rem;
    }

    @include sm {
      font-size: 1rem;
    }
  }
}
