// All styles for the settings page.
@import 'styles/styles';

.ody-settings {
  @extend %vbox;

  padding: 3rem 22.5%;

  @include xs {
    padding: 1rem 5%;
  }

  > .index-list-and-selected {
    @include hbox;

    border: 1px solid $moonstone-blue;
    border-radius: 1.25rem;

    @include xs {
      @include vbox;

      gap: 2rem;
      padding: 1rem;
    }

    @include sm {
      @include vbox;

      gap: 2rem;
      padding: 1rem;
    }

    @include md {
      min-height: 20rem;
      padding: 2rem;
    }

    @include lg {
      min-height: 20rem;
      padding: 3rem 4rem;
    }

    > .section-index {
      @extend %vbox;

      border-right: 1px solid $moonstone-blue;
      gap: 1.5rem;

      @include md {
        padding-right: 1rem;
        width: 14rem;
      }

      @include lg {
        padding-right: 2rem;
        width: 16rem;
      }

      > a {
        font-size: 1.5rem;
        line-height: 1.875rem;

        &.active {
          font-weight: 600;
        }
      }
    }

    > .section {
      @extend %vbox;

      width: 100%;

      @include xs {
        gap: 2rem;
      }

      @include sm {
        gap: 2rem;
      }

      @include md {
        gap: 2.5rem;
        padding-left: 1rem;
      }

      @include lg {
        gap: 2.5rem;
        padding-left: 2rem;
      }

      > .subsection {
        @include hbox;

        align-items: flex-start;
        gap: 2rem;
        width: 100%;

        @include xs {
          @include vbox;

          gap: 1rem;
        }

        > .title-and-text {
          @extend %vbox;

          color: $raisin-black;
          flex-grow: 1;
          font-family: $commissioner;
          gap: 0.5rem;

          > .title {
            font-size: 1.375rem;
            font-weight: 500;

            @include xs {
              font-size: 1rem;
            }

            @include sm {
              font-size: 1rem;
            }
          }

          > .text {
            font-size: 1rem;
            white-space: break-spaces;
          }
        }
      }
    }
  }
}

[role='dialog'].popup-content.settings-editor-modal-content {
  @extend %vbox;

  height: auto;
  max-height: 95vh;
  max-width: 47.5rem;
  overflow: hidden;
  width: calc(100% - 2rem);

  > .editor {
    @extend %discreet-scrollable;

    background-color: $white;
    border-radius: 1.25rem;
    flex: 1;
    height: 100%;
    padding: 1.5rem;

    > .hdg-md {
      padding-bottom: 1.5rem;
    }

    > form {
      > .fields {
        @extend %vbox;

        gap: 1rem;

        > .field {
          label {
            font-size: 1rem;
            font-weight: normal;
            line-height: 1.25rem;
          }

          input {
            &[type='password'],
            &[type='text'] {
              font-size: 1rem;
              font-weight: normal;
              line-height: 1.25rem;
              padding: 0.5rem;
            }
          }
        }
      }

      > .error {
        color: $copper-red;
        font-family: $commissioner;
        font-size: 1rem;
        font-weight: normal;
        line-height: 1.25rem;
        margin-top: 1.5rem;

        @include xs {
          font-size: 0.8rem;
          line-height: 1rem;
        }

        @include sm {
          font-size: 0.8rem;
          line-height: 1rem;
        }
      }

      > .buttons {
        @extend %hbox;

        flex: 1;
        gap: 1rem;
        justify-content: flex-end;
        padding-top: 2rem;

        > .btn-custom {
          @include xs {
            font-size: 1rem;
            padding: 0.5rem 1rem;
          }

          @include sm {
            font-size: 1rem;
            padding: 0.5rem 1rem;
          }

          @include md {
            font-size: 1.375rem;
            padding: 1rem;
          }

          @include lg {
            font-size: 1.375rem;
            padding: 1rem;
          }
        }
      }
    }
  }
}
