// All styles for the about us welcome key metric.
@import 'styles/styles';

.about-us-key-metric {
  @extend %vbox;

  align-items: center;
  background-color: $silver-lake-blue;
  border-radius: 3.75rem;
  box-shadow: $result-card-hover-shadow;
  justify-content: space-evenly;
  max-width: 11rem;
  padding: 2.5rem;
  text-align: center;

  @include xs {
    max-width: 100%;
    padding: 1.5rem;
  }

  @include sm {
    max-width: 100%;
    padding: 1.5rem;
  }

  @include md {
    min-height: 8rem;
    padding: 2rem;
  }

  @include lg {
    min-height: 8rem;
  }

  &__visible {
    @include sm {
      animation: slide-from-left-in 0.5s ease-in-out forwards;
    }
  }

  &__number-container {
    @extend %hbox;

    align-items: center;
    justify-content: center;
  }

  &__number {
    color: $isabelline;
    font-family: $iwona;
    font-size: 3.25rem;
    font-weight: 700;
    line-height: 4.5rem;

    @include xs {
      font-size: 2rem;
      line-height: 3rem;
    }

    @include sm {
      font-size: 2.5rem;
      line-height: 3.5rem;
    }

    @include md {
      font-size: 3rem;
      line-height: 4rem;
    }
  }
}
