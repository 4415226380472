// All styles for the search filters component.
@import 'styles/styles';

.search-filters {
  @extend %vbox;

  background-color: $white;
  border-radius: 1.25rem;
  flex: 0 1 23rem;

  @include xs {
    flex: 1 0 auto;
  }

  @include sm {
    flex: 1 0 auto;
  }

  @include md {
    border: 1px solid $moonstone-blue;
  }

  @include lg {
    border: 1px solid $moonstone-blue;
  }

  > .ody-modal > .btn-custom {
    @extend %hbox;

    align-items: center;
    font-size: 1.375rem;
    gap: 0.75rem;
    padding: 1rem;

    @include xs {
      font-size: 1rem;
      padding: 0.5rem 1rem;
    }

    @include sm {
      font-size: 1rem;
      padding: 0.5rem 1rem;
    }
  }
}

.search-filters > .container,
[role='dialog'].popup-content.search-filters-modal-content > .container {
  @extend %vbox;

  background-color: $white;
  border-radius: 1.25rem;
  gap: 1.5rem;
  padding: 1.5rem;

  > .title-and-clear {
    @extend %hbox;

    justify-content: space-between;

    > button {
      color: $silver-lake-blue;
      font-size: 1.375rem;

      @include xs {
        font-size: 1rem;
      }

      @include sm {
        font-size: 1rem;
      }
    }
  }
}

[role='dialog'].popup-content.search-filters-modal-content {
  @extend %vbox;

  height: auto;
  max-height: 95vh;
  max-width: 33rem;
  overflow: hidden;
  width: calc(100% - 2rem);

  > .container {
    @extend %discreet-scrollable;

    flex: 1;
    height: 100%;
  }
}
