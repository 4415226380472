// All styles for the take assessment cards section.
@import 'styles/styles';

.take-assessment-cards-section {
  @extend %hbox;

  align-items: center;
  background-color: $isabelline;
  flex-wrap: wrap;
  gap: 6.87rem;
  justify-content: center;
  padding: 8% 6%;

  @include xs {
    gap: 4rem;
    padding: 10% 5%;
  }

  @include sm {
    gap: 4rem;
    padding: 10% 5%;
  }

  @include md {
    gap: 3rem;
    padding: 5%;
  }
}
