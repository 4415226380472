// All styles for the sign-in form.
@import 'styles/styles';

.ody-sign-in-form {
  @extend %vbox;

  align-items: center;
  gap: 1rem;

  @include xs {
    width: 100%;
  }

  @include sm {
    width: 100%;
  }

  @include md {
    width: 95%;
  }

  @include lg {
    width: 80%;
  }

  &__form-control {
    @extend %vbox;

    gap: 0.3rem;
    width: 100%;

    &--link {
      @extend %hbox;

      align-items: center;
    }

    &--link-label {
      font-size: 1rem;
      line-height: 1.25rem;
    }
  }

  &__password-container {
    @extend %vbox;

    width: 100%;

    &--link {
      align-self: flex-end;
      padding-top: 0.5rem;
    }
  }

  &__sign-in-button {
    width: 100%;

    @include xs {
      font-size: 1rem;
      padding: 1rem;
      width: 100%;
    }

    @include sm {
      font-size: 1rem;
      padding: 1rem 3.5rem;
    }

    @include md {
      font-size: 1.5rem;
      padding: 1rem 3.5rem;
    }

    @include lg {
      font-size: 1.5rem;
      padding: 1rem 3.5rem;
    }
  }

  &__external-actions {
    @extend %vbox;

    gap: 1rem;
  }

  &__separator {
    color: $raisin-black;
    font-family: $commissioner;
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.25rem;
    text-align: center;
  }
}
