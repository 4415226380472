// All styles for the public pages sign in section.
@import 'styles/styles';

.public-learn-more-sign-in-section {
  @extend %hbox;

  align-items: center;
  background-color: $isabelline;
  justify-content: space-evenly;
  padding: 5% 0;

  @include xs {
    @include vbox;

    align-items: initial;
    flex-direction: column-reverse;
    gap: 1.5rem;
    padding: 2%;
  }

  @include sm {
    @include vbox;

    align-items: initial;
    flex-direction: column-reverse;
    gap: 2rem;
    padding: 3%;
  }

  @include md {
    padding: 4%;
  }

  &__companies {
    padding: 5% 0 5% 5%;

    @include xs {
      align-items: normal;
      justify-content: space-evenly;
      padding: 2%;
    }

    @include sm {
      align-items: normal;
      justify-content: space-evenly;
      padding: 3%;
    }

    @include md {
      padding: 4% 0 4% 4%;
    }
  }

  &__sign-in-container {
    @extend %vbox;

    gap: 4.125rem;
    max-width: 40.5rem;

    @include xs {
      gap: 1.5rem;
      max-width: 100%;
    }

    @include sm {
      gap: 2.125rem;
      max-width: 100%;
    }

    @include md {
      animation: fade-in-up 0.5s ease-in-out forwards;
      gap: 3.125rem;
      max-width: 30.5rem;
      opacity: 0;
      transform: translateY(6.25rem);
    }

    @include lg {
      animation: fade-in-up 0.5s ease-in-out forwards;
      opacity: 0;
      transform: translateY(6.25rem);
    }
  }

  &__animation-container {
    max-width: 50%;

    @include xs {
      max-width: 100%;
    }

    @include sm {
      max-width: 100%;
    }
  }

  &__animated-icon {
    &--job-seekers {
      @include xs {
        height: 15.31rem;
      }

      @include sm {
        height: 20.31rem;
      }

      @include md {
        height: 20.31rem;
      }

      @include lg {
        height: 30.31rem;
      }
    }

    &--companies {
      @include xs {
        height: 13rem;
      }

      @include sm {
        height: 18rem;
      }

      @include md {
        height: 18rem;
      }

      @include lg {
        height: 37rem;
      }
    }

    &--endorsers {
      @include xs {
        height: 16rem;
      }

      @include sm {
        height: 20rem;
      }

      @include md {
        height: 20rem;
      }

      @include lg {
        height: 32.68rem;
      }
    }
  }
}
