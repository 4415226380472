// All styles for option filters.
@import 'styles/styles';

.ody-filter.option {
  @extend %vbox;

  gap: 1rem;

  > .title-and-expand-collapse {
    @extend %hbox;

    align-items: center;
    justify-content: space-between;

    > .title {
      color: $raisin-black;
      font-family: $commissioner;
      font-size: 1.375rem;
      font-weight: 700;

      @include xs {
        font-size: 1rem;
      }

      @include sm {
        font-size: 1rem;
      }
    }
  }

  > .options {
    @extend %vbox;

    gap: 1rem;

    > .option {
      @extend %hbox;

      gap: 1rem;
      justify-content: space-between;

      &.disabled {
        opacity: 0.4;
      }

      > label.inline {
        align-items: flex-start;
        color: $raisin-black;
        font-family: $commissioner;
        font-size: 1.375rem;
        font-weight: normal;
        gap: 0.5rem;

        @include xs {
          font-size: 1rem;
        }

        @include sm {
          font-size: 1rem;
        }
      }

      > .number {
        color: $spanish-gray;
        font-family: $commissioner;
        font-size: 1.375rem;
        font-weight: normal;

        @include xs {
          font-size: 1rem;
        }

        @include sm {
          font-size: 1rem;
        }
      }
    }

    > button {
      color: $moonstone-blue;
      font-family: $commissioner;
      font-size: 1.375rem;
      font-weight: normal;
      text-align: left;

      @include xs {
        font-size: 1rem;
      }

      @include sm {
        font-size: 1rem;
      }
    }
  }

  &.collapsed > .options {
    max-height: 0;
    overflow: hidden;
    transition: max-height 100ms ease-out;
  }

  &.expanded > .options {
    max-height: 500rem;
    transition: max-height 100ms ease-in;
  }
}
