// All styles for the learn more different section (job seekers).
@import 'styles/styles';

.learn-more-section-different-section {
  @extend %vbox;

  background-color: $maximum-yellow-red;
  gap: 5rem;
  padding: 5% 6%;

  @include xs {
    padding: 5%;
  }

  @include sm {
    padding: 5%;
  }

  @include md {
    padding: 5% 4%;
  }

  &__title-section {
    @extend %vbox;

    align-items: center;
    justify-content: center;
  }

  &__title {
    margin-bottom: 0.8rem;

    @include xs {
      text-align: center;
    }

    @include sm {
      text-align: center;
    }
  }

  &__subtitle {
    @include xs {
      text-align: center;
    }

    @include sm {
      text-align: center;
    }
  }

  &__content-section {
    @extend %hbox;

    align-items: center;
    justify-content: space-between;

    @include xs {
      @include vbox;

      gap: 3rem;
    }

    @include sm {
      @include vbox;

      gap: 3rem;
    }
  }

  &__animated-icon {
    @include md {
      max-width: 42rem;
    }

    @include lg {
      max-width: 60rem;
    }
  }
}
