// All styles for controlled metrics section.
@import 'styles/styles';

.controlled-metrics-section {
  @extend %vbox;

  gap: 1rem;
  width: 100%;

  @include xs {
    align-items: center;
  }

  @include sm {
    align-items: center;
  }

  &__cards {
    @extend %hbox;

    flex-wrap: wrap;
    gap: 1.5rem;
    width: 100%;

    @include xs {
      @include vbox;

      align-items: center;
    }

    @include sm {
      @include vbox;

      align-items: center;
    }
  }
}
