// All styles for the header (users).
@import 'styles/styles';

.ody-header.users {
  > .profile-menu > .ody-popup-menu > .profile-picture {
    @extend %hbox;

    align-items: center;
    border-radius: 9999px;
    height: 2.5rem;
    width: 2.5rem;

    &.no-picture {
      background-color: $silver-sand;
    }
  }

  > .profile-menu-and-links > .ody-popup-menu.users-header-mobile-popupmenu > .trigger-button {
    border-radius: 9999px;
    padding: 0.5rem 0.6rem;
  }
}

[role='tooltip'].popup-content.users-header-mobile-popupmenu-content {
  > .profile-picture {
    @extend %hbox;

    align-items: center;
    border-radius: 9999px;
    height: 2.5rem;
    justify-content: center;
    width: 2.5rem;

    &.no-picture {
      background-color: $silver-sand;
    }
  }

  > .links,
  > .profile-links {
    margin-bottom: 0.5rem;

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    li:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }
}
