// All styles for the learn more light red section.
@import 'styles/styles';

.learn-more-light-red-section {
  @extend %hbox;

  align-items: center;
  background-color: $middle-red;
  justify-content: space-between;
  overflow: hidden;
  padding: 5% 6% 0;

  @include xs {
    @include vbox;

    gap: 1.5rem;
    padding: 4% 6%;
  }

  @include sm {
    @include vbox;

    gap: 2rem;
    padding: 4% 6%;
  }

  @include md {
    padding: 5% 4%;
  }

  &__animation-container {
    max-width: 50%;

    @include xs {
      max-width: 100%;
    }

    @include sm {
      max-width: 100%;
    }

    @include md {
      transform: translateY(13rem);
    }

    @include lg {
      transform: translateY(11rem);
    }
  }

  &__content-container {
    @extend %vbox;

    gap: 3.43rem;
    max-width: 40.5rem;

    @include xs {
      gap: 1.75rem;
      max-width: 100%;
    }

    @include sm {
      gap: 2.5rem;
      max-width: 100%;
    }

    @include lg {
      padding-bottom: 3%;
    }
  }

  &__description {
    &.txt {
      @include xs {
        font-size: 1rem;
      }

      @include sm {
        font-size: 1rem;
      }

      @include md {
        font-size: 1rem;
      }
    }
  }

  &__button {
    &.btn {
      width: 70%;

      @include xs {
        width: 100%;
      }

      @include sm {
        width: 100%;
      }

      @include md {
        width: 80%;
      }
    }

    &.btn-sm {
      padding: 1rem 0;

      @include xs {
        padding: 0.5rem 0;
      }
    }

    &.btn-white {
      border: none;
      color: $tundora;
      font-size: 1.5rem;
      font-weight: 700;
      text-align: center;

      @include xs {
        font-size: 1rem;
      }

      @include sm {
        font-size: 1rem;
      }
    }
  }
}
