// All styles for the home our companies say child collection item.
@import 'styles/styles';

.home-our-companies-say-child-collection-item {
  @extend %vbox;

  gap: 1.5rem;

  @include xs {
    width: 100%;
  }

  @include sm {
    width: 100%;
  }

  &__description-container {
    background-color: $white;
    border-radius: 1.875rem;
    max-width: 25.62rem;
    padding: 1.875rem;

    @include xs {
      max-width: 100%;
    }

    @include sm {
      max-width: 100%;
    }

    @include md {
      max-width: 20rem;
      padding: 1.5rem;
    }
  }

  &__description {
    &.txt-lg {
      @include xs {
        font-size: 1rem;
        line-height: 1.5rem;
      }

      @include sm {
        font-size: 1rem;
        line-height: 1.5rem;
      }

      @include md {
        font-size: 1.375rem;
        line-height: 1.75rem;
      }
    }
  }

  &__photo-name-container {
    @extend %hbox;

    align-items: center;
    gap: 0.625rem;
    max-width: 25.62rem;

    @include xs {
      max-width: 100%;
    }

    @include sm {
      max-width: 100%;
    }

    @include md {
      max-width: 20rem;
    }
  }

  &__photo-container {
    border-radius: 9999px;
    flex-shrink: 0;
    height: 3.75rem;
    width: 3.75rem;
  }

  &__photo {
    border-radius: 9999px;
    height: 100%;
    width: 100%;
  }

  &__name-occupation-container {
    @extend %vbox;
  }

  &__name {
    &.txt-md {
      @include xs {
        font-size: 1rem;
        line-height: 1.5rem;
      }

      @include sm {
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }
  }
}
