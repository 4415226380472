// All styles for the profile completed endorsements.
@import 'styles/styles';

.profile-completed-endorsements {
  @extend %vbox;

  &__title-section {
    @extend %hbox;

    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.125rem;

    @include xs {
      @include vbox;

      gap: 1.5rem;
      justify-content: center;
    }

    > .profile-completed-endorsements__view-all-button {
      @include xs {
        font-size: 1rem;
        padding: 0.5rem 1rem;
        width: 100%;
      }
    }
  }

  &__no-completed-endorsements {
    @extend %hbox;

    align-items: center;
    justify-content: center;
    padding: 1rem;
  }

  &__cards {
    @extend %hbox;

    flex-wrap: wrap;
    gap: 1.5rem;

    @include xs {
      @include vbox;

      justify-content: center;
    }

    @include sm {
      @include vbox;

      justify-content: center;
    }
  }
}
