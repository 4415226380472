// All styles for profile professional section
@import 'styles/styles';

.profile-professional-section {
  &__fields {
    @extend %vbox;
  }

  &__field {
    @extend %hbox;

    &:not(:last-child) {
      padding-bottom: 1rem;
    }
  }

  &__field-icon {
    @extend %hbox;

    align-items: center;
    width: 2rem;
  }

  &__field-value {
    flex: 1;
  }
}
