// All styles for the learn more our endorsers card.
@import 'styles/styles';

.learn-more-our-endorsers-card {
  @extend %vbox;

  background-color: $white;
  border-radius: 2.5rem;
  box-shadow: $result-card-hover-shadow;
  gap: 1.5rem;
  justify-content: center;
  min-width: 26.5rem;
  padding: 4.25rem 2rem;
  position: relative;

  @include xs {
    box-shadow: none;
    min-width: 75%;
    padding: 2.5rem 1.5rem;
  }

  @include sm {
    box-shadow: none;
    min-width: 80%;
    padding: 3rem 1.5rem;
  }

  @include md {
    min-width: 21.5rem;
    opacity: 0;
    padding: 3.5rem 2rem;
  }

  @include lg {
    opacity: 0;
  }

  &__visible {
    @include md {
      animation: slide-from-left-in 0.5s ease-in-out forwards;
      transform: translateX(-6.25rem);
    }

    @include lg {
      animation: fade-in-up 0.5s ease-in-out forwards;
      transform: translateY(6.25rem);
    }
  }

  &__image-container {
    @extend %hbox;

    align-items: center;
    background-color: $white;
    border-radius: 9999px;
    box-shadow: $result-card-hover-shadow;
    height: 4.685rem;
    justify-content: center;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translate(-50%, -50%);
    width: 4.685rem;

    @include xs {
      box-shadow: none;
      height: 3.06rem;
      width: 3.06rem;
    }

    @include sm {
      box-shadow: none;
      height: 3.56rem;
      width: 3.56rem;
    }

    @include md {
      height: 4.06rem;
      width: 4.06rem;
    }

    > .ody-circular-image {
      height: 100%;
      width: 100% !important;
    }
  }

  &__icon-container {
    @extend %hbox;

    align-items: center;
    background-color: $white;
    border-radius: 9999px;
    box-shadow: $result-card-hover-shadow;
    height: 3.125rem;
    justify-content: center;
    left: 50%;
    padding: 1.56rem;
    position: absolute;
    top: -50%;
    transform: translate(-50%, 80%);
    width: 3.125rem;

    @include xs {
      box-shadow: none;
      height: 1.5rem;
      transform: translate(-50%, 80%);
      width: 1.5rem;
    }

    @include sm {
      box-shadow: none;
      height: 2rem;
      transform: translate(-50%, 80%);
      width: 2rem;
    }

    @include md {
      height: 2.5rem;
      width: 2.5rem;
    }
  }

  &__name-container {
    @extend %hbox;

    align-items: center;
  }

  &__name {
    margin-right: 0.81rem;
  }

  &__info-container {
    @extend %vbox;
  }
}
