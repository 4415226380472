// All styles for the public FAQ item.
@import 'styles/styles';

.public-faq-item {
  @extend %vbox;

  background-color: $isabelline;
  border-radius: 1.875rem;
  gap: 0;
  padding: 1.875rem;
  transition: all  0.3s ease;
  white-space: break-spaces;

  @include sm {
    opacity: 0;
  }

  @include md {
    opacity: 0;
  }

  @include lg {
    opacity: 0;
  }

  &__expanded {
    gap: 0.62rem;
  }

  &__visible {
    @include sm {
      animation: fade-in-up 0.5s ease-in-out forwards;
      transform: translateY(6.25rem);
    }

    @include md {
      animation: fade-in-up 0.5s ease-in-out forwards;
      transform: translateY(6.25rem);
    }

    @include lg {
      animation: fade-in-up 0.5s ease-in-out forwards;
      transform: translateY(6.25rem);
    }
  }

  &__question-toggle {
    @extend %hbox;

    align-items: center;
    background-color: transparent;
    border: none;
    justify-content: space-between;
    text-align: left;

    &.txt {
      @include xs {
        font-size: 1rem;
      }

      @include sm {
        font-size: 1.2rem;
      }
    }
  }

  &__question-toggle-icon-container {
    @extend %hbox;

    align-items: center;
    justify-content: center;
    transform: rotate(-180deg);
    transition: all 0.3s ease;

    &--expanded {
      transform: rotate(0);
    }
  }

  &__answer-container {
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s ease;

    &--expanded {
      max-height: 20rem;
    }
  }

  &__answer {
    &.txt {
      @include xs {
        font-size: 0.8rem;
      }

      @include sm {
        font-size: 1rem;
      }
    }
  }
}
