// All styles for the public companies collection section.
@import 'styles/styles';

.public-companies-collection-section {
  @extend %vbox;

  align-items: flex-start;
  background-color: $isabelline;
  justify-content: center;
  overflow-x: hidden;
  transition: all 0.3s ease;

  @include xs {
    min-height: 27rem;
  }

  @include sm {
    min-height: 37rem;
  }

  @include md {
    min-height: 45rem;
    padding: 0 8%;
  }

  @include lg {
    min-height: 45rem;
    padding: 0 12%;
  }

  &__title {
    margin-bottom: 7.125rem;

    @include xs {
      margin-bottom: 1.5rem;
      padding-left: 5%;
    }

    @include sm {
      margin-bottom: 2.125rem;
      padding-left: 5%;
    }

    @include md {
      margin-bottom: 5.125rem;
    }
  }

  &__container {
    @extend %hbox;

    flex-wrap: wrap;
    gap: 5.62rem;

    @include xs {
      gap: 1.62rem;
    }

    @include sm {
      gap: 1.62rem;
    }

    @include md {
      gap: 3.62rem;
    }
  }

  &__company {
    @extend %hbox;

    align-items: center;
    justify-content: center;

    @include xs {
      width: 100vw;
    }

    @include sm {
      width: 100vw;
    }

    @include md {
      width: 10rem;
    }

    @include lg {
      width: 12rem;
    }

    &--social-responsibility {
      @include xs {
        width: 100vw;
      }

      @include sm {
        width: 100vw;
      }

      @include md {
        width: 12rem;
      }

      @include lg {
        width: 15rem;
      }
    }
  }

  &__image {
    mix-blend-mode: multiply;
    width: 100%;
  }

  &__see-all-button {
    align-self: center;

    &.btn {
      @extend %hbox;

      align-items: center;
      justify-content: center;
      width: 11.18rem;
    }

    &.btn-sm {
      padding: 1rem;
      text-align: center;

      @include xs {
        padding: 0.5rem 1rem;
      }
    }

    &.btn-blue {
      font-size: 1.5rem;

      @include xs {
        font-size: 1rem;
      }

      @include sm {
        font-size: 1rem;
      }
    }
  }

  &__carousel {
    .carousel {
      &.carousel-slider {
        height: 18rem;
        margin-bottom: 1.5rem;

        @include xs {
          margin-bottom: 1rem;
        }
      }

      .slide {
        @extend %hbox;

        justify-content: center;
      }

      .control-dots {
        margin-top: 1.25rem;
        width: 100vw;

        .dot {
          background-color: $beau-blue;
          box-shadow: none;

          &.selected {
            background-color: $silver-lake-blue;
          }
        }
      }
    }

    &--social-responsibility {
      img {
        @include xs {
          height: 70%;
        }
      }

      .carousel {
        &.carousel-slider {
          margin-bottom: 0;

          @include xs {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
