// All styles for date pickers.
@import 'styles/styles';

.ody-date-picker-wrapper {
  @extend %vbox;

  > div {
    @extend %vbox;

    > input {
      background-color: $white;
      border: 1px solid $silver-sand;
      border-radius: 0.5rem;
      color: $raisin-black;
      font-family: $commissioner;
      font-size: 1.375rem;
      font-weight: normal;
      line-height: 1.625rem;
      margin-top: 0.5rem;
      outline: 0;
      padding: 1rem;

      @include xs {
        font-size: 1rem;
        line-height: 1.25rem;
      }

      @include sm {
        font-size: 1rem;
        line-height: 1.25rem;
      }
    }
  }
}

.ody-date-picker-popper {
  color: $raisin-black;
  font-family: $commissioner;
  font-size: 1rem;

  .react-datepicker__header {
    background-color: $silver-sand;
    color: $raisin-black;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month--selected,
  .react-datepicker__month-text--keyboard-selected {
    background-color: $silver-lake-blue;
  }

  .react-datepicker__month-wrapper {
    color: $raisin-black;
    font-family: $commissioner;
    font-size: 1rem;
  }

  .react-datepicker__navigation-icon::before {
    border-color: $raisin-black;
  }

  .react-datepicker__month .react-datepicker__month-text {
    width: 3rem;
  }
}
