// All styles for the learn more create CV section.
@import 'styles/styles';

.learn-more-create-cv-section {
  @extend %hbox;

  align-items: center;
  background-color: $isabelline;
  justify-content: space-evenly;
  padding: 5% 6%;

  @include xs {
    @include vbox;

    gap: 2rem;
    padding: 4% 6%;
  }

  @include sm {
    @include vbox;

    gap: 3rem;
    padding: 4% 6%;
  }

  @include md {
    padding: 5% 4%;
  }

  &__content-section {
    @extend %vbox;

    gap: 1.875rem;

    @include xs {
      gap: 1rem;
    }

    @include sm {
      gap: 1.2rem;
    }

    @include md {
      gap: 1.5rem;
      max-width: 28.9rem;
    }

    @include lg {
      max-width: 41.43rem;
    }
  }

  &__description {
    &.txt {
      @include xs {
        font-size: 1rem;
      }

      @include sm {
        font-size: 1rem;
      }

      @include md {
        font-size: 1rem;
      }
    }
  }

  &__button {
    &.btn {
      width: 70%;

      @include xs {
        width: 100%;
      }

      @include sm {
        width: 100%;
      }
    }

    &.btn-sm {
      padding: 1rem;

      @include xs {
        padding: 0.5rem 1rem;
      }
    }

    &.btn-yellow {
      color: $tundora;
      font-size: 1.5rem;
      font-weight: 700;

      @include xs {
        font-size: 1rem;
      }

      @include sm {
        font-size: 1rem;
      }
    }
  }

  &__animated-icon {
    @include xs {
      height: 13rem;
    }

    @include sm {
      height: 15rem;
    }

    @include md {
      height: 20rem;
    }

    @include lg {
      height: 25.18rem;
    }
  }
}
