// All styles for the endorsement feedback card in job seeker profile
@import 'styles/styles';

.endorsement-feedback-card {
  @extend %vbox;

  background-color: $white;
  border: 1px solid $silver-lake-blue;
  border-radius: 1.25rem;
  cursor: pointer;
  flex: 0 0 16.8rem;
  gap: 1rem;
  padding: 1.5rem;
  text-align: left;
  transition: all 0.2s ease;

  &:hover {
    @include md {
      box-shadow: $result-card-hover-shadow;
    }

    @include lg {
      box-shadow: $result-card-hover-shadow;
    }
  }

  &__name {
    @include wrap;
  }

  &__testimonial-section {
    @extend %vbox;
    @extend %wrap;

    gap: 0.25rem;
  }

  &__testimonial {
    @include truncate-box-vertical(4);
  }
}
