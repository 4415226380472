// All styles for password input.
@import 'styles/styles';

.ody-password-input {
  &__container {
    position: relative;
    width: 100%;
  }

  &__input-box {
    background-color: $white;
    border: 1px solid $silver-sand;
    border-radius: 0.5rem;
    margin-top: 0.5rem;
    outline: 0;

    > .ody-password-input__input {
      border: none;
      margin-top: 0;
      width: 89%;
    }

    &:focus,
    &:focus-within {
      border-color: $moonstone-blue;
    }

    &--disabled {
      background-color: $silver-sand;
      cursor: not-allowed;
    }

    &--error {
      border-color: $copper-red;

      &:focus,
      &:focus-within {
        border-color: $copper-red;
      }
    }

    > .ody-password-input__visibility-button {
      background-color: transparent;
      border: none;
      position: absolute;
      right: 1rem;
      top: 55%;

      &:disabled {
        background-color: $silver-sand;
        cursor: not-allowed;
      }
    }
  }
}
