// All styles for the dashboard statistics
@import 'styles/styles';

.dashboard-statistics {
  @extend %hbox;

  align-self: stretch;
  justify-content: center;

  @include xs {
    @include vbox;

    justify-content: center;
    padding-top: 0;
    width: 100%;
  }

  @include sm {
    @include vbox;
  }

  &__statistic {
    @extend %vbox;

    align-items: stretch;
    flex: 1;
    padding-bottom: 6rem;
    padding-top: 8rem;
    text-align: center;

    @include xs {
      justify-content: center;
      min-height: 11rem;
      padding: 0;
    }

    @include sm {
      padding-bottom: 2rem;
      padding-top: 3rem;
    }

    @include md {
      padding-bottom: 4rem;
      padding-top: 5.5rem;
    }

    &--light-job-seekers {
      background-color: $maximum-yellow-red;
    }

    &--dark-job-seekers {
      background-color: $marigold;
    }

    &--light-companies {
      background-color: $middle-red;
    }

    &--dark-companies {
      background-color: $copper-red;
    }

    &--light-endorsers {
      background-color: $african-violet;
    }

    &--dark-endorsers {
      background-color: $middle-blue-purple;
    }
  }

  &__number {
    font-family: $commissioner;
    font-weight: 600;
    padding-bottom: 0.5rem;

    @include xs {
      font-size: 2.5rem;
      line-height: 3rem;
    }

    @include sm {
      font-size: 2.25rem;
      line-height: 2.75rem;
    }

    @include md {
      font-size: 3rem;
      line-height: 3.625rem;
    }

    @include lg {
      font-size: 4rem;
      line-height: 5rem;
    }
  }

  &__label {
    align-self: center;
    font-family: $commissioner;
    font-weight: normal;
    max-width: 20.125rem;

    @include xs {
      font-size: 1.375rem;
      line-height: 1.75rem;
      padding: 0 1rem;
    }

    @include sm {
      font-size: 1rem;
      line-height: 1.5rem;
      padding: 0 1rem;
    }

    @include md {
      font-size: 1.5rem;
      line-height: 1.875rem;
      padding: 0 2rem;
    }

    @include lg {
      font-size: 2.125rem;
      line-height: 2.625rem;
      padding: 0 2rem;
    }
  }
}
