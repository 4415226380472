// All styles for the home page create profile section.
@import 'styles/styles';

.home-create-profile-section {
  @extend %hbox;

  align-items: center;
  background-color: $isabelline;
  justify-content: space-evenly;
  min-height: 37.75rem;

  @include xs {
    @include vbox;

    min-height: 29rem;
  }

  @include sm {
    @include vbox;
  }

  &__animated-icon {
    @include xs {
      height: 9rem;
    }

    @include sm {
      height: 12rem;
    }

    @include md {
      height: 15rem;
    }

    @include lg {
      height: 18rem;
    }
  }

  &__info-container {
    @extend %vbox;

    max-width: 33.5rem;

    @include xs {
      max-width: 21.3rem;
    }

    @include sm {
      max-width: 21.3rem;
    }

    @include md {
      max-width: 24.9rem;
    }
  }

  &__info-title {
    margin-bottom: 1rem;
  }

  &__info {
    margin-bottom: 2.625rem;

    &.txt {
      @include xs {
        font-size: 1rem;
      }

      @include sm {
        font-size: 1rem;
      }

      @include md {
        font-size: 1rem;
      }
    }
  }

  &__see-example-profile-button {
    &.btn {
      width: 70%;

      @include xs {
        width: 100%;
      }

      @include sm {
        width: 100%;
      }
    }

    &.btn-sm {
      padding: 1rem 0;
    }

    &.btn-blue {
      color: $tundora;
      font-size: 1.5rem;

      @include xs {
        font-size: 1rem;
      }

      @include sm {
        font-size: 1rem;
      }
    }
  }
}
