// All styles for the endorsement.
@import 'styles/styles';

.endorsement {
  @extend %vbox;

  &__top-banner {
    background-color: $african-violet;
    padding: 4.1% 21.5% 2.5%;

    @include md {
      padding: 4.1% 13.8% 2.5%;
    }

    @include sm {
      padding: 4.1% 14.39% 2.5%;
    }

    @include xs {
      padding: 4.1% 6.6% 2.5%;
    }
  }

  &__form-container {
    @extend %vbox;

    gap: 1.31rem;
    padding: 4.1% 21.5%;
  }
}
