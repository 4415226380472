// All styles for the video testimonial.
@import 'styles/styles';

.video-testimonial {
  @extend %vbox;

  gap: 1.875rem;
  justify-content: space-between;
  max-width: 39.62rem;

  @include xs {
    align-items: center;
    gap: 1.375rem;
    justify-content: center;
    width: 95%;
  }

  @include sm {
    align-items: center;
    gap: 1.375rem;
    justify-content: center;
    width: 95%;
  }

  @include md {
    gap: 1.5rem;
    max-width: 30.62rem;
  }

  &__title {
    margin: 0;

    @include md {
      min-height: 9rem;
    }
  }

  &__video-container {
    @extend %hbox;

    align-items: center;
    border-radius: 1.875rem;
    box-shadow: $result-card-hover-shadow;
    height: 21.31rem;
    justify-content: center;
    overflow: hidden;
    z-index: 1;

    @include xs {
      height: 16rem;
      width: 100%;
    }

    @include sm {
      height: 18rem;
      width: 100%;
    }

    @include md {
      min-width: 30rem;
    }

    @include lg {
      min-width: 39.62rem;
    }
  }

  &__name-age-location-container {
    @extend %hbox;

    align-items: center;
    justify-content: space-between;
    padding: 0 2%;

    @include xs {
      padding: 0 4%;
      width: 100%;
    }

    @include sm {
      padding: 0 4%;
      width: 100%;
    }
  }

  &__name {
    &.txt-lg {
      @include xs {
        font-size: 1rem;
        line-height: 1.5rem;
      }

      @include sm {
        font-size: 1rem;
        line-height: 1.5rem;
      }

      @include md {
        font-size: 1.375rem;
        line-height: 1.75rem;
      }
    }
  }

  &__age-location {
    &.txt-lg {
      @include xs {
        font-size: 1rem;
        line-height: 1.5rem;
      }

      @include sm {
        font-size: 1rem;
        line-height: 1.5rem;
      }

      @include md {
        font-size: 1.375rem;
        line-height: 1.75rem;
      }
    }

    &.txt {
      font-weight: 300;
    }
  }
}
