// All styles for profile image
@import 'styles/styles';

.profile-image {
  @extend %hbox;

  background-color: $white;
  height: 7.5rem;
  margin-left: calc(50% - 3.75rem);
  margin-top: -3.75rem;
  position: relative;
  width: 7.5rem;

  @include xs {
    height: 5rem;
    margin-left: calc(50% - 2.5rem);
    margin-top: -2.5rem;
    width: 5rem;
  }

  @include sm {
    height: 5rem;
    margin-left: calc(50% - 2.5rem);
    margin-top: -2.5rem;
    width: 5rem;
  }

  > .picture {
    @extend %vbox;

    align-items: center;
    border-radius: 9999px;
    justify-content: center;

    @include xs {
      height: 5rem;
      width: 5rem;
    }

    @include sm {
      height: 5rem;
      width: 5rem;
    }

    @include md {
      height: 7.5rem;
      width: 7.5rem;
    }

    @include lg {
      height: 7.5rem;
      width: 7.5rem;
    }

    > img {
      z-index: 3;
    }
  }

  > .edit-button {
    background-color: white;
    border-radius: 9999px;
    display: flex;
    height: fit-content;
    position: absolute;
    right: -0.1rem;
    top: -0.1rem;
    z-index: 3;

    @include xs {
      padding: 0.4rem 0.525rem;

      > img {
        width: 0.75rem;
      }
    }

    @include sm {
      padding: 0.4rem 0.525rem;

      > img {
        width: 0.75rem;
      }
    }

    @include md {
      padding: 0.5rem 0.6rem;

      > img {
        width: 1rem;
      }
    }

    @include lg {
      padding: 0.5rem 0.6rem;

      > img {
        width: 1rem;
      }
    }
  }

  &.no-picture > .picture > img {
    background-color: $silver-sand;
    border-radius: 9999px;
  }

  > .ody-modal > button {
    display: none;
  }
}
