// All styles for the loader.
@import 'styles/styles';

.ody-loader {
  align-items: center;
  bottom: 0;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  margin: auto;
  position: fixed;
  right: 0;
  top: 0;
  width: 100vw;
  z-index: 9999;

  > .spinner {
    background: none;
    display: block;
    height: 6.25rem;
    margin: auto;
    shape-rendering: auto;
    width: 6.25rem;

    @include xs {
      height: 3.25rem;
      width: 3.25rem;
    }

    @include sm {
      height: 4.25rem;
      width: 4.25rem;
    }

    @include md {
      height: 5.25rem;
      width: 5.25rem;
    }
  }
}
