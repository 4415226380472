// All styles for the endorsers feedback in profile (job seekers).
@import 'styles/styles';

.profile-endorser-feedback {
  @extend %vbox;

  &__title-container {
    @extend %hbox;

    align-items: center;
    justify-content: space-between;
    padding-bottom: 1rem;

    @include xs {
      @include vbox;

      gap: 1.5rem;
      justify-content: center;
    }

    > .profile-endorser-feedback__request-endorsement-button {
      > .ody-modal.confirm-modal {
        @include sm {
          @include vbox;

          align-items: center;
          justify-content: center;
        }

        @include xs {
          @include vbox;

          align-items: center;
          justify-content: center;
          width: 100%;
        }

        > button {
          @include xs {
            font-size: 1rem;
            padding: 0.5rem 1rem;
            width: 100%;
          }
        }
      }
    }
  }

  &__average-section {
    @extend %hbox;

    flex-wrap: wrap;
    gap: 3rem;
    justify-content: space-between;
    margin-bottom: 3rem;

    @include sm {
      @include vbox;

      align-items: center;
      justify-content: center;
    }

    @include xs {
      @include vbox;

      align-items: center;
      justify-content: center;
    }
  }

  &__average-score-container {
    @extend %vbox;

    align-items: center;
    gap: 1rem;
    justify-content: center;
  }

  &__endorsements-section {
    @extend %hbox;

    flex-wrap: wrap;
    gap: 2.43rem;
    justify-content: space-between;

    @include sm {
      @include vbox;

      align-items: center;
      justify-content: center;
    }

    @include xs {
      @include vbox;

      align-items: center;
      justify-content: center;
    }
  }
}
