// All styles for the home our companies say root collection item.
@import 'styles/styles';

.home-our-companies-say-root-collection-item {
  @extend %hbox;

  align-items: center;
  flex-wrap: wrap;
  gap: 7rem;
  justify-content: center;
  margin: auto;
  width: 85%;

  @include xs {
    @include vbox;

    align-items: flex-start;
    gap: 2.5rem;
    width: 90%;
  }

  @include sm {
    @include vbox;

    align-items: flex-start;
    gap: 3rem;
    width: 90%;
  }

  @include md {
    gap: 3rem;
    width: 95%;
  }
}
