// All styles that can be applied to texts.

// Some text.
.txt {
  font-family: $commissioner;
  font-weight: normal;
}

// Some sm text.
.txt-sm {
  font-size: 1rem;
  line-height: 1.5rem;
}

// Some md text.
.txt-md {
  font-size: 1.375rem;
  line-height: 1.75rem;
}

// Some lg text.
.txt-lg {
  font-size: 1.5rem;
  line-height: 2rem;
}

// Some xl text.
.txt-xl {
  font-size: 1.75rem;
  line-height: 2.25rem;
}

.txt-bold {
  font-weight: bold;
}
