// All styles for the take assessment first section.
@import 'styles/styles';

.take-assessment-first-section {
  @extend %hbox;

  align-items: center;
  background-color: $maximum-yellow-red;
  justify-content: space-between;
  padding: 2% 6%;

  @include xs {
    @include vbox;

    gap: 2rem;
    padding: 5%;
  }

  @include sm {
    @include vbox;

    gap: 3rem;
    padding: 2% 5%;
  }

  @include md {
    padding: 2% 5%;
  }

  &__animation-container {
    max-width: 50%;

    @include xs {
      max-width: 100%;
    }

    @include sm {
      max-width: 100%;
    }
  }

  &__traits-image {
    left: 2rem;
    position: absolute;
    top: 3rem;
    width: 100%;
  }

  &__content {
    max-width: 42.12rem;

    @include xs {
      max-width: 100%;
    }

    @include sm {
      max-width: 100%;
    }

    @include md {
      max-width: 28.5rem;
    }
  }

  &__title {
    margin-bottom: 2rem;

    @include xs {
      margin-bottom: 1rem;
    }

    @include sm {
      margin-bottom: 1.5rem;
    }
  }

  &__description {
    margin-bottom: 2.81rem;

    @include xs {
      margin-bottom: 1rem;
    }

    @include sm {
      margin-bottom: 1.5rem;
    }

    @include md {
      margin-bottom: 2rem;
    }

    &.txt {
      @include xs {
        font-size: 1rem;
      }

      @include sm {
        font-size: 1rem;
      }

      @include md {
        font-size: 1rem;
      }
    }
  }

  &__button {
    @extend %hbox;

    align-items: center;
    background-color: rgba($maximum-yellow-red, 100%);
    border: 4px dashed $carrara;
    border-radius: 0.93rem;
    color: $carrara;
    font-family: $commissioner;
    font-size: 2rem;
    font-weight: 600;
    justify-content: center;
    line-height: 2.43rem;
    padding: 1rem;
    width: 60%;

    &:disabled {
      cursor: not-allowed;
    }

    @include xs {
      font-size: 0.8rem;
      line-height: 1rem;
      padding: 0.5rem;
      width: 100%;
    }

    @include sm {
      font-size: 1rem;
      line-height: 1.3rem;
      padding: 0.5rem;
      width: 100%;
    }

    @include md {
      font-size: 1.5rem;
      line-height: 1.875rem;
      padding: 0.8rem;
    }
  }
}
